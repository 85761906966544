
@import '../config.scss';

.e_portfolio_accordion {
    border-radius: 16px!important;
    .e_parent_item{
        border-radius: 8px!important;
            .e_parent_accordion_btn {
                font-weight: 700!important;
                font-size: 14px!important;
                line-height: 17px!important;
                color: #010100!important;
                background-color: $white;
                width: 100%;
                height: 40px;
                border: none;
                outline: none;
                text-align: left;
                padding: 10px;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    border: solid $primary_color;
                    right: 20px;
                    top: 10px;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 5px;
                    
                }
            }
             .e_parent_accordion_btn[aria-expanded="false"] {
                &::after{
                transform: rotate(45deg);
                 -webkit-transform: rotate(45deg);
            }
        }
        .e_parent_accordion_btn[aria-expanded="true"] {
            &::after{
                transform: rotate(-135deg);
                 -webkit-transform: rotate(-135deg);
            }
        }
    }
  
   .show {
    transition: ease-in-out 1s;
   }
}
.e_child_accordion{
    border: 1px solid #cccccc7a!important;
    border-radius: 8px;
        .e_accordion_header {
            border-radius: 8px!important; 
        }
        .e_child_accordion_btn{ 
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.02em;
            color: #010101;
            background-color: $white;
            width: 100%;
            height: 30px;
            border: none;
            outline: none;
            text-align: left;
            padding: 1px 10px 1px 10px;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                right: 20px;
                top: 15px;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-bottom: 5px solid #000000;
                
            }
        }
        .e_child_accordion_btn[aria-expanded="false"] {
            transition: ease-in-out 1s;
            &::after{
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
         }
        }
        .e_child_accordion_btn[aria-expanded="true"] {
            transition: ease-in-out 1s;
            &::after{
                transform: rotate(-135deg);
                -webkit-transform: rotate(-135deg);
            }
        }
        .e_child_body {
            // background-color: #F6F9FF;
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            color: #6D6D6D;
            font-feature-settings: 'ss03' on;
            padding-top: 0;
            padding-bottom: 0;
        }
}   

@media only screen and (max-width: 575px) {
    .e_child_accordion_btn {
        &::after {
            top: 8px!important;
        }
    }
}