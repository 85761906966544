/*
 *   File : dateRangepicker.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : dateRangepicker.scss
 *   Integrations : 
 *   Version : v1.0
 */

.DateRangePickerInput{
	background-color: $white!important;
	border-radius: 8px 8px 8px 8px!important;
  border: 1px solid $input_boder!important;
	padding: 8px 0px!important;
	.DateInput_input{
		background-color: $white!important;
		border-radius: 0px!important;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		&:placeholder{
			color: $input_placeholder!important;
      font-weight: 600;
		}
	} 

}

.DateRangePickerInput__withBorder {
		// border: none !important;
}
.DateRangePickerInput_clearDates{
	top: 46%!important;
}
.DateRangePickerInput_clearDates_default:focus, .DateRangePickerInput_clearDates_default:hover{
	background-color: transparent!important;
}
.DateRangePickerInput_calendarIcon{
	background-image: url('../../assets/images/input/calendar.svg')!important;
	background-repeat: no-repeat!important;
	background-position-y: 90%!important;
  margin: 0 20px 0 60px;
	.DateRangePickerInput_calendarIcon_svg{
		display: none!important;
	}
}
.DateRangePickerInput__showClearDates{
	// padding-right: 5px!important;

}
.DateRangePickerInput_clearDates{
	background-color: #dbdbdb!important;
	border-radius: 50%;
}
.DateRangePickerInput_clearDates__small{
	padding: 1px 3px 4px 3px!important;
}
.DateRangePickerInput_clearDates_svg__small{
	height: 10px!important;
}
.DateInput_input__small{
	border-radius: 8px 0px 0px 8px!important;
	padding: 11px 7px 11px 12px !important;
}
