@import "../config.scss";

.e_custom_tooltip {
  position: absolute;
  background-color: white;
  border-radius: 8px;
  width: 140px;
  height: max-content;
  color: black;
  top: -50px;
  left: 0;
  right: 0;
  padding: 0px 5px;
  margin: auto;
  text-align: center;
  padding-top: 5px;
  transform: scale(0);
  font-size: 14px;
  animation: zoom-in 0.5s 0s forwards;
  font-weight: 500;
  box-shadow: 0 0 3px rgb(33 33 33 / 20%);
}

@keyframes zoom-in {
  70% {
    transform: scale(1.2);
  }

  90% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
