/*
 *   File : acccordion.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_order_accordion {
  .e_accordion_sec {
    background: #ffffff;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    // h6 {
    //   color: $primary_color  !important;
    //   @extend %font14;
    //   line-height: 24px;
    //   font-weight: 700!important;
    //   padding: 12px 10px 0px;
    //   width: 100%;
    // }
  }
  .e_add_sec {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.48);
    border-radius: 8px;
    width: 100%;
    padding: 7px 10px 14px;
    margin-bottom: 20px;

    h6 {
      color: $primary_color !important;
      @extend %font14;
      line-height: 24px;
      font-weight: 700 !important;
      padding: 12px 10px 0px;
      width: 100%;
      cursor: pointer;
      &:hover {
        translate: scale(1.5);
      }
    }
  }
  .e_type_span {
    padding: 5px;
    background: #edf3ff;
    border-radius: 100px;
    margin: auto;
    img {
      position: relative;
      top: -1px;
      margin-right: 0 !important;
    }
  }
  .e_accordion_button {
    border: none !important;
    background-color: $white;
    text-align: left;
    width: 100%;
    position: relative;
    border-radius: 0;
    outline: none;
    //  &[aria-expanded="false"] {
    .e_accordion_icon {
      position: absolute;
      top: 35%;
      right: 0px;
      background-image: url(../../assets/images/dataformatting/down.svg);
      background-repeat: no-repeat;
      padding: 13px;
    }
    .e_column {
      width: 230px;
    }
    //  }
    //  &[aria-expanded="true"] {
    .e_accordion_icon_minus {
      position: absolute;
      top: 35%;
      right: 0px;
      background-image: url(../../assets/images/dataformatting/up.svg);
      background-repeat: no-repeat;
      padding: 13px;
    }
    //  }
  }
  .e_accordion_item {
    border: none !important;
    .e_accordion_header {
      background: #ffffff;
    }
  }
  .e_accordion_title {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #656565;
    padding-top: 2px;
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
  .e_amount {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: rgba(1, 1, 1, 0.9);
    margin-top: 5px;
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
  .e_accordion_sub_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(1, 1, 1, 0.9);
    margin-top: 5px;
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
  .e_accordion_wrapper {
    background: #f3f7ff;
    border-radius: 8px;
    padding: 16px;
    margin: 2px;
    .e_accordion_content_img {
      width: 4%;
    }
    .e_accordion_content {
      font-weight: 400;
      @extend %font14;
      line-height: 150%;
      color: $input_text;
      h6 {
        @extend %font14;
      }
    }
    .e_accordion_sub_wrapper {
      p {
        font-weight: 600;
        color: $black;
        @extend %font12;
        line-height: 16px;
        word-break: break-all;
      }
    }
    .e_accordion_table_wrapper {
      // margin-bottom: 32px;
      // border-bottom: 0.5px solid #f0f0f0;
      p {
        font-weight: 500;
        color: $black;
        @extend %font12;
        line-height: 16px;
        word-break: break-all;
      }
    }
  }
  .e_delete {
    height: 40px;
    text-align: center;
    border: 1px solid $primary_color;
    padding: 8px 12px;
    border-radius: 8px;
    font-weight: 700;
    &:hover {
      opacity: 0.7;
    }
  }
  .e_edit {
    height: 40px;
    text-align: center;
    border: 1px solid $primary_color;
    padding: 8px 12px;
    border-radius: 8px;
    font-weight: 700;

    &:hover {
      opacity: 0.7;
    }
  }
}
.e_accordion_body {
  padding: 0 0 !important;
  p {
    @extend %font12;
    line-height: 16px;
    color: $input_placeholder;
    span {
      color: $input_text;
      font-weight: 700;
    }
  }
}
