/*
 *   File : singleDate.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Datepicker style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 16-12-2021
 */

@import "./../config.scss";

.e-single-date {
  .ant-picker {
    width: 100%;
    padding: 0;
    border-radius: 8px !important;
    border: none;
    border: 1.8px solid #e6e6e6 !important;
    border-radius: 8px !important;
  }
  .ant-picker:hover {
    // border: #5A69EE!important;
    box-shadow: none;
    @extend %font12;
  }
  .ant-picker-input > input {
    width: 100%;
    padding: 13px 16px !important;
    position: relative;
    @extend %font14;
    background: $white;
    font-weight: 600;
    color: $input_text;
    margin: 1px;
    // border-color: transparent !important;
  }
  .ant-picker-focused {
    border: 1.8px solid #5a69ee !important;
    box-shadow: none !important;
    @extend %font12;
    input {
      border: none !important;
    }
  }
  // .ant-picker-panel-container {
  .ant-picker-panel {
    border: 1.8px solid #5a69ee !important;
    border-radius: 8px;
  }

  // width: 110%;
  // }
  .ant-picker-input > input:hover {
    border-color: #e6e6e6 !important;
  }
  .ant-picker:hover {
    background-color: transparent !important;
  }
  .ant-picker-input > input:focus {
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-picker-input > input::placeholder {
    font-weight: 500;
    @extend %font14;
    color: rgba(0, 0, 0, 0.24);
  }
  // .ant-picker-input > input {

  // }
  .ant-picker-suffix {
    position: absolute;
    right: 10px;
    top: 17px;
  }
  .ant-picker-focused .ant-picker-suffix {
    display: none;
  }
  .ant-picker-dropdown {
    position: relative;
    z-index: 9999 !important;
  }
  .ant-picker-input:focus .ant-picker-clear,
  .ant-picker-clear {
    right: 10px;
  }
}
.e-single-date-value {
  .ant-picker {
    width: 100%;
    padding: 0;
    border: 1.8px solid #e6e6e6 !important;
    border-radius: 8px !important;
    border-color: transparent;
    border: none;
  }
  .ant-picker:hover {
    border-color: #f0f0f0;
    box-shadow: none;
    @extend %font12;
  }
  .ant-picker-input > input {
    width: 100%;
    padding: 13px 16px !important;
    position: relative;
    @extend %font14;
    background: $white;
    font-weight: 600;
    margin: 1px;
    color: $input_text;
    // border: 1.8px solid $input_boder;
    // border-radius: 8px !important;
    //  border-color: transparent !important;
  }
  .ant-picker-focused {
    border: 1.8px solid $primary_color !important;
    // box-shadow: 0px 0px 5px 2px rgba(19, 41, 230, 0.1) !important;
    @extend %font12;
    input {
      border: none !important;
    }
  }
  .ant-picker-input > input:hover {
    border-color: transparent !important;
  }
  .ant-picker:hover {
    background-color: transparent !important;
  }
  .ant-picker-input > input:focus {
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-picker-input > input::placeholder {
    font-weight: 600;
    @extend %font14;
    color: $input_placeholder;
  }
  // .ant-picker-input > input {

  // }
  .ant-picker-suffix {
    display: none;
  }
  .ant-picker-focused .ant-picker-suffix {
    display: none;
  }
  .ant-picker-dropdown {
    position: relative;
    z-index: 9999 !important;
  }
  .ant-picker-input:focus .ant-picker-clear,
  .ant-picker-clear {
    opacity: 1;
    right: 10px;
  }
}
.ant-picker-dropdown {
  z-index: 1060 !important;
}
// .anticon.anticon-calendar {
//   background-image: url('../../assets/images/input/calendar.svg')!important;
// 	background-repeat: no-repeat!important;
// 	background-position-y: 50%!important;
// }
