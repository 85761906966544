/*
 *   File : invest.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Invest modal style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

@import "./../config.scss";

.e_invest_modal {
  .e_dialog {
    max-width: 540px !important;
  }
  .e_modal_content {
    height: auto !important;
    padding: 0 !important;
    border-radius: 12px;
  }
  .e_invest_modal_body {
    height: 600px;
    overflow-y: scroll;
  }
  .e_modal_header {
    background: #f6f6f6;
    position: relative;
    border-radius: 12px 12px 0px 0px;
    .e_modal_close {
      border: none;
      outline: none;
      background: transparent;
      position: absolute;
      right: 3%;
      top: 30%;
      width: 28px;
      height: 28 px;
    }
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $black;
  }
  label {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #010101;
  }
  .e_amount_input {
    padding: 12px 20px 13px 27px;
  }
  .e_rupee {
    position: absolute;
    top: 38%;
    left: 11%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
  }
  h5 {
    @extend %h5;
  }
  .e_to_withdraw {
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 12px;
    color: #010101;
  }
  .e_amount {
    font-weight: 700;
    @extend %font18;
    color: $input_text;
  }
  .e_amount_fee {
    @extend %font12;
    line-height: 16px;
    color: $input_text;
    font-weight: 300;
  }
  .e_zip_calendar {
    background-image: url("../../assets/images/modal/calendar.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    background-position: left;
  }
  .e_tooltip {
    max-width: 400px !important;
    right: 40px;
    min-width: none;
  }
  button {
    width: 100%;
    &:hover {
      img {
        transform: translateX(5px);
      }
    }
    img {
      transition: ease-in 0.3s;
    }
  }
  .e_amount_wrpper {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    height: 50px;
    overflow: hidden;
    transition: ease-in-out height 0.5s;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c0c0c0 !important;
      border-radius: 12px;
    }
    .e_title {
      color: $black;
      font-size: 16px;
      font-weight: 500;
    }
    // overflow-y: hidden;
    b {
      color: black;
    }
    p {
      @extend %font12;
      line-height: 16px;
      color: $input_placeholder;
      span {
        font-weight: 600;
        color: $input_text;
        @extend %font14;
      }
    }
    img {
      width: 20px;
      transition: ease transform 0.4s;
    }
    h6 {
      font-weight: 600;
      @extend %font12;
      line-height: 24px;
      color: $black;
    }
    .e_border_bottom {
      border-bottom: 2px solid $white;
    }
    .e_asset_class {
      font-weight: 600;
      font-size: 11px !important;
      // line-height: 24px;
      margin-block-end: auto;
      color: #010101;
    }
    .e_scheme {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      // color: rgba(1, 1, 1, 0.7);
      color: #010101;
      display: block;
    }
    .e_content_qty {
      p {
        font-weight: 500;
        @extend %font12;
        line-height: 24px;
        color: $black;
      }
    }
  }
  .e_success_icon {
    margin-top: 150px;
    width: 40px;
    height: 40px;
  }
  .e_success_text {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $input_text;
    padding-top: 10px;
  }
  .e_info {
    font-weight: 400;
    font-size: 12px;
    b {
      font-weight: 500;
    }
  }
}
