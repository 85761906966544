/*
 *   File : invest.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Invest modal style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

@import "./../config.scss";

.e_redeem_modal {
  .e_redeem_content {
    // min-height: 650px!important;
    padding: 0 !important;
    max-width: 540px !important;
    .e_modal_header {
      background: #f6f6f6;
      position: relative;
      border-radius: 12px 12px 0px 0px;
      height: 80px;
      img {
        position: absolute;
        right: 15px;
        top: 20%;
        cursor: pointer;
      }
      h5 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #010101;
        text-align: center;
        margin-top: 25px;
      }
    }
    .e_input_wrapper {
      position: relative;
      .e_input {
        padding: 8px 23px 8px 30px !important;
        position: relative;
      }
      .e_rupee {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #010101;
        font-family: "Roboto";
        position: absolute;
        top: 12px;
        left: 15px;
      }
    }
  }
  label {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #010101 !important;
  }
  .e_check_box {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #010101 !important;
  }
  // h5 {
  //   @extend %h5;
  // }
  .e_amount_wrpper {
    background: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    height: 50px;
    overflow: hidden;
    transition: ease-in-out height 0.5s;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c0c0c0 !important;
      border-radius: 12px;
      height: 2px;
    }
    img {
      width: 20px;
      transition: ease transform 0.4s;
    }
    .e_title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #0e1012;
    }
    p {
      @extend %font12;
      line-height: 16px;
      color: $black;
      font-weight: 500;
      color: $black;
    }
    h6 {
      font-weight: 600;
      @extend %font12;
      line-height: 24px;
      color: $input_text;
    }
    .e_border_bottom {
      border-bottom: 2px solid $white;
    }
  }
  .e_confirmation_message {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #010101;
  }
  .e_modal_submit {
    width: 100%;
  }
  .e_to_withdraw {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #010101;
  }
  .e_amount {
    font-weight: 700;
    @extend %font18;
    color: $input_text;
  }
  .e_loader_text {
    top: 50%;
  }
  .e_loader_img {
    left: 44%;
  }
}
