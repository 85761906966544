

@import '../config.scss';

.e_modal {
    .e_modal_content {
        padding: 0!important;
        max-width: 540px!important;
    }
    .e_modal_header {
        background: #F6F6F6;
        position: relative;
        border-radius: 12px 12px 0px 0px;
        height: 80px;
          img {
            position: absolute;
            right: 15px;
            top: 20%;
            cursor: pointer;
          }
          h5 {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #010101;
            text-align: center; 
            margin-block-end: auto;
          }
      }
      .e_subtitle {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #010101;
      }
      
      .e_note {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.6);
      }
}