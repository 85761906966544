/*-- Import supporting css*/
@import "./config.scss";
@import "./new-design.scss";
@import "./components/dateRangepicker.module.scss";
@import "./components/singleDatepicker.module.scss";
@import "./components/selectBox.module.scss";
@import "./components/switch.module.scss";
@import "./components/rangeSlider.module.scss";
@import "./components/pie.module.scss";
@import "./components/phoneInput.module.scss";
@import "./components/singleDate.module.scss";
@import "./components/rangePicker.module.scss";
@import "./components/side.module.scss";
@import "./components/edit.module.scss";
@import "./components/newSlider.module.scss";
@import "./components/headerDropdown.module.scss";
@import "./components/area.module.scss";
@import "./components/radioButton.module.scss";
@import "./components/progressBar.module.scss";

/*---------- Body ---------- */

body {
  font-family: $font-stack;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden !important;
  background-color: $white;

  // height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }
}

/*Chart*/
.e-graph-more {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #6d6d6d;
  cursor: pointer;
  // position: absolute;
  // bottom: 30px;
  transition: ease-in-out 0.3s;

  &:hover {
    text-decoration: underline;
  }
}

.e-piechart-label {
  top: 40px;
  right: 30%;
  height: 210px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    // border-radius: 10px;
    height: 0px !important;
    background: #f0f0f0;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 12px;
  }

  div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .e-assets-chart-label {
    font-weight: 500;
    font-size: 12px;
    // line-height: 17px;
    color: $input_placeholder;
    padding-left: 5px;
    margin-bottom: 20px;
  }

  .e-number {
    color: $input_text;
    font-weight: 600;
    @extend %font12;
    line-height: 16px;
  }
}

.e-area-chart {
  .apexcharts-toolbar {
    display: none !important;
  }
}

.e-tour-asset-container {
  .e-piechart-label {
    overflow: scroll !important;
  }
}

/*--------------auth-----------*/
.hidden {
  display: none !important;
}

.e-auth-container {
  .e-form-wrap {
    // padding-top: 100px;
  }

  .e-otp-resend-timer {
    font-size: 14px;
    font-weight: 500;
    color: #6d6d6d;

    span {
      font-weight: 500 !important;
      color: $primary_color;
    }

    // color: #1329e6;
  }

  .e-auth-banner {
    width: 32%;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    top: 0px;
    right: 0;
    overflow-y: hidden;
    z-index: 100;
  }

  h3 {
    @extend %h3;
    font-weight: 700 !important;
  }

  p {
    font-weight: 400;
    @extend %font14;
    line-height: 24px;
    color: $input_placeholder;

    span {
      font-weight: 600;
      color: $input_text;
    }
  }

  .pwd-container {
    position: relative;

    img {
      cursor: pointer;
      position: absolute;
      width: 20px;
      right: 20px;
      top: 50%;
    }
  }

  .pwd-container-auth {
    position: relative;

    img {
      cursor: pointer;
      position: absolute;
      width: 20px;
      right: 20px;
      top: 38%;
    }
  }
}

.e-card-edit {
  cursor: pointer;
}

.PhoneInputCountrySelect {
  display: none !important;
}

.e-rupee-portfolio {
  font-size: 15px;
  top: -4px;
  font-family: "Roboto";
  color: $input_text;
}
.e-no-pointer {
  pointer-events: none;
}
// .e_disable_phone {
// 	.PhoneInputCountrySelect {
// 		display: contents !important;
// 	}

.PhoneInputCountrySelectArrow {
  display: none !important;
}

// }

.e-btn-loader {
  pointer-events: none !important;
}

.e-btn-loader span {
  &:after {
    content: " .";
    font-family: "Epilogue";
    margin: 0;
    line-height: 100%;
    animation: dots 1s steps(1, end) infinite;
  }
}

@keyframes dots {
  0%,
  12.5% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    text-shadow: 0.5em 0;
  }

  75%,
  100% {
    text-shadow: 0.5em 0, 1em 0;
  }
}

.e-login-switch {
  background: #dfe2ff;
  color: #010104;
  font-size: 13px;
  display: inline-block;
  font-weight: 700;
  padding: 8px 30px 8px;
  border: 1px solid #6b73bb;
  border-radius: 6px;
  box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);

  &:hover {
    cursor: pointer;
  }
}

.e-login-switch-incative {
  font-size: 13px;
  font-weight: 700;
  display: inline-block;
  padding: 8px 30px 8px;
  border: 1px solid #547ec7;
  border-radius: 6px;
  // margin-left: 15px;
  opacity: 0.8;

  &:hover {
    cursor: pointer;
  }
}

// .e-active-form{
//     background: $white;
//     padding: 10px 15px;
//     box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
// }

// -------- Introduction ------------
.e-introduction-container {
  .e-video-sec {
    height: 280px;

    .e-video-thumbnail {
      height: 100%;
    }

    .e-video-play {
      &:hover ~ .e-video-thumbnail {
        width: 100%;
        height: 350px;
      }
    }
  }

  h5 {
    font-weight: 600;
    @extend %font24;
    line-height: 29px;
    color: $input_text;
  }

  h6 {
    font-weight: 700;
    @extend %font14;
    line-height: 24px;
    color: $input_text;
    margin-block-end: -2px;

    span {
      color: #010101;
      font-weight: 400;
    }
  }

  .e-submit {
    width: 100%;
  }
}

/*--------------onboard--------*/
.anticon svg {
  font-size: 22px !important;
}

.e-signup-radio {
  padding: 2px 30px;

  &:before {
    // left: 0!important;
    // top: 0!important;
  }
}

.e-marital-status {
  // padding: 2px 30px;
  &:before {
    left: 0 !important;
    // top: 0!important;
  }
}

.e-onboard-index {
  .e-onboard-card {
    background: #ffffff;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }

  h6 {
    font-weight: 700;
    @extend %font14;
    color: #010101;
    line-height: 17px;
  }

  p {
    font-weight: 500;
    @extend %font14;
    line-height: 24px;
    color: #6d6d6d;
    margin-block-end: 0px;
  }

  .e-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #010101;
  }

  .e-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #6d6d6d;
  }

  .e-onboard-img {
    width: 35px;
    height: 35px;
  }
}

.e-onboard-index-edit {
  font-size: 12px;
  position: relative;

  &::before {
    content: "";
    width: 0;
    position: absolute;
    height: 2px;
    bottom: 0;
    background-color: $primary_color;
    transition: ease 0.5s;
  }

  &:hover {
    &::before {
      width: 85%;
    }
  }
}

.e-mobile-view {
  display: none;
}

.e-onboard-container {
  .onboard-wrapper {
    background: #ffffff;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 20px;
    min-height: 100% !important;

    h6 {
      @extend %h6;
      font-weight: 700;
      padding: 16px 0 0;
    }

    p {
      font-weight: 500;
      font-size: 13px !important;
    }
  }
}

// .e-input-radio {
//   .e-sigup-radio {
//     .radioButton_e_radiobutton__MQ5Ie label:before {
//       border: 1px solid green !important;
//     }
//   }
// }
.e-tax-martital-status {
  margin-right: 70px;
}
.e-assets-table-card {
  background: #ffffff;

  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  padding: 24px 6px;
  .e-table-wrapper {
    background: rgba(235, 235, 235, 0.5);
    backdrop-filter: blur(4px);
    border-radius: 8px;
    padding: 16px 8px;
    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #010101;
    }

    border-radius: 8px;
  }
  .e-table-subtitle {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    h6 {
      margin-block-end: auto;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: $input_text;
    }
    &:hover {
      box-shadow: 0px 3px 3px rgb(0 0 0 / 12%);
      transform: scale(1.02);
    }
  }
  .e-table-subtitle-coming {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    h6 {
      margin-block-end: auto;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: rgba(1, 1, 1, 0.5);
    }
  }
  .e-table-row {
    background: #fafafa;
    border-radius: 8px;
    margin: 0;
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #010101;
      margin-bottom: 0 !important;
    }
  }
}

.e-uploader {
  background: $white;
  border: 1.8px dashed #e6e6e6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;

  .e-upload-span {
    h6 {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #010101;
      font-family: "Outfit";
    }
    button {
      background: #edf3ff;
      border: 1px solid #5a69ee;
      border-radius: 4px;
      padding: 8px 16px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #5a69ee;
    }
    span {
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      font-family: "Outfit";
      color: #7f7f7f;
      display: block;
    }
  }
}

.e-questioner-topic {
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 17px;
  color: #010101;
}

.e-question-label {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 24px;
}

.e-current-item {
  padding: 6px 10px;
  color: #1329e6;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  border: 2px dashed;
  border-radius: 50%;
}

.e-listed-item {
  padding: 6px 10px;
  color: #6d6d6d;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  border: 2px solid #6d6d6d;
  background-color: #e9ecff;
  border-radius: 50%;
}

.e-completed-item {
  color: $white;
  background-color: $primary_color;
  border-radius: 50%;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  border: 2px solid $primary_color;
}

.e-redeem-modal-checkbox {
  color: $primary_color;
  font-weight: 600;

  .checkBox_checkmark__27v4h {
    border: 1.6px solid $primary_color;
  }
}

.RSPBprogressBar {
  // position: fixed !important;
  z-index: 2 !important;
}

.RSPBprogressBar .RSPBprogression {
  left: unset !important;
}

.e-step-progress-wrapper {
  margin-top: 6%;
  position: sticky;
  top: 97px;
  z-index: 10;
}

.e-nominee-remove {
  margin-top: 15px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  float: right;
  transition: ease-in-out 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  img {
    width: 20px;
    margin-bottom: 10px;
  }
}

.e-confirm-otp {
  .e-back {
    margin-top: 100px;
    color: $input_placeholder;
    font-weight: 600;
  }

  .e-back-arrow {
    margin-right: 15px;
    cursor: pointer;

    &:hover {
      // transform: translateX(-10%);
      // transition: 0.4s ease-in-out;
    }
  }

  .e-back {
    &:hover {
      cursor: pointer;
      color: $primary_color;

      .e-back-arrow {
        transform: translateX(-10%);
        transition: 0.4s ease-in-out;
      }
    }
  }

  a {
    text-decoration: none;
  }
}

.e-onboard-process {
  // padding-top: 96px;
  span {
    font-weight: 700;
    @extend %font14;
    line-height: 10.5px;
  }

  .e-back {
    color: $input_placeholder;
  }

  .e-wrapper {
    padding: 40px 30px;
    border-radius: 8px;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
  }

  .e-process-wrap {
    h3 {
      font-weight: 600 !important;
    }

    .e-step-count {
      font-weight: 700;
      @extend %font12;
      line-height: 15px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $input_text;
    }

    .e-tooltip-hover {
      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }

    .e-step-tooltip {
      right: 0%;
      max-width: 295px;
      // height: 240px;
    }

    .e-skip-tooltip {
      top: 15px;
      right: 100px;
      position: absolute !important;
    }

    .e-tooltip-pan {
      width: 274px;
      right: 60%;
    }

    .e-next-span {
      background-color: $primary_color;
      border-radius: 30px;
      padding: 16px 15px 16px 10px;
      cursor: pointer;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transition: all 0.25s ease;
        border-radius: 30px;
        background: $primary_color;
        // box-shadow: inset 0 0 0 60px $primary_color;
      }

      &:hover:before {
        background: #0b1fca;
        box-shadow: inset 0 0 0 1px #0b1fca;
        transition: all 1s ease;
      }

      &:hover {
        transition: all 1s ease;
      }

      .e-form-submit {
        position: relative;
        transition: all 0.25s ease;
        left: 3px;
        top: -2px;
      }

      .e-loader-gif {
        width: 25px;
        position: relative;
        left: 3px;
        top: -2px;
      }
    }

    .e-next-disabled {
      opacity: 0.7;
      pointer-events: none;
    }

    .e-loader-btn {
    }

    .e-wraper {
      background: $white;
      box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      padding: 32px;

      h6 {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: $input_text;
      }

      .e-tooltip-address {
        width: 280px;
      }

      .e-tooltip-account {
        width: 274px;
        right: 20px;
      }
      label {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: $input_text;
      }
    }

    .e-radio-wraper {
      border: 1px solid $input_boder;
      border-radius: 8px;
      // padding: 30px 30px 30px 20px;
      cursor: pointer;
      display: flex;
      align-items: stretch;
      cursor: pointer;
      min-height: 75px;
      padding-left: 8px;
      // padding: 25px 10px 20px 10px;

      .e-radio-label {
        margin-bottom: 0px !important;
      }

      .e-quize-radio {
        margin: 0;
      }

      .e-radio,
      label {
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .e-radio-metric-wrapper {
      overflow: scroll;
    }

    .e-wrapper.e-analysis {
      label {
        font-weight: 500 !important;
      }

      p {
        font-weight: 400;
        @extend %font12;
        line-height: 15px;
        color: $input_placeholder;
      }

      .e-radio-wraper {
        border: 1px solid $input_boder;
        border-radius: 8px;
        padding: 16px 20px;
        min-height: 60px;

        span {
          font-size: 14px !important;
          line-height: 150%;
          color: #010101;
          font-weight: 400 !important;
        }

        .ant-radio-inner {
          position: relative;
          top: 2px !important;
          left: 0;
          display: block;
          width: 20px !important;
          height: 20px !important;
          background-color: #fff;
          border-color: #d9d9d9;
          border-style: solid;
          border-width: 1px;
          border-radius: 50%;
          transition: all 0.3s;
        }

        .e-radio-label {
          // font-weight: 400!important;
        }
      }

      .e-radiometrics span {
        font-weight: 400 !important;
      }

      .e-radiometrics-note {
        font-weight: 600;
        @extend %font14;
        line-height: 24px;
        color: $input_text;
      }

      .e-radiometrics p {
        @extend %font14;

        a {
          color: $primary_color;
          font-weight: 700;
          @extend %font14;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .e-wrapper.e-acct-agreement {
      p {
        font-weight: 500;
        @extend %font14;
        line-height: 24px;
        color: $input_text;
      }
    }
  }

  .e-sample-modal-a {
    color: #1329e6;
    text-decoration-line: underline;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }

  .e-verification {
    p {
      font-weight: 600;
      @extend %font14;
      line-height: 150%;
      color: $input_text;
    }

    .e-verification-wraper {
      background: $input_boder;
      border: 1px solid $input_boder;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 12px 15px;
      width: 160px;
      font-size: 24px;
      font-weight: 400;
    }
  }

  .e-verification-btn span {
    color: #fff !important;
  }

  .e-skip-btn span {
    color: $primary_color !important;
  }

  .e-back-arrow {
    padding-left: 25px;
    margin-right: 15px;
    cursor: pointer;

    &:hover {
      // transform: translateX(-10%);
      // transition: 0.4s ease-in-out;
    }
  }

  .e-back {
    &:hover {
      cursor: pointer;
      color: $primary_color;

      .e-back-arrow {
        transform: translateX(-10%);
        transition: 0.4s ease-in-out;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .e-capture-submit span {
    color: $primary_color !important;
  }
}

.e-onboard-submit-btn {
  span {
    color: $white !important;
  }
}

.e-record-btn {
  width: 250px;
  text-align: center;

  span {
    background-image: url("../assets/images/onboard/play-button.png");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 12px 20px;
    padding: 20px 36px;
    margin-left: 22px;
  }
}

.e-video-cam {
  border-radius: 8px !important;
  // height: 500px;
}

.e-sample-video-pose {
  width: 203px;
}

.e-stop-record {
  // width: 115px;
  height: 44px;
  background: #e74a3b;
  border-radius: 6px;
  padding: 12px 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  border: none;
  transition: ease-in-out 0.4s;
  &:hover {
    background-color: #ac3025;
  }
  &:after {
    background-color: #e74a3b !important;
  }
}

.e-rerecord {
  background-color: #d52828;
  border: 1px solid transparent !important;
  width: 250px;

  span {
    background-image: url("../assets/images/onboard/play-button.png");
    background-repeat: no-repeat;
    background-size: 21px;
    background-position: left;
    padding: 20px 30px;
  }

  &:after {
    background-color: red !important;
  }
}

.e-onboarding-step-4 {
  .e-step4-input {
    padding: 20px 50px 20px 20px;
  }
}

.e-boarding-file-preview {
  border-radius: 8px;
  background-color: #e7e7e952;
  background-image: url("../assets/images/onboard/tick.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 98%;
  height: 30px;
  border-bottom: 3.5px solid #0fd99c;
  p {
    font-weight: 600;
    font-size: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 93%;
  }
}

.e-file-preview-title {
  margin-block-end: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #656565;
}
.e-onboard-tooltip {
  background: #ffffff;
  box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: absolute;
  width: 321px;
  height: 144px;
  right: -30%;
  z-index: 2;

  img {
    width: 21px;
    height: 21px;
  }

  h5 {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #010101;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #010101;
    position: relative;

    &::before {
      content: "";
      width: 8px;
      position: absolute;
      height: 8px;
      background-color: #1329e6;
      border-radius: 100px;
      top: 30%;
      left: -15px;
    }
  }
}

.e-invest-modal-radio {
  margin-right: 120px;
  label {
    font-size: 16px !important;
  }
}

.e-edit-profile-radio {
  margin-right: 70px;
}

/*--------------Portfolio-----------*/
.e-portfolio-container {
  .e-portfolio-data-sec {
    margin-top: 70px;
  }
  .e-portfolio-card {
    width: 636px;
    margin-left: 30px;
  }

  .progress-bar {
    animation: reverse progress-bar-stripes 2s linear infinite;
  }

  .e-range-slider-loader {
    height: 60px !important;
  }

  .progress {
    animation: toRight 0.5s forwards;
    animation-delay: 1s;
    background-color: transparent !important;

    .progress-bar {
      background-color: $primary_color !important;
      border-radius: 8px !important;
    }
  }

  .e-faq-heading {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #010101;

    img {
      width: 15.75px;
    }
  }

  @keyframes toRight {
    0%,
    12.5% {
      transform: rotate(10deg);
    }

    25% {
      transform: rotate(45deg);
      opacity: 0;
    }

    50% {
      transform: rotate(90deg);
      opacity: 0;
    }

    75%,
    100% {
      transform: rotate(180deg);
      opacity: 1;
    }
  }

  .e-percent {
    font-weight: 500;
    color: $input_placeholder;
  }

  .e-total {
    font-weight: 300;
    @extend %font12;
    line-height: 16px;
    color: $input_placeholder;
  }

  .e-edit-investment {
    .e-investment-title {
      p {
        font-weight: 500;
        @extend %font14;
        color: $input_text;
      }

      span {
        font-weight: 300;
        @extend %font12;
        line-height: 16px;
        color: $input_placeholder;
        opacity: 0.77;
      }
    }
  }

  .e-invest-now {
    padding: 20px 0px !important;
    width: 100%;

    span {
      color: $white;
    }
  }

  // .e-piechart-label-type {
  //   top: 0px;
  //   right: 23%;
  //   // height: 65vh;
  //   overflow-y: auto;
  //   // position: absolute;
  //   &::-webkit-scrollbar {
  //     display: none;
  //   }
  //   p {
  //     font-weight: 500;
  //     @extend %font12;
  //     line-height: 24px;
  //     color: $input_placeholder;
  //     span {
  //       font-weight: 600;
  //       color: $input_placeholder !important;
  //     }
  //   }
  // }
  .e-risk-note {
    font-weight: 500 !important;
  }

  .e-risk-note-red {
    color: #a12b2b !important;
    font-weight: 500 !important;
  }
}

.e-graph-card {
  border-radius: 8px;
  position: relative;

  .apexcharts-canvas {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
  }

  .e-chart-label {
    padding: 5px;

    .e-chart-label-text {
      font-weight: 500 !important;
      font-size: 10px !important;
      line-height: 24px;
      color: #6d6d6d;
    }

    span {
      font-weight: 600 !important;
      font-size: 10px !important;
      line-height: 24px;
      color: #6d6d6d;
    }
  }

  .e-asset-chart-label-wrapper {
    height: 220px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      // border-radius: 10px;
      height: 0px !important;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #edf3ff;
      // border-radius: 10px;
    }
  }

  .e-invest-amount-eg {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #6d6d6d;

    b {
      font-weight: 700;
      font-size: 14px;
      color: #000000;
    }
  }

  .e-bar-wrapper {
    height: 229px;
    // background-color: red;
    width: 100%;
    $classes: "mintd-bar" $primary_color 35%, "deposit-bar" #af93f6, "gold-bar" #644dec 18%;

    @each $class, $color, $left in $classes {
      .e-#{$class} {
        width: 52px;
        background-color: $color;
        position: absolute;
        bottom: 0;
        left: $left;
        transition: height ease-in-out 0.5s;
      }

      h6 {
        position: absolute;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #010101;
        left: 20%;
        transition: bottom ease-in-out 0.5s;
      }

      .e-graph-legend {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
      }
    }
  }

  .e-graph-bottom {
    border-bottom: 1px solid #000000;
    height: 230px;
  }

  .e-switch {
    background: #f0f0f0;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #010101;
    width: 42px;
    height: 33px;
    text-align: center;
    line-height: 33px;
    cursor: pointer;
    transition: ease-in-out 0.3s;

    &:hover {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
    }
  }

  .e-active-switch {
    background: $primary_color;
    color: #fff;
    cursor: pointer;
  }
}

.e-methodology-link {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: $primary_color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.e-allocation-edit-card {
  // border: 1px solid #ccc;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.06);
  .e-edit-allocation-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: $black;
  }
}

.e-fund-name {
  font-weight: 500 !important;
  color: #000000 !important;
}

.e-display-name {
  font-weight: 400 !important;
  color: black;
  font-size: 12px;
}

.e-portfolio-btn {
  width: 60% !important;
}

.MuiSlider-markLabel {
  top: -10px !important;
}

.MuiSlider-markLabel[data-index="19"] {
  left: 99% !important;
}

.MuiSlider-markLabel[data-index="0"] {
  left: 1% !important;
}

.e-payment-radio {
  &::after {
    top: 6.3px !important;
    left: 6.3px !important;
  }
}

// progress bar
.e-progress-track {
  height: 4px;
  background-color: $white;
  width: 100%;

  .e-progress-bar {
    height: 4px;
    // background-color: $primary_color;
    border-radius: 12px;
    width: 0;
    -webkit-transition: width 0.5s;
  }
}

.e-animate {
  animation: toRight 0.2s forwards;
  // animation-delay:  2s;
}

@keyframes toRight {
  0%,
  12.5% {
    margin-left: 0%;
  }

  25% {
    margin-left: 25%;
  }

  50% {
    margin-left: 50%;
  }

  75% {
    margin-left: 75%;
  }

  100% {
    margin-left: 100%;
  }
}

.e-kyc-error-status {
  // color: #d52828!important;
  font-size: 14px;
  font-weight: 500 !important;
}

.e-video-sec-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #010101;
}

.e-video-sec {
  position: relative;
  overflow: hidden;
  height: 332px;

  .e-video-thumbnail {
    width: 100%;
    height: 332px;
    object-fit: cover;
    border-radius: 8px;
    transition: ease 0.5s;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 67.62%, #000000 114.31%);
    border-radius: 8px;
  }

  .e-video-play {
    position: absolute;
    // top: 40%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 56px;
    height: 56px;
    border-radius: 100%;
    text-align: center;
    background: radial-gradient(50% 50% at 50% 50%, #1329e6 0%, #e7eafd 90.1%);
    border: 1px solid $primary_color;
    padding: 0px 20px;
    z-index: 5;
    transition: ease-in-out 0.5s;

    &:hover {
      padding: 0px 18px;
      border: 2px solid $primary_color;
      transform: scale(0.8);
      background: radial-gradient(50% 50% at 50% 50%, #1329e6 60%, #e7eafd 90.1%);
    }

    &:hover ~ .e-video-thumbnail {
      width: 100%;
      height: 400px;
    }

    img {
      width: 20.16px;
      height: 22.68px;
    }
  }

  .e-text-wrapper {
    position: absolute;
    width: 100%;
    height: 77px;
    bottom: 0;
    left: 0;
    z-index: 2;

    .e-ceo-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.16px;
      color: #ffffff;
      margin-block-end: 2px;
    }

    .e-designation {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.16px;
      color: #ffffff;
    }
  }
}

/*--------------Overview-----------*/
.e-tour-overview-container {
  .e-invest-more {
    padding: 12px 16px;
  }
}
.e-overview-container {
  // padding: 0 52px 130px;
  // background-color: $background_bg !important;

  h5 {
    @extend %h5;
  }

  .e-overview-graph-loader {
    height: 355px !important;
  }

  .e-area-chart-scroll {
    // overflow-x: scroll;
    // overflow-y: hidden;
    // &::-webkit-scrollbar {
    //   // border-radius: 10px;
    //   height: 0px !important;
    //   width: 8px;
    // }
  }

  .e-dashboard-summary-card .e-invest-more {
    padding: 12px 16px !important;
  }

  .e-chart-wrapper {
    background: $white;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 36px 10px 32px 32px;

    .apexcharts-canvas {
      left: -15px;
    }

    h6 {
      @extend %h6;
      font-weight: 700;
      padding-bottom: 30px;
    }
  }

  .e-pending-tooltip {
    right: 55px;
    min-width: 450px !important;
  }

  .e-pending-amount {
    background-color: #ffffff !important;
    padding: 18px 18px 16px 18px;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
    border-radius: 8px !important;

    p {
      color: $input_placeholder;
      font-weight: 500;
      @extend %font14;
      line-height: 20px;
      margin-bottom: 0;

      span {
        @extend %font16;
        color: $black;
        font-weight: 700 !important;
      }
    }

    .e-icon {
      background: $white;
      box-shadow: 0px 0px 16px rgba(19, 41, 230, 0.24);
      border-radius: 100px;
      padding: 20px;

      img {
        position: relative;
        top: -1px;
        margin-right: 0 !important;
      }

      // position: relative;
      // z-index:999;
      // transition: 0.4s ease-in-out;
      &:hover {
        // cursor: not-allowed;
        transform: translateX(10%);
        transition: 0.4s ease-in-out;
        box-shadow: 0px 0px 16px rgba(19, 41, 230, 0.6);
      }
    }
  }

  .e-span-wrap {
    cursor: pointer;

    .e-profile-active-btn {
      background: $primary_color;
      border-radius: 4px;
      color: $white;
      @extend %font12;
      padding: 9px 10px;
      width: 100%;
      margin-right: 10px;
      font-weight: 500;
      line-height: 18px;
    }

    .e-profile-btn {
      background: $input_boder;
      border-radius: 4px;
      color: $input_text;
      @extend %font12;
      padding: 9px 10px;
      width: 100%;
      margin-right: 10px;
      font-weight: 500;
      line-height: 18px;

      &:hover {
        transform: translateX(10%);
        transition: 0.4s ease-in-out;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);
      }
    }
  }

  .e-home-link-btn {
    font-weight: bold;
    font-size: 14px;
  }
}

// dashboard warning strip
.e-warnig-strip {
  width: 100%;
  background-color: #edf3ff;
  box-shadow: 4px 4px 17px 3px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  vertical-align: middle;

  .e-strip-content {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px !important;
  }

  .e-rebalance-btn {
    padding: 12px 12px !important;
  }
}

.e-onboard-index-warning {
  // position: fixed;
  // bottom: 20px;
  // width: 600px;
  // right: 0;
  // z-index: 10;
}

.e-onboard-tour-btn {
  padding: 18px 0px;
}

/*------------Assets--------*/
.e-assets-container {
  .e-pie-container {
    background: $white;
    border: 1px solid $input_boder;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 20px 32px 40px 25px;
    height: 355px;
    position: relative;

    .apexcharts-canvas {
      left: -86px;
    }

    .e-piechart-label {
      top: 100px;
      // width: 300px!important;
      // right: 11%;
    }

    h6 {
      @extend %h6;
      font-weight: 700;
    }
  }

  .e-tooltip {
    left: 33%;
    max-width: 350px;
  }

  .e-table-wrapper {
    background: $white;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
    padding: 24px 6px;

    th {
    }

    h6 {
      @extend %h6;
      color: $input_placeholder;
      font-weight: 600;
    }
  }

  h6 {
    @extend %h6;
    font-weight: 700;
    padding-left: 32px;
  }

  .e-table-content {
    background: $white;
    padding: 24px 32px;
    cursor: pointer;

    p {
      @extend %font14;
      font-weight: 500;
      padding-bottom: 22px;
      margin-bottom: 0;
      overflow-wrap: break-word;
    }

    .e-progress {
      background-color: transparent !important;
      padding-bottom: 50px;
      padding-top: 14px;
      overflow: hidden;

      .e-progress-bar {
        width: 0;
        height: 8px;
        background-color: $primary_color !important;
        border-radius: 8px !important;
        animation: 1s ease-out 0s 1 slideInFromLeft;
      }

      .e-value {
        font-weight: 500;
        @extend %font14;
        color: $input_text;
        position: relative;
        top: -7px;
        left: 0px;
      }
    }

    @keyframes slideInFromLeft {
      0% {
        transform: translateX(-100%);
      }

      100% {
        transform: translateX(0);
      }
    }

    // .progress {

    //   padding-bottom: 50px;
    //   .progress-bar {
    //     background-color: $primary_color !important;
    //     border-radius: 8px !important;
    //   }
    //   .e-value {
    //     font-weight: 500;
    //     @extend %font14;
    //     color: $input_text;
    //     position: relative;
    //     top: -5px;
    //     left: 0px;
    //   }
    // }
    .e-color1 {
      .progress {
        .progress-bar {
          background-color: #6439ec !important;
        }
      }
    }

    .e-color2 {
      .progress {
        .progress-bar {
          background-color: #8d43f1 !important;
        }
      }
    }

    span {
      color: $green;
      font-weight: 600;
      @extend %font14;
      padding-bottom: 22px;
      display: block;
    }
  }

  .e-table-hover {
    height: 75px;

    &:hover {
      .e-detail-arrow {
        transform: translateX(60%);
        transition: 0.4s ease-in-out;
      }
    }
  }

  .e-span-wrap {
    .e-profile-active-btn {
      padding: 5px 10px !important;
    }

    .e-profile-btn {
      padding: 5px 10px !important;
      cursor: pointer;
    }
  }

  .e-piechart-label {
    // width: 30%;
  }
}

.e-profile-edit-btn {
  color: $primary_color !important;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  transition: ease-in-out 0.5s;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.e-assets-title {
  background-color: #f6f9ff !important;

  h6 {
    padding-left: 0 !important;
  }
}

.e-radio-buttons {
  display: flex;
  label {
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 15px;
    color: $input_text;
  }
}

.e-tax-input {
  padding-left: 30px !important;
  // padding: 6px 23px 6px 30px !important;
  position: relative;
  // transition: ease padding 0.5s;
}
// .e-tax-input:focus {
//   padding: 10px 23px 10px 30px !important;
// }
// .e-tax-input[value]:not([value=""]) {
//   padding: 10px 23px 10px 30px !important;
// }
.e-ruppe {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #010101;
  font-family: "Roboto";
  position: absolute;
  top: 69px;
  left: 28px;
}

.e-assets-chart-label {
  // width: 78%;
  text-overflow: ellipsis;
  // height: 33px;
  // overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/*------------Scrip Detail--------*/
.e-detail-bg {
  background-color: $input_text;
  padding: 50px 52px 30px 65px;
  margin-top: 90px;

  h4 {
    @extend %font32;
    color: $white !important;
    font-weight: 600;
    padding: 20px 0 0;
  }

  a {
    text-decoration: none;
  }

  p {
    opacity: 0.67;
    color: $white;
  }

  .e-back {
    color: $white !important;
    text-decoration: none !important;
    font-weight: 700;
    @extend %font14;
  }

  .e-back {
    &:hover {
      cursor: pointer;

      .e-back-arrow {
        transform: translateX(-20%);
        transition: 0.4s ease-in-out;
      }
    }
  }
}

.e-scrip-container {
  .e-span-wrap {
    .e-profile-active-btn {
      padding: 5px 10px !important;
    }

    .e-profile-btn {
      padding: 5px 10px !important;
      cursor: pointer;
    }
  }

  .e-fund-wrapper {
    background-color: $white;
    padding: 32px;
    border-radius: 8px;

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: $input_placeholder;
    }

    .e-returns {
      font-weight: 500;
      @extend %font18;
      padding: 25px 0 0;
    }

    .e-tooltip {
      right: 10%;
      max-width: 285px;
    }

    h6 {
      @extend %h6;
      color: $input_placeholder;
      font-weight: 500 !important;
    }

    .e-currnt-value {
      color: $input_text;
      font-weight: 700;
    }
  }

  .e-pie-container {
    background-color: $white;
    padding: 32px;
    border-radius: 8px;
    border: 1px solid $input_boder;

    h6 {
      @extend %h6;
      font-weight: 700;
    }

    .apexcharts-canvas {
      left: -110px;
    }

    .e-piechart-label-type {
      top: 70px;
      right: 21%;
      height: 210px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        // border-radius: 10px;
        height: 0px !important;
        background: #f0f0f0;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #c0c0c0;
        border-radius: 12px;
      }

      .e-sector {
        font-weight: 500;
        @extend %font12;
        line-height: 24px;
        padding-top: 2px;
        color: $input_placeholder;
        // width: 175px;
        overflow-x: hidden;
      }

      .e-sec-allctn {
        font-weight: 600;
        color: $input_text !important;
        @extend %font12;
        // position: relative;
        // right: -62%;
      }
    }
  }
}

.e-progress-text {
  p {
    line-height: 24px;
    font-weight: 400 !important;
    @extend %font12;
    color: $input_placeholder !important;
  }

  img {
    width: 24px;
    height: 24px;
    padding-top: 2px;
  }
}

.e-zip-calendar {
  background-image: url("../assets/images/modal/calendar.svg");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  background-position: left;
}

/*Profile*/
.e-side-wrap {
  background-color: $white !important;
  padding: 32px;
  box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
  border-radius: 8px !important;
  border: 1px solid $input_boder;

  .e-delete {
    position: absolute;
    top: 60px;
    right: 40px;
    background: #ffffff;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    padding: 24px 128px 24px 34px;
    color: $input_text;
    font-weight: 500;
    @extend %font14;
    line-height: 24px;

    span {
      opacity: 0.3;
    }

    &:hover {
      transform: scale(1.02);
      cursor: pointer;
    }
  }
}

.e-rebalanace-status-card {
  background-color: $tooltip_bg !important;
  padding: 32px;
  box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
  border-radius: 8px !important;
  border: 1px solid $input_boder !important;
  transition: box-shadow 0.3s;

  a {
    text-decoration: none;
  }

  h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $black;
    cursor: pointer;
  }

  p {
    @extend %font14;
    line-height: 19px;
    font-weight: 400;
    color: $input_placeholder;

    span {
      color: $black;
      font-weight: 600;
    }
  }
}

.e-profile-img {
  @extend %font24;
  background-color: #edf3ff;
  color: #13293c;
  font-weight: 700;
  line-height: 17px;
  border-radius: 100%;
  margin-bottom: 16px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.e-icon:hover {
  opacity: 0.6;
  cursor: pointer;
}

.e-edit-icon:hover {
  transform: scale(1.08);
  cursor: pointer;
}

.e-edit-img {
  position: absolute;
  top: 40%;
  right: 36%;
}

.e-profile-sec {
  background: linear-gradient(90deg, #edf3ff 0%, rgba(237, 243, 255, 0) 100%);
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 8px;

  h6 {
    font-weight: 600;
    @extend %font12;
    color: $input_placeholder;
    line-height: 16px;
  }

  p {
    color: $input_text;
    font-weight: 600;
    line-height: 24px;
    @extend %font14;
    padding-left: 10px;
  }

  .e-score {
    @extend %font18;
    padding-top: 8px;
  }

  .e-edit {
    font-weight: 600;
    @extend %font12;
    line-height: 16px;
    color: $primary_color;
    margin-right: 2rem;
    cursor: pointer;
  }

  .e-remove {
    font-weight: 600;
    @extend %font12;
    line-height: 16px;
    color: $red;
    margin-right: 1.5rem;
    cursor: pointer;
  }

  .iconStyleToolTip_e_icon_tooltip__1eSJm {
    background: #ffffff;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    padding: 25px 25px 0px 35px !important;
    position: absolute;
    z-index: 1;
    width: 274px !important;
    min-width: 0px !important;
    height: auto !important;
    right: 5rem;
    top: 17rem;
  }
}

.e-detail-sec-sip {
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 25px 13px 11px 20px;
  background-color: $white;

  h6 {
    font-weight: 600;
    @extend %font14;
    letter-spacing: 0.01em;
    color: $input_placeholder;
  }
}

.e-detail-sec {
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 4px 16px;
  background-color: $white;
  min-height: 95px;

  h6 {
    @extend %font12;
    font-weight: 600;
    color: $input_placeholder;
    line-height: 16px;
  }

  p {
    color: $input_text;
    @extend %font14;
    font-weight: 600;
    line-height: 24px;
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th {
    width: 240px;
    @extend %font12;
    color: #6d6d6d;
    padding: 12px 42px 16px 16px;
    font-weight: 600;
  }

  td {
    @extend %font14;
    color: #010101;
    padding: 22px 42px 22px 16px;
    font-weight: 400;
  }

  tr:nth-child(even) {
    background-color: #edf3ff;
  }

  .secondary_e_s_btn__tCUZ_ {
    border: 1px solid $input_text;
    background-color: transparent;
    color: $input_text;
    @extend %font14;
    font-weight: 700;

    &:after {
      color: $secondary_color !important;
      background: #edf3ff;
      border-radius: 8px;
      height: calc(100% + 0px);
      position: absolute;
      content: "";
      top: 0px;
      left: 0;
      width: 0;
      transition: all 0.35s;
      z-index: 1;
      opacity: 0;
    }

    &:hover:after {
      opacity: 1;
      width: 100%;
      box-shadow: none !important;
    }

    span {
      z-index: 999;
      position: relative;
    }
  }
}

.password-container-auth {
  position: relative;

  img {
    cursor: pointer;
    position: absolute;
    width: 20px;
    right: 20px;
    top: 38%;
  }
}

.e-update {
  padding: 18px 80px !important;
}

.e-change-pwd {
  width: 100%;
}

.e-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.e-modal-loader {
  height: 400px !important;
}

.e_wrapper_splash {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  z-index: 99999;
  // text-align: center;
}

.e_img_splash {
  position: absolute;
  top: 45vh;
  left: 42%;
  animation: img-zoom-out 2s 1s forwards;
}

@keyframes img-zoom-out {
  0% {
    position: absolute;
    top: 45vh;
    // left: 42%;
    height: 100px;
  }

  100% {
    position: absolute;
    height: 32px;
    left: 3.7%;
    top: 4.3%;
  }
}

/*---------Faq---------*/
.e-faq-container {
  background-color: $background_bg;
}

.e-faq-bg {
  padding: 55px 52px 0px 65px !important;
  margin-top: 75px !important;
}

.e-faq-wraper {
  padding: 0 70px 0 100px;
}

/*----updates-portfolio modal----*/
.e-allocate-assets {
  h5 {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: $black;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
}

.e-bank-note {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $input_text;
}

span {
  font-weight: 600;
}

.e-penny-note {
  padding-top: 18px !important;
}

.e-tooltip-font {
  .cardIconStyleToolTip_e_card_tooltip__1Q9_7 {
    span {
      font-size: 12px;
    }
  }
}

.e-btn-position {
  position: fixed;
  bottom: 16px;
  z-index: 99;
}

.e-pointer-cursor {
  cursor: pointer;
}

// Change score index
.e-infocard-edit-score {
  background: #ffffff;
  box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  img {
    width: 36px;
    height: 36px;
  }

  h6 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: $input_text;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    color: #6d6d6d;
    padding-right: 30px;
    margin-block-end: 0;
  }
}

.e-editsore-index {
  .e-continue-btn {
    padding: 18px 118px;
  }

  .e-description {
    margin-block-end: 8px;
  }
}

.e-editscore-indicator {
  background-color: #edf3ff;
  border-radius: 8px;
  height: 124px;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #010101;
  }

  .e-next-span {
    cursor: pointer;
    padding: 20px 18px;
    background-color: $primary_color;
    border-radius: 100px;
    transition: ease 0.3s;

    &:hover {
      box-shadow: 0px 0px 36px rgba(19, 41, 230, 0.48);
    }
  }
}

.e-tour {
  h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $input_text;
  }

  h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #010101;

    a {
      color: $primary_color !important;
      text-decoration: none !important;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 0;
  }

  .e-completed-item {
    width: 24px;
    height: 24px;
    padding: 3px;
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  .RSPBprogressBar {
    bottom: -58px;
    height: 3px !important;
    background: transparent !important;
    left: -22px;
  }
}

.Toastify__toast {
  width: 400px !important;
}

.Toastify__toast-container--bottom-center {
  bottom: 0px !important;
  left: 47%;
  transform: translateX(-50%);
}

.Toastify__toast-body {
  font-family: "Montserrat" !important;
}

.Toastify {
  font-family: "Montserrat" !important;
}

/*Tour modal*/
.e-tour-modal h6 {
  font-weight: 600 !important;
}

.e-skip-tour-btn,
.e-take-tour {
  font-weight: 700 !important;
  width: 100% !important;
}
.e-take-tour {
  border: 1.8px solid $primary_color;
}

.react-joyride__tooltip {
  border-radius: 8px !important;

  // button:nth-child(1) {
  //   font-weight: 500 !important;
  //   font-size: 12px !important;
  //   color: #6d6d6d !important;
  //   margin-bottom: 0 !important;
  //   border-radius: 50% !important;
  //   background: transparent !important;
  //   padding: 0 !important;
  //   touch-action: none !important;
  //   margin-top: 18px !important;

  //   .e-next-span {
  //     cursor: pointer;
  //     transition: ease 0.3s;
  //   }
  // }

  .e-tour-button {
    width: 36px;
    height: 36px;
    background: #4254eb;
    border-radius: 4.5px;
    padding: 7px 11px 9px;
    transition: ease-in-out 0.4s;
    &:hover {
      background: $primary_color;
    }
  }

  .e-tour-back {
    width: 36px;
    height: 36px;
    background: $white;
    border: 2px solid #5a69ee;
    border-radius: 4.5px;
    padding: 4px 0px 8px;
    transition: ease-in-out 0.4s;
    &:hover {
      background: #edf3ff;
    }
    img {
      width: 34px;
    }
  }
  button {
    background: transparent !important;

    &:focus-visible {
      outline: none !important;
    }
  }

  svg {
    position: relative;
    top: 15px;
    right: 12px;
    fill: black !important;
    stroke: #000000;
    stroke-width: 1.1px;
    width: 20px !important;
    height: 20px !important;
    background: rgba(0, 0, 0, 0.1);
    padding: 4px;
    border-radius: 50%;
  }

  img {
    &:focus-visible {
      outline: none !important;
    }
  }
}
.react-joyride__overlay {
  .react-joyride__spotlight {
    border-radius: 4px !important;
    position: absolute !important;
    background-color: gray !important;
    pointer-events: none;
  }
}
.react-joyride__tooltip {
  padding: 24px 24px 6px !important;
}

.react-joyride__tooltip div {
  padding: 0px !important;
  margin-top: 0px !important;
}

.e-next-span {
  &:hover {
    transform: scale(0.05);
  }
}
.react-joyride__tooltip button[data-action="close"] {
  top: -4px !important;
  right: -7px !important;
}

.react-joyride__tooltip button.e-finish-tour-btn {
  background: #5a69ee !important;
  color: $white !important;
  margin-top: 20px;
  padding: 12px 16px !important;
  border-radius: 8px !important;
  border: none !important;
  position: relative;
  right: 0;
}

.react-joyride__spotlight {
  border-radius: 16px !important;
}

#e-sec-6.react-joyride__spotlight {
  height: 600px;
  left: 1189px !important;

  top: 70px;
  width: 295px !important;
}

.__floater__arrow svg polygon {
  fill: #ffffff !important;
}

/*Signup*/
.e-auth-video-container {
  .e-form-wrap {
    margin-top: 100px;
  }
}

.hover-video-player-container,
video {
  height: 100vh !important;
}

.e-video-player {
  position: relative;
  overflow: hidden;
  transition: all 0.9s ease-in-out;
  height: 100vh;
  text-align: center;

  // &:hover {
  //   cursor: pointer;
  //   transition: all 0.9s ease-in-out;
  //   .hover-video-player-container,
  //   video {
  //     // transition: all 0.9s ease-in-out;
  //     // transform: scale(1.2) !important;
  //   }
  // }
}

.paused-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(14, 16, 18, 0.24);
  backdrop-filter: blur(2px);
}

.e-video-player .e-video-play {
  position: absolute;
  top: 50%;
  left: 46%;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  text-align: center;
  background: radial-gradient(50% 50% at 50% 50%, #1329e6 0, #e7eafd 90.1%);
  border: 1px solid transparent;
  padding: 0 20px;
  z-index: 5;
  transition: 0.5s ease-in-out;

  &:hover {
    padding: 0 18px;
    border: 2px solid #1329e6;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    background: radial-gradient(50% 50% at 50% 50%, #1329e6 60%, #e7eafd 90.1%);
  }

  img {
    width: 21.6px;
    height: 24.3px;
  }
}

.e-video-player .e-video-play.e-video-play-btn {
  background: radial-gradient(50% 50% at 50% 50%, #1329e6 0%, #1329e6 52.6%, #e7eafd 90.1%);
  border: 1px solid rgba(19, 41, 230, 0.5);

  img {
    width: 15.56px;
    height: 19.15px;
  }
}

// Ivest modal hdfc notification
.e-invest-modal-accordion {
  .accordion-item {
    background: #edf3ff;
    border: 1px solid #f0f0f0;
    border-radius: 8px;

    .e-accordion-btn {
      background: #edf3ff;
      border: none;
      outline: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      width: 100%;
      color: #010101;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        border: solid #010101;
        right: 20px;
        top: 10px;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 5px;
      }
    }

    .e-accordion-btn[aria-expanded="false"] {
      &::after {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        top: 12px;
      }
    }

    .e-accordion-btn[aria-expanded="true"] {
      &::after {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        top: 14px;
      }
    }

    .accordion-body {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      align-items: center;
      color: #000000;

      b {
        font-weight: 700;
      }
    }
  }
}

.e-create-account-btn {
  border: 1px solid $input_boder;
  box-sizing: border-box;
  box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
  color: $primary_color;
  padding: 16px !important;
  // position: absolute;
  // right: 3%;
  // top: 7%;
}

.e-login-btn-redirect {
  padding: 16px 51.2px !important;
}

.e-back-mw {
  color: #000000;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  transition: ease-in-out 0.3s;

  &:hover {
    color: $primary_color;
    text-decoration: underline;
  }
}

.e-back-login-mw {
  color: $primary_color;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  transition: ease-in-out 0.3s;

  &:hover {
    color: #000000;
    text-decoration: underline;
  }
}

.e-auth-btn-wrapper {
  position: absolute;
  right: 4%;
  top: 5%;
}

.e-logo-small-screen {
  position: absolute;
  top: 7%;
  left: 3%;
  cursor: pointer;
}

.e-login-btn-header {
  font-weight: 600;
  @extend %font14;
  line-height: 17px;
  color: $input_text !important;
  // position: absolute;
  // right: 3%;
  // top: 7%;
}

.e-slider-card {
  background: #edf3ff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  height: 309px;
  padding: 32px;

  .e-close {
    cursor: pointer;
    transition: ease 0.5;

    &:hover {
      transform: scale(1.2);
    }
  }

  .slick-list {
    height: 165px;
  }

  h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #6d6d6d;

    b {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
    }
  }

  .e-order-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    cursor: pointer;
    width: max-content;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: -22px;
      top: 5px;
      height: 20px;
      width: 20px;
      background-image: url("../assets/images/overview/right.svg");
      background-repeat: no-repeat;
      transition: ease 0.5s;
    }

    &:hover {
      &::after {
        right: -26px;
      }
    }
  }
}

.slick-dots {
  overflow: hidden;
  white-space: pre;
  left: 26%;
  width: 46%;

  li {
    button {
      &::before {
        color: #6d6d6d;
        opacity: 1;
        font-size: 7px;
      }
    }
  }

  .slick-active {
    button {
      &::before {
        color: $primary_color !important;
        opacity: 1;
        font-size: 9px;
      }
    }
  }
}

.e-slider-footer {
  position: absolute;
  bottom: 21px;
  width: 100%;

  .e-view-more-notification {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1329e6;
  }

  img {
    cursor: pointer;
  }
}

// notification
.e-notification-container {
  background-color: #f6f9ff !important;
  padding-bottom: 100px;

  .e-notification-list-title-wrapper {
    background-color: #000000;
    height: 200px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 10%;
      opacity: 0.2;
      width: 300px;
      height: 200px;
      background-image: url("../assets/images/m-logo.png");
      background-repeat: no-repeat;
      background-size: 279px;
      background-position: 13% 100%;
    }

    // padding: 80px 50px;
    .e-back {
      color: $white;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;
      transition: ease 0.5;

      &:hover {
        opacity: 0.7;
      }
    }

    .e-title-image {
      width: 284.99px;
      opacity: 0.2;
    }

    h4 {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: $white;
    }
  }

  .e-notification-list-card {
    background-color: $white;
    border: 1px solid #f0f0f0;
    border-radius: 8px;

    h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: $black;
    }

    h6 {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $black;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #6d6d6d;
    }
  }
}

.e-fade-out {
  opacity: 0;
  transition: opacity ease 0.5s;
}

.e-slider-prev {
  height: 36px;
  width: 36px;
  border-radius: 100px;
  outline: none;
  border: 1px solid #6d6d6d;
  background-color: transparent;
  transition: ease 0.5s;

  &:hover {
    background-color: $primary_color;
    border: 1px solid $primary_color;

    svg {
      path {
        fill: #ffffff !important;
      }
    }
  }

  svg {
    width: 18px;
    height: 18px;
    margin-bottom: 3px;
  }
}

.e-slider-next {
  height: 36px;
  width: 36px;
  border-radius: 100px;
  outline: none;
  border: 1px solid #6d6d6d;
  background-color: transparent;
  transition: ease 0.5s;

  &:hover {
    background-color: $primary_color;
    border: 1px solid $primary_color;

    svg {
      path {
        fill: #ffffff !important;
      }
    }
  }

  svg {
    width: 18px;
    height: 18px;
    margin-bottom: 3px;
  }
}

.e-refer-link-wrap {
  background-color: #1329e6;
  border-radius: 100px;
  height: 100%;
  width: 39px;
  text-align: center;
  padding-top: 5px;
  // margin-left: 8px;
}

.e-take-tour-btn {
  color: $secondary_color;
  margin-left: 15px;
  background-color: $white;
  border: none;
  outline: none;
  border-radius: 8px;
  border: 1px solid $secondary_color;
  padding: 8px 18px;
  line-height: 18.5px !important;
  font-weight: 500 !important;
  font-size: 12.1294px !important;
  cursor: pointer;

  &:hover {
    background-color: $background_bg;
  }

  img {
    width: 13.46px;
  }
}

.e-logout-icon {
  width: 30px;
  overflow: hidden;
  display: flex;
  transition: ease 0.5s;
  float: right;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: $primary_color !important;
  cursor: pointer;

  &:hover {
    // width: 100px;
  }

  .e-label {
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 15px !important;
  }

  //   a:hover .logout{
  // 	opacity: .9;
  //   }
  img {
    width: 24px;
    float: left;
    height: 22px;
    margin-right: 11px;
  }
}

.e-onboard-next {
  width: 100%;
  display: none;

  img {
    position: inherit;
    z-index: 9;
    height: 17px;
  }

  .e-hide-arrow {
    opacity: 0;
  }
}

.e-personal-detail-icon {
  display: flex;
}

//   .logout {
// 	font-size: .8em;
// 	font-family: 'Oswald', sans-serif;
// 	  position: relative;
// 	right: -18px;
// 	bottom: -4px;
// 	overflow: hidden;
// 	letter-spacing: 3px;
// 	opacity: 0;
// 	transition: opacity .45s;
// 	-webkit-transition: opacity .35s;

//   }

//   .button {
// 	  text-decoration: none;
// 	  float: right;
// 	padding: 12px;
// 	margin: 15px;
// 	color: white;
// 	width: 25px;
// 	background-color: black;
// 	transition: width .35s;
// 	-webkit-transition: width .35s;
// 	overflow: hidden
//   }

// Portfolio tracker
.e-portfolio-tracker {
  padding: 0 52px 130px;
  background-color: $background_bg !important;

  .e-page-title {
    font-style: normal;
    font-weight: 600;
    @extend %font20;
    line-height: 24px;
    color: $input_text;
  }

  .e-title {
    font-style: normal;
    font-weight: 700;
    @extend %font16;
    line-height: 24px;
    color: $input_text;
  }

  .e-portfolio-stat-card {
    background-color: $modal_bg;
    border: 1px solid $input_boder;
    border-radius: 8px;
    height: 330px;
    label {
      font-style: normal;
      font-weight: 500;
      @extend %font14;
      line-height: 17px;
      color: $input_placeholder;
    }

    .e-amount {
      font-style: normal;
      font-weight: 600;
      @extend %font24;
      line-height: 29px;
      color: $primary_color;
    }

    .e-assets {
      font-weight: 600;
      @extend %font18;
      line-height: 22px;
      color: $input_text;
    }

    .e-card-btn {
      padding: 20px 0;
      width: 100%;
    }

    .e-s-btn {
      background-color: #edf3ff !important;
    }
  }

  .e-graph-card {
    background-color: $white;
    border: 1px solid $input_boder;
    border-radius: 8px;
    cursor: pointer;
    min-height: 349px;

    .e-graph-nodata {
      height: 200px;
      object-fit: contain;
    }

    .apexcharts-canvas {
      position: relative;
      left: 10% !important;
    }

    .e-graph-legends {
      padding: 9px 0px 9px 10px;
      width: 55%;
      position: relative;
      background-color: $white;
      border-radius: 8px;
      border: 1px solid transparent;

      &::after {
        content: "";
        position: absolute;
        top: 42%;
        right: 6%;
        width: 9px;
        height: 9px;
        border: solid #1329e6;
        border-width: 0 1.8px 1.8px 0;
        display: inline-block;
        padding: 2px;
        opacity: 0;
        transition: ease-in-out opacity 0.3s;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }

      &:hover {
        background-color: $modal_bg;
        border-radius: 8px;
        border: 1px solid $input_boder;

        &::after {
          opacity: 0.7;
        }
      }

      .e-graph-indicator {
        padding: 4px !important;
        border-radius: 100px;
        display: inline-block;
      }

      .e-legend-value {
        font-style: normal;
        font-weight: 500;
        @extend %font12;
        line-height: 24px;
        color: $input_placeholder;
        text-transform: capitalize;

        b {
          @extend %font14;
          color: $input_text;
          vertical-align: middle;
        }
      }
    }

    // .e-active-legend {
    // 	background-color: $modal_bg;
    // 	border-radius: 8px;
    // 	border: 1px solid $input_boder;
    // 	position: relative;
    // 		&::after {
    // 			content: "";
    // 			position: absolute;
    // 			top: 42%;
    // 			right: 6%;
    // 			width: 9px;
    // 			height: 9px;
    // 			border: solid #1329e6;
    // 			border-width: 0 1.8px 1.8px 0;
    // 			display: inline-block;
    // 			padding: 2px;
    // 			opacity: 0.7;
    // 			transform: rotate(-45deg);
    // 			-webkit-transform: rotate(-45deg);
    // 		}
    // }
  }

  .e-asset-card {
    background: $white;
    border: 1px solid $input_boder;
    border-radius: 8px;

    span {
      font-weight: 500;
      @extend %font14;
      line-height: 17px;
      color: $input_placeholder;
    }

    h6 {
      font-weight: 600;
      @extend %font18;
      line-height: 22px;
      color: $input_text;
    }

    .e-coming-soon {
      font-size: 16px;
      font-weight: 600;
    }

    .e-asset-card-arrow {
      transition: ease 0.3s;
      cursor: pointer;

      &:hover {
        transform: translateX(5px);
      }
    }

    &:hover {
      cursor: pointer;

      .e-asset-card-arrow {
        transform: translateX(5px);
      }
    }
  }
}
.e-rotate {
  animation: rotation 4s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
// remove it if noe needed
.e-amount {
  .e-value-rupee {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    font-family: "Roboto";
    position: relative;
    top: -2.5px;
    font-family: "Roboto";
  }
}

//Allocation detail
.e-allocation-detail {
  padding: 0 52px 130px;
  background-color: $background_bg !important;

  .e-tab-wrapper {
    overflow-x: visible;
    white-space: pre;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .e-active-node {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $primary_color;
    cursor: pointer;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      height: 2px;
      border-radius: 100px;
      width: 0;
      background-color: $primary_color;
      transition: ease-in-out width 0.5s;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }

  .e-current-node {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $input_text;
  }

  .e-tab {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: $input_text;
    background: #f0f0f0;
    border-radius: 4px;
    padding: 9px 20px;
    cursor: pointer;

    &:hover {
      transform: translateX(10%);
      transition: 0.4s ease-in-out;
      box-shadow: 0px 0px 5px rgb(0 0 0 / 28%);
    }
  }

  .e-active-tab {
    color: $white;
    background-color: $primary_color;
  }

  .e-total-amount {
    background: #edf3ff;
    border: 1px solid #f0f0f0;
    border-radius: 8px;

    h6 {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      font-feature-settings: "liga" off;
      color: $input_placeholder;
    }

    h5 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      font-feature-settings: "liga" off;
      color: $primary_color;
    }
  }

  .e-allocation-card {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 8px;

    .e-icon-wrap {
      border: 1px solid #f0f0f0;
      width: 56px;
      height: 56px;
      border-radius: 100px;
      text-align: center;
      color: #fff;
      padding-top: 14px;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;

      .e-icon {
        width: 46px;
        height: 46px;
        margin-top: 5px;
      }
    }

    h6 {
      font-weight: 600;
      font-size: 14px;
      line-height: 130%;
      color: $input_text;
    }

    label {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $input_placeholder;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 130%;
      color: $input_text;
    }

    .e-gain {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #0fd99c;
    }
  }
}

.e-assets-card {
  .e-amount {
    font-weight: 500 !important;
  }
}

// Pie chart tool tip
.e-custom-tool-tip {
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #f0f0f0;
  backdrop-filter: blur(7px);
  outline: none !important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #010101;
  padding: 8px 18px;
  display: inline-block;
  text-transform: capitalize;
  position: relative;

  &::after {
    // content: "";
    // position: absolute;
    // top: 100%;
    // left: 50%;
    // margin-left: -5px;
    // border-width: 5px;
    // border-style: solid;
    // border-color: #555 transparent transparent transparent;

    // 	content:  '';
    // 	// position: absolute;
    // 	width: 5px;
    // 	height: 5px;
    // 	top: 120%;                         // half way down (vertical center).
    // 	margin-top: 120%;                // adjust position, arrow has a height of 30px.
    // 	left:30px;
    // 	background: rgba(255, 255, 255, 0.7);
    // 	border-left: 15px solid transparent;
    // 	border-right: 15px solid transparent;
    // 	border-top: 15px solid red;
  }
}

//Searach select
.e-search-select-wrapper {
  .css-26l3qy-menu {
    box-shadow: none !important;
  }

  .css-tj5bde-Svg,
  .css-8mmkcg {
    background-image: url("../assets//images/lens.svg") !important;
  }

  .css-4ljt47-MenuList {
    border: 1px solid #f0f0f0 !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    height: 210px !important;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c0c0c0 !important;
      border-radius: 12px;
    }

    div {
      border-bottom: 1px solid #f0f0f0;
    }
  }
}

.css-qc6sy-singleValue {
  text-transform: capitalize;
}

.e-asset-modal-preview {
  .e-table-wrapper {
    width: 100%;
    height: 230px;
    overflow: auto;
    position: relative;
    border-radius: 8px;

    &::-webkit-scrollbar {
      // border-radius: 10px;
      height: 4px !important;
      background: #f0f0f0 !important;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c0c0c0 !important;
      border-radius: 12px;
    }

    h6 {
      color: $input_text;
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.e-csv-table {
  // margin-top: 20px;
  thead th {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $input_text;
    padding-bottom: 12px;
    padding: 16px;
    white-space: nowrap;
    color: $input_text;
  }

  tbody {
    tr {
      padding-bottom: 12px;

      td {
        font-weight: 400;
        font-size: 12px;
        line-height: 30px;
        color: $input_text;
        padding: 4px 16px;
      }
    }
  }

  tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.e-portfolio-disable-input {
  background: #dcdcdc !important;
  pointer-events: none;
}

.e-search-select-wrapper {
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 8px 20px 8px 8px !important;
  }
}

.e-upload-btn {
  padding: 21.5px 0px 21px 0px;
  width: 100%;
}

.css-26l3qy-menu {
  div {
    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.e-mf-search-panel {
  background-color: white;
  min-height: 250px;
  position: absolute;
  width: 88%;
  z-index: 10;
  padding: 20px;
  height: 300px;
  overflow-y: auto;
  border-radius: 12px;
  box-shadow: 2px 2px 48px rgb(0 0 0 / 8%);
  overflow-y: auto;
  // remove it
  // background-color: white;
  // min-height: 250px;
  // position: absolute;
  // width: 88%;
  // z-index: 10;
  // padding: 20px;
  // height: 300px;
  // overflow-y: auto;
  // border-radius: 12px;
  // box-shadow: 2px 2px 48px rgb(0 0 0 / 8%);
  // overflow-y: auto;

  &::-webkit-scrollbar {
    // border-radius: 10px;
    height: 0px !important;
    background: #f0f0f0 !important;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c0c0c0 !important;
    border-radius: 12px;
  }
}

.e-mf-add-btn {
  padding: 13px 20px;
  width: 100%;
}

.e-hover-popup {
  position: relative;

  &::after {
    content: "Coming soon";
    position: absolute;
    background-color: white;
    border-radius: 8px;
    width: 121px;
    height: 30px;
    color: black;
    top: -50px;
    left: -16%;
    text-align: center;
    padding-top: 5px;
    transform: scale(0);
    font-size: 14px;
    animation: zoom-in 0.5s 0s forwards;
    font-weight: 500;
    box-shadow: 0 0 3px rgb(33 33 33 / 20%);
  }
}

.e-portfolio-btn {
  padding: 16px 20px;
}
.e-link-icon {
  position: relative;
  top: -3px;
}

@keyframes zoom-in {
  70% {
    transform: scale(1.2);
  }

  90% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.e-capitalized {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}
.e-capatalize {
  text-transform: capitalize;
}

.e-s-modal-btn {
  padding: 18px 20px;
}

.e-guest-overlay {
  position: relative;
  height: 100vh;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(6px);
  }
}

.e-guest-help {
  position: absolute;
  width: 100%;
  height: max-content;
  bottom: 1%;
  text-align: center;
}

.e-guest-header-placeholder {
  display: none;
}

.e-new-badge {
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 9px;
  position: absolute;
  right: 5px;
  top: 11px;
  font-weight: 700 !important;
  background: linear-gradient(10deg, $primary_color 20.62%, #36096d 114.31%);
  // remove it
  // color: white;
  // padding: 2px 8px;
  // border-radius: 4px;
  // font-size: 9px;
  // right: 5px;
  // font-weight: 700 !important;
  // background: linear-gradient(10deg, $primary_color 20.62%, #36096D 114.31%);

  &::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -50px;
    animation: shine 2s infinite linear;
    /* Animation */
  }
}

@keyframes shine {
  0% {
    left: -50px;
  }

  20% {
    left: 100%;
  }

  100% {
    left: 100%;
  }
}

.e-mintd-security-btn {
  font-weight: 600 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  color: #010101;
  position: absolute;
  bottom: -2px;
  cursor: pointer;
  transition: ease-in-out 0.3s;

  &:hover {
    opacity: 0.7;
  }

  img {
    vertical-align: text-top;
    margin-right: 5px;
  }
}

// New design styles

.e-inner-small-screen-title {
  display: none !important;
  .e-menu-btn {
    background-color: #ffffff;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.06);
    float: right;
  }
}
.e-small-screen-menu {
  display: none;
  width: 400px;
  position: absolute;
  right: -1000px;
  z-index: 999;
  top: 0;
  transition: ease-in-out right 1s;
}

.e-menu-over-lay {
  &::after {
    content: "";
    position: fixed;
    background-color: #000000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
  }
}

.e-side-abr-close-btn {
  display: none;
}

.e-main-content {
  height: 100vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.e-btn-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #5a69ee !important;
}

.e-card {
  background: #ffffff;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}

.e-dashboard-greeting {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: $input_text;
}

.e-dashboard-sub-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(1, 1, 1, 0.6);
}

.e-tool-tip {
  position: absolute;
  background-color: #ffffff;
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  width: 200px;
  line-height: normal;
  box-shadow: 0px 4px 48px rgb(0 0 0 / 6%);
  border-radius: 8px;
  z-index: 10;
  right: -207px;
}

.e-dashboard {
  .e-dashboard-onboard-card-wrapper {
    width: calc(100% + 30px);
    padding: 0 15px;
    margin-left: -13px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .e-completed {
      padding: 12px 24px;
      margin-top: 18px;
      background: #e7f7ef;
      border-radius: 6px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #3cbc81;
    }
    .e-dashboard-card {
      background-color: $white;
      box-shadow: 0px 4px 16px rgb(0 0 0 / 5%);
      border-radius: 8px;
      min-width: 320px;
      margin-right: 25px;
      height: max-content;

      h6 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: $input_text;
        position: relative;
        img {
          position: absolute;
          top: 2px;
        }
      }

      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.5);
        margin-block-end: auto;
      }

      .e-step {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.1em;
        color: #a6a6a6;
      }

      .e-dashboard-onboard-card-btn {
        padding: 12px 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $white;

        &:hover {
          img {
            transform: translateX(4px);
          }
        }

        img {
          width: 13px;
          height: 13px;
          transition: ease 0.5s;
        }
      }
    }
  }

  .e-option {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.6);
  }

  .e-video-card {
    position: relative;
    width: 100%;
    height: 230px;
    overflow: hidden;
    border-radius: 8px;

    video {
      position: absolute;
      width: 100%;
      top: 0;
      height: 100% !important;
      left: 0;
      transition: ease-in-out 0.5s;
    }

    button {
      width: 36.28px;
      height: 36.28px;
      top: 68%;

      img {
        width: 12.96px;
        height: 14.58px;
      }

      &::after {
        display: none;
      }
    }

    p {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .e-section-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  .e-home-faq {
    display: contents;
    .e-home-faq-wrapper {
      width: 50%;
    }
    .accordion-item {
      // box-shadow: 0px 1px 16px rgb(0 0 0 / 10%);
      box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.06);
      border-radius: 8px !important;
      border: none !important;
      .accordion-button {
        background-color: $white;
        border: none;
        box-shadow: none;
        font-weight: 550;
        font-size: 18px;
        line-height: 23px;
        color: $input_text;
        padding: 24px 20px;
        border-radius: 8px;

        &::after {
          background-image: url("../assets/images/new-design/Combined-Shape.svg");
        }
      }
      .accordion-button:not(.collapsed) {
        padding-bottom: 0px !important;
      }

      .accordion-button:not(.collapsed)::after {
        transform: rotate(130deg);
      }

      .accordion-body {
        font-weight: 500;
        font-size: 16px;
        color: $input_text;
        padding-top: 8px;
      }
    }
  }

  .e-lose-arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-top: 15px solid #f00;
  }

  .e-dashboard-main-btn {
    padding: 23px 0px;
    padding: 21px 0px;
    font-size: 18px;
    font-weight: 500;
    transition: ease 0.5s;

    &:hover {
      img {
        transform: translateX(5px);
      }
    }

    img {
      transition: ease 0.5s;
    }
  }
  .e-dashboard-summary-card {
    background: #ffffff;
    box-shadow: 0px 3.90209px 46.825px rgba(0, 0, 0, 0.06);
    border-radius: 11.7063px;
    height: 100%;
    .e-card-title {
      font-weight: 500;
      font-size: 11.7063px;
      line-height: 14px;
      font-feature-settings: "liga" off;
      color: rgba(0, 0, 0, 0.75);
    }
    .e-portfolio-value {
      font-weight: 700;
      font-size: 35.1188px;
      line-height: 43px;
      font-feature-settings: "liga" off;
      color: $black;
      sup {
        font-weight: 400;
        font-size: 17.1608px;
        line-height: 21px;
        font-feature-settings: "liga" off;
        color: #000000;
      }
    }
    .e-dashboard-rupee {
      font-weight: 400;
      font-size: 17.1608px;
      line-height: 21px;
      color: #000000;
      font-family: "Roboto";
      position: relative;
      top: -12px;
    }
    .e-dashboard-rupee-sub {
      @extend .e-dashboard-rupee;
      top: -4px;
    }
    .e-gain-percent {
      font-weight: 500;
      font-size: 13.6573px;
      line-height: 17px;
      color: $black;

      img {
        vertical-align: sub;
      }
    }
    .e-card-label {
      font-weight: 500;
      font-size: 11.7063px;
      line-height: 14px;
      font-feature-settings: "liga" off;
      color: rgba(0, 0, 0, 0.75);
    }
    .e-amount {
      font-weight: 500;
      font-size: 23.4125px;
      line-height: 23px;
      font-feature-settings: "liga" off;
      color: $input_text;
    }
    .e-invest-more,
    .e-withdraw-more {
      padding: 12px 16px;
      width: 100%;
    }

    .e-pie-outer-wrapper {
      position: relative;
      height: 135px;
      width: 255.5px;
      left: 0;
      right: 0;
      margin: auto;
      .e-half-progress {
        position: relative;
        margin: auto;
        height: 120px;
        .e-progress-legend {
          font-weight: 500;
          font-size: 13.4905px;
          line-height: 16px;
          color: rgba(1, 1, 1, 0.5);
          position: absolute;
          left: 0;
          right: 0;
          top: 45%;
          margin: auto;
        }
        .e-progress-value {
          font-weight: 600;
          font-size: 35.9746px;
          line-height: 44px;
          color: #010101;
          position: absolute;
          left: 0;
          right: 0;
          top: 60%;
          margin: auto;
        }
        .barOverflow {
          /* Wraps the rotating .bar */
          position: relative;
          width: 100%;
          height: 100%;
          margin-bottom: -14px;
          overflow: hidden;
          margin: auto;
          left: 0;
          right: 0;
          top: 0;
        }
        .bar {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 255.19px;
          height: 255.19px;
          border-radius: 138.577px;
          box-sizing: border-box;
          border: 25px solid #f5f3f3 !important;
          border-bottom-color: #1329e6 !important;
          border-right-color: #1329e6 !important;
          transition: ease-in-out transform 0.5s;
          transform: rotate(42deg);
          &::after {
            content: "";
            position: absolute;
            background-color: #1329e6;
            top: 171px;
            left: 6.5px;
            width: 28.5px;
            height: 24.5px;
            border-radius: 50%;
            transform: rotate(45deg);
          }
        }
      }
    }

    .e-circle-outer {
      width: 255.19px;
      height: 255.19px;
      background: #f5f3f3;
      border-radius: 138.577px;
      position: relative;
      .e-inner {
        position: absolute;
        width: 255.19px;
        height: 255.19px;
        left: 0px;
        top: 0px;
        border-radius: 1124.21px;
      }
    }

    .e-chart-label {
      font-weight: 500;
      font-size: 13.4905px;
      line-height: 16px;
      color: rgba(1, 1, 1, 0.5);
      position: absolute;
      top: 58px;
      left: 0;
      right: 0;
    }
    .e-chart-value {
      font-weight: 600;
      font-size: 35.9746px;
      line-height: 44px;
      color: #010101;
      position: absolute;
      top: 79px;
      left: 0;
      right: 0;
    }
    .e-growth {
      font-weight: 600;
      font-size: 22.4841px;
      line-height: 27px;
      font-feature-settings: "liga" off;
      color: #3cbc81;
      margin-block-end: auto;
    }
    .e-edit-score-btn {
      font-weight: 400;
      font-size: 11.2421px;
      line-height: 14px;
      font-feature-settings: "liga" off;
      color: $input_text;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .e-score-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      font-feature-settings: "liga" off;
      color: #010101;
      width: max-content;
    }
    .e-sub {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      font-feature-settings: "liga" off;
      color: rgba(1, 1, 1, 0.9);
      margin-block-end: -5px;
    }
    .e-date {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      font-feature-settings: "liga" off;
      color: rgba(1, 1, 1, 0.9);
    }
    .e-sip-status {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      font-feature-settings: "liga" off;
      color: #3cbc81;
      margin-block-end: -5px;
    }
    .e-step-count {
      background-color: #0fd99c;
      width: 27px;
      height: 27px;
      border-radius: 100px;
      font-size: 15px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      padding-top: 3px;
      position: absolute;
      left: -13px;
    }
    .e-rebalance-btn {
      font-size: 12px;
      font-weight: 400;
      padding: 0;
    }
  }

  .e-half-pie-card {
    position: relative;
    .e-radius-left {
      position: absolute;
      width: 19px;
      height: 24.9px;
      background-color: #f5f3f3;
      border-radius: 0px 300px 300px 0px;
      transform: rotate(95deg);
      bottom: -5px;
      left: 2.5px;
      // transition: ease-in-out background-color 0.4s;
    }
    .e-radius-right {
      position: absolute;
      width: 19px;
      height: 24.9px;
      background-color: #f5f3f3;
      border-radius: 0px 300px 300px 0px;
      bottom: -5px;
      right: 3px;
      transform: rotate(85deg);
      transition-delay: 0.4s;
      // transition: ease-in background-color ;
    }
  }
  .e-chart-wrapper {
    background: #fff;
    box-shadow: 2px 2px 48px rgb(0 0 0 / 8%);
    border-radius: 8px;
    padding: 36px 10px 32px 32px;
    h6 {
      font-weight: 600;
      font-size: 18.5798px;
      line-height: 23px;
      color: $input_text;
    }
  }
  .e-notification-card {
    .e-mark-all {
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: $black;
      transition: ease 0.3s;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    h6 {
      font-weight: 600;
      font-size: 12.95px;
      line-height: 16px;
      color: $black;
    }
    p {
      font-weight: 400;
      font-size: 11px;
      line-height: 130%;
      color: $black;
    }
    .e-notification-secondary {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: $black;
      padding: 5.55px 11.1px;
    }
    .e-notification-primary {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      padding: 5.55px 11.1px;
    }
    .e-link {
      font-weight: 400;
      font-size: 10.7765px;
      line-height: 13px;
      color: #010101;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .e-icon {
      width: 33px;
      height: 33px;
      border-radius: 100px;
    }
  }
}

.e-notification-accordion {
  background-color: rgb(178 184 234 / 12%);
  border-radius: 4px;
  height: 0;
  transition: ease-in-out height 0.5s;
  .e-inprogress-dashed {
    position: relative;
    height: 228px;
    width: 0;
    border-right: 1px dashed #b7b7b7;
    margin-left: 20px;

    .e-progress-indicator {
      position: absolute;
      width: 2px;
      height: 100px;
      background-color: #0fd99c;
      transition: ease height 0.5s;
    }
    .e-step-count {
      background-color: #0fd99c;
      width: 28px;
      height: 28px;
      border-radius: 100px;
      font-size: 15px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      padding-top: 3px;
      position: absolute;
      left: -14px;
    }

    .e-step-progress {
      background-color: #e7e5e5;
    }
    .e-one {
      top: 0;
    }

    .e-two {
      top: 50%;
    }

    .e-three {
      top: 100%;
    }
  }
  .e-progress-label-wrapper {
    position: relative;
    height: 228px;
    .e-progress-label {
      position: absolute;
      p {
        font-size: 12px;
        color: $black;
        font-weight: 500;
        margin-block-end: auto;
      }
      .e-success-badge {
        color: #0fd99c;
        font-size: 9px;
        font-weight: 700;
        padding: 3px 7px;
        background-color: #96dbc65b;
        border-radius: 99px;
      }
      .e-inprogress-badge {
        @extend .e-success-badge;
        color: $black;
        background-color: #e7e5e5;
      }
      h5 {
        font-weight: 600;
        font-size: 8px;
        color: $black;
      }
    }
    .e-progress-label:nth-child(1) {
      top: 0;
    }

    .e-progress-label:nth-child(2) {
      top: 50%;
    }

    .e-progress-label:nth-child(3) {
      top: 100%;
    }
  }
}

.e-onboard-form {
  label {
    color: #010101;
    padding-top: 2px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
}

// Bread crumb
.e-breadcrumb-wrapper {
  .e-previous {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.5);
  }

  .e-current {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #4254eb;
  }
}

// For small screen
.e-onboard-progress-section-small-screen {
  display: none;
  .e-onboard-progress-wrapper {
    width: 100%;
    .e-inprogress-dashed {
      position: relative;
      height: 0;
      width: 100%;
      border-bottom: 1px dashed #b7b7b7;
      // margin-left: 20px;
      .e-progress-indicator {
        position: absolute;
        width: 2px;
        height: 2px;
        background-color: #0fd99c;
        transition: ease width 0.5s;
      }

      .e-img-wrapper {
        position: absolute;
        left: 0;
        width: 35px;
        padding-top: 6px;
        height: 44px;
        background-color: #ffffff;
        border-radius: 100px;
        top: -20px;

        img {
          width: 30px;
          transform: scale(0);
          animation: zoomUp 1s 0.5s forwards;
        }
      }

      .e-img-wrapper:nth-child(3) {
        left: 30%;
      }

      .e-img-wrapper:nth-child(4) {
        left: 60%;
      }

      .e-img-wrapper:nth-child(5) {
        left: 100%;
      }
    }
  }
}

.e-red-color {
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: $brick_red;
}

.e-refresh {
  .e-refresh-tooltip {
    background: rgba(255, 255, 255, 0.24);
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(6px);
    width: 180px;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    right: 5% !important;
    top: 18px;
    text-align: center;
    left: auto !important;
    .row {
      padding: 9px !important;
    }
  }
}

.e-refresh {
  .e-assets-tooltip {
    background: rgba(255, 255, 255, 0.24);
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(6px);
    width: 180px;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    left: 80% !important;
    top: 30px;
    .row {
      padding: 9px !important;
    }
    p {
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 150% !important;
      color: $brick_red !important;
      margin-bottom: 0px;
    }
  }
}

.e-check-handy {
  label {
    color: #2b3ee9 !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  .checkBox_checkmark__1pL5T {
    border: solid 1.6px #2b3ee9;
  }
}

.e-onboard-progress-section {
  border-right: 1px solid rgba(64, 64, 64, 0.12);
  height: 100vh;
  .e-onboard-progress-wrapper {
    .e-inprogress-dashed {
      position: relative;
      height: 228px;
      width: 0;
      border-right: 1px dashed #b7b7b7;
      margin-left: 20px;

      .e-progress-indicator {
        position: absolute;
        width: 2px;
        height: 100px;
        background-color: #0fd99c;
        transition: ease height 0.5s;
      }

      .e-img-wrapper {
        position: absolute;
        left: -15px;
        width: 35px;
        padding-top: 6px;
        height: 44px;
        background-color: #ffffff;
        border-radius: 100px;

        img {
          width: 30px;
          transform: scale(0);
          animation: zoomUp 1s 0.5s forwards;
        }
      }

      .e-img-wrapper:nth-child(3) {
        top: 30%;
      }

      .e-img-wrapper:nth-child(4) {
        top: 60%;
      }

      .e-img-wrapper:nth-child(5) {
        top: 90%;
      }
    }

    .e-progress-label-wrapper {
      position: relative;
      height: 228px;

      .e-progress-label {
        position: absolute;

        p {
          font-weight: 600;
          font-size: 8px;
          line-height: 10px;
          color: rgba(0, 0, 0, 0.75);
          margin-block-end: 0;
        }

        h5 {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #010101;
        }
      }

      .e-progress-label:nth-child(2) {
        top: 33%;
      }

      .e-progress-label:nth-child(3) {
        top: 63%;
      }

      .e-progress-label:nth-child(4) {
        top: 93%;
      }
    }
  }

  @keyframes zoomUp {
    70% {
      transform: scale(1.2);
    }

    90% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .e-progress-label-wrapper {
    position: relative;
    height: 228px;

    .e-progress-label {
      position: absolute;

      p {
        font-weight: 600;
        font-size: 8px;
        line-height: 10px;
        color: rgba(0, 0, 0, 0.75);
        margin-block-end: 0;
      }

      h5 {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #010101;
      }
    }

    .e-progress-label:nth-child(2) {
      top: 33%;
    }

    .e-progress-label:nth-child(3) {
      top: 63%;
    }

    .e-progress-label:nth-child(4) {
      top: 93%;
    }
  }
}

.e-onboard-step-section {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }
  .e-step-progress {
    height: 4px;
    border-radius: 0px 99px 99px 0px;
    width: 50%;
    background: #5a69ee;
  }
  .e-mintd-security-btn {
    color: #5a69ee;
    bottom: -20px;
  }
  .e-onboard-form-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $input_text;
  }
  .e-submit-btn {
    padding: 12px 24px;
    border-radius: 6px;
    &:hover {
      img {
        transform: translateX(5px);
      }
    }
    img {
      width: 18px;
      height: 18px;
      transition: ease-in-out 0.4s;
    }
  }
  .e-onboard-info-card {
    background: #e7f7ef;
    border-radius: 8px;
    h5 {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: $input_text;
    }
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: $input_text;
      margin-block-end: auto;
    }
  }
  .e-step-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #010101;
  }

  .e-desc {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: rgba(1, 1, 1, 0.6);
  }

  .e-step-indicator {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: #5a69ee;
  }

  // Quiz section
  .e-quiz-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .e-quiz {
    label {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $input_text;
    }

    .e-quiz-radio-wrapper {
      background: #f7f7f7;
      border-radius: 6px;
      width: 360px;
      min-height: 44px;
      padding: 12px 18px;
      position: relative;
      // display: flex;
      justify-content: space-between;
      border: 1.8px solid transparent;
      transition: ease 0.3s;
      cursor: pointer;

      &:hover {
        border: 1.8px solid #e6e6e6;
        background-color: #edf3ff;
      }

      .e-option {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #010101;
        padding-right: inherit;
      }

      input[type="radio"] {
        appearance: none;
        background-color: transparent;
        width: 20px;
        height: 20px;
        border: 1px solid rgba(0, 0, 0, 0.24);
        border-radius: 50%;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 100px;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          background-color: #5a69ee;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }
      }

      input[type="radio"]:checked::before {
        transform: scale(1);
      }
      input[type="radio"]:checked {
        border: 1px solid #5a69ee;
      }
    }
    .e-current-answer {
      border: 1.8px solid #5a69ee;
      background: rgba(208, 212, 250, 0.24);
    }
  }
  .e-questioner-submit {
    padding: 12px 24px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $white;
  }
  .e-radiometrics {
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $input_text;
      a {
        color: rgba(66, 84, 235, 1);
        text-decoration: none;
        font-weight: 600;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.e-video-instruction {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #010101;
}
.e-video-otp {
  background: rgba(195, 195, 196, 0.4);
  border-radius: 6px;
  width: 146px;
  height: 58px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #010101;
  padding: 18px 36px;
}

.e-video-btn {
  background: #e74a3b;
  border-radius: 6px;
  padding: 12px 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $white;
  border: none;
  transition: ease-in-out 0.4s;
  &:hover {
    background-color: #ac3025;
  }
}

.e-video-recorder-wrapper {
  overflow: hidden;
  border-radius: 12px;
  video {
    width: 100%;
    height: 60vh !important;
    transform: scale(1.2);
  }
  .e-preview-play {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    text-align: center;
    background: radial-gradient(50% 50% at 50% 50%, #1329e6 0, #e7eafd 90.1%);
    border: 1px solid transparent;
    // padding: 0 20px;
    z-index: 5;
    transition: 0.5s ease-in-out;
    &:hover {
      background: radial-gradient(79% 79% at 50% 50%, #e7eafd 0, #1329e6 90.1%);
    }
    img {
      width: 18px;
    }
  }
}
.e-video-upload {
  border: 1px solid #5a69ee;
  border-radius: 4px;
  background: #5a69ee;
  padding: 12px 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $white;
}
.e-record-again {
  background: #e74a3b;
  border-radius: 6px;
  padding: 12px 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid transparent;
  transition: ease-in-out 0.4s;
  &:hover {
    background-color: #ac3025;
  }
  img {
    vertical-align: sub;
  }
}
.e-preview-player {
  video {
    filter: blur(2.2px);
  }
}
.e-ac-open-agreement {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}
.e-sign-btn {
  padding: 12px 24px !important;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  &:hover {
    img {
      transform: translateX(5px);
    }
  }
  img {
    transition: ease-in-out 0.4s;
  }
}

.e-sign-btn span {
  color: $white;
}
.e-onboard-sub-info {
  background: #fff4d2;
  border-radius: 8px;
  h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $black;
    margin-block-end: auto;
  }
  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: $black;
    margin-block-end: 0;
  }
  .e-onboard-info-fields {
    background-color: $white;
    border-radius: 8px;
  }
  .e-arrow-img {
    position: absolute !important;
    width: 157px;
    top: 195px;
    left: 144px;
  }
}
.e-onboard-check-box {
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: $input_text;
}

.e-onboard-back-btn {
  border: 0;
  outline: 0;
  background: #edf3ff;
  border-radius: 6px;
  width: 108px;
  height: 44px;
  transition: ease 0.5s;

  &:hover {
    opacity: 0.7;
  }
}
.e-onboard-questions-section {
  label {
    // text-transform: capitalize;
  }
}

// portfolio
.e-portfolio-section {
  position: relative;
  // .e-inner-section {
  //   height: 100vh!important;
  //   overflow-y: auto;
  //   &::-webkit-scrollbar {
  //     display: none!important;
  //   }
  // }
  .e-fixed-btn-wrapper {
    position: fixed;
    width: 50%;
    bottom: 0;
    height: 85px;
    background: rgba(255, 255, 255, 0.48);
    -webkit-backdrop-filter: blur(2.03328px);
    backdrop-filter: blur(2.03328px);
    z-index: 99;
    .e-fixed-btn {
      width: 83%;
      position: relative;
      left: 40px;
      &:hover {
        img {
          transform: translateX(5px);
        }
      }
      img {
        transition: ease-in-out 0.4s;
      }
    }
  }

  .e-portfolio-video-wrapper {
    // width: 600px;
    height: 368px;
    overflow: hidden;
    border-radius: 12.8px;

    video {
      position: absolute;
      width: 100%;
      top: 0;
      height: 100% !important;
      left: 0;
      transition: ease-in-out 0.5s;
      transform: scale(1.05);
    }

    button {
      top: 70%;

      &::after {
        display: none;
      }
    }

    p {
      top: 88%;
    }
  }
  .e-methodology-link {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5a69ee;
  }

  .e-portfolio-riskscore-preview {
    background-color: $primary_color;
    border: 4.8px solid #d2d2d2;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.06);
    border-radius: 16px;

    .e-card-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      font-feature-settings: "liga" off;
      color: #ffffff;
    }

    .e-card-link-btn {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      font-feature-settings: "liga" off;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        img {
          transform: translateX(5px);
        }
      }
      img {
        transition: ease-in 0.3s;
      }
    }

    .e-label {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      font-feature-settings: "liga" off;
      color: rgba(255, 255, 255, 0.6);
    }

    .e-amount {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.08em;
      font-feature-settings: "liga" off;
      color: #ffffff;
    }

    .e-card-badge {
      background: rgba(255, 255, 255, 0.28);
      border-radius: 999px;
      padding: 8px 18px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #fffbee;
    }

    .e-score {
      font-weight: 600;
      font-size: 36px;
      line-height: 26px;
      color: #ffffff;
    }

    .e-invest-btn {
      background-color: $white;
      border-radius: 6px;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      border: none;
      outline: none;
      width: 195px;
      padding: 12px 18px;
      display: flex;
      justify-content: center;
      transition: ease-in-out 0.3s;
      &:hover {
        background-color: #f0f0f0;
        img {
          transform: translateX(5px);
        }
      }
      img {
        transition: ease-in 0.3s;
      }
    }
  }
  .e-subsequent-score-card {
    background: #ffffff;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    border: 4.8px solid transparent;
    .e-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      font-feature-settings: "liga" off;
      color: $input_text;
    }
    .e-score {
      font-weight: 600;
      font-size: 36px;
      line-height: 26px;
      color: $input_text;
    }
    .e-card-link-btn {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      font-feature-settings: "liga" off;
      color: #5a69ee;
      cursor: pointer;
      &:hover {
        img {
          transform: translateX(5px);
        }
      }
      img {
        transition: ease-in 0.3s;
      }
    }
    .e-label {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      font-feature-settings: "liga" off;
      color: rgba(0, 0, 0, 0.6);
      margin-block-end: auto;
    }
    .e-amount {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.08em;
      font-feature-settings: "liga" off;
      color: $black;
    }
    .e-continue-btn {
      background: rgba(19, 41, 230, 0.06);
      border-radius: 6px;
      padding: 12px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: $black;
      outline: none;
      border: none;
      width: 165px;
      transition: ease-in 0.3s;
      &:hover {
        img {
          transform: translateX(5px);
        }
      }
      img {
        transition: ease-in 0.3s;
      }
    }
  }
  .e-chart-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $input_text;
  }
  .e-chart-card {
    background-color: $white;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    height: 485px;
    .apexcharts-canvas {
      left: -66px;
    }
    .e-chart-label {
      .e-chart-label-text {
        font-weight: 500;
        font-size: 10.4895px;
        line-height: 25px;
        color: #6d6d6d;
      }
      span {
        font-weight: 600;
        font-size: 10.4895px;
        line-height: 25px;
        color: #6d6d6d;
      }
    }
    .e-desc {
      font-weight: 500;
      font-size: 12px;
      line-height: 25px;
      font-feature-settings: "liga" off;
      color: rgba(0, 0, 0, 0.6);
      span {
        font-weight: 700;
        font-size: 14px;
        color: $black;
      }
    }
    .e-returns {
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      color: $input_text;
      span {
        font-size: 24px;
        color: $primary_color;
      }
    }
    .e-bar-wrapper {
      height: 229px;
      // background-color: red;
      width: 100%;
      $classes: "mintd-bar" $primary_color 42%, "deposit-bar" #af93f6, "gold-bar" #af93f6 18%;

      @each $class, $color, $left in $classes {
        .e-#{$class} {
          width: 44.6px;
          background-color: $color;
          position: absolute;
          bottom: 0;
          left: $left;
          transition: height ease-in-out 0.5s;
          border-radius: 8px 8px 0px 0px;
        }

        h6 {
          position: absolute;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #010101;
          left: 20%;
          transition: bottom ease-in-out 0.5s;
        }

        .e-graph-legend {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
        }
      }
    }

    .e-graph-bottom {
      border-bottom: 1px solid #000000;
      height: 230px;
    }

    .e-switch {
      background: #f0f0f0;
      border-radius: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #010101;
      width: 42px;
      height: 33px;
      text-align: center;
      line-height: 33px;
      cursor: pointer;
      transition: ease-in-out 0.3s;

      &:hover {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
      }
    }

    .e-active-switch {
      background: $primary_color;
      color: #fff;
      cursor: pointer;
    }
  }

  .e-portfolio-faq {
    display: contents;

    .accordion-item {
      box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.06);
      border-radius: 8px !important;
      border: 0;
      .accordion-button {
        background-color: $white;
        border: none;
        box-shadow: none;
        font-weight: 550;
        font-size: 14px;
        line-height: 23px;
        color: $input_text;
        padding: 15px 20px;
        border-radius: 8px;

        &::after {
          background-image: url("../assets/images/new-design/Combined-Shape.svg");
        }
      }

      .accordion-button:not(.collapsed)::after {
        transform: rotate(130deg);
      }

      .accordion-body {
        font-weight: 500;
        font-size: 14px;
        color: $input_text;
      }
    }
  }
}
.e-break {
  word-break: break-all;
}
.e-span-wrap {
  cursor: pointer;

  .e-profile-active-btn {
    background: $primary_color;
    border-radius: 4px;
    color: $white;
    padding: 9px 10px;
    width: 100%;
    margin-right: 10px;
    font-weight: 500;
    line-height: 18px;
    font-size: 10px;
  }

  .e-profile-btn {
    background: $input_boder;
    border-radius: 4px;
    color: $input_text;
    padding: 9px 10px;
    width: 100%;
    margin-right: 10px;
    font-weight: 500;
    line-height: 18px;
    font-size: 10px;

    &:hover {
      transform: translateX(10%);
      transition: 0.4s ease-in-out;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);
    }
  }
}

//Assets
.e-asset-container {
  padding-bottom: 300px;
  .e-asset-graph-card {
    h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
      margin-top: 6px;
      position: relative;
      img {
        position: absolute;
        top: 3px;
      }
    }
    .e-vertical-rule {
      position: absolute;
      width: 2px;
      height: 132px;
      background: rgba(217, 217, 217, 0.25);
      border-radius: 999px;
      top: 0;
      bottom: 0;
      left: -20px;
      right: 0;
      margin: auto;
      padding: 0%;
    }
  }
  .e-asset-graph-chart {
    position: relative;
    .apexcharts-canvas {
      position: absolute;
      left: -56px;
      top: -6px;
    }
  }
  .e-assets-chart-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 32px !important;
    color: $black;
  }

  .e-asset-table-title {
    background: #ebebeb;
    border-radius: 8px;
    h6 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $input_text;
      margin-block-end: auto;
    }
  }
  .e-table-subtitle {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    h6 {
      margin-block-end: auto;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: $input_text;
    }
  }
  .e-table-body {
    background: #fafafa;
    border-radius: 8px;
    position: relative;
    background-image: url("../assets/images/new-design/arrow-dull.svg");
    background-repeat: no-repeat;
    background-position: 97% 50%;
    transition: ease-in-out 0.4s;
    cursor: pointer;
    &:hover {
      background-color: $white;
      box-shadow: 0px 3.50769px 42.0923px rgba(0, 0, 0, 0.12);
      transform: scale(1.08);
      background-image: url("../assets//images/new-design/arrow-active.svg");
    }
    p,
    span {
      margin-block-end: auto;
      font-weight: 600;
      font-size: 16.5363px;
      line-height: 20px;
      color: $black;
    }
  }
  .e-allocation-detail {
    position: absolute;
    top: 150%;
    right: -320px;
    background-color: $white !important;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    width: 291px;
    height: 287px;
    opacity: 0;
    z-index: -1;
    .e-fund-name {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $input_text;
    }
    .e-display-name {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: rgba(1, 1, 1, 0.75);
    }
    label {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: rgba(1, 1, 1, 0.9);
    }
    .e-card-value {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: rgba(1, 1, 1, 0.9);
      .e-rupee-symbol {
        position: relative;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        top: -4px;
      }
    }
    a {
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #010101;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .e-asset-popup-open {
    opacity: 0;
    top: 150%;
    z-index: 2;
    animation: fadeUp 1s forwards;
  }
  @keyframes fadeUp {
    0% {
      opacity: 0;
      top: 150%;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }
  .e-asset-portfolio-card {
    .e-card-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: $black;
    }
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.6);
    }
    .e-current-value {
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      letter-spacing: 0.08em;
      color: $primary_color;
      span {
        vertical-align: text-top;
        font-size: 18px;
      }
      .e-dashboard-rupee {
        font-family: "Roboto";
      }
    }
    .e-value {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.08em;
      color: $black;
      span {
        vertical-align: text-top;
        font-size: 15px;
      }
      .e-dashboard-rupee-sub {
        font-family: "Roboto";
      }
    }
    button {
      padding: 12px 16px;
      width: 100%;
    }
  }
}

//Portfolio tracker
.e-portfolio-container {
  .e-card-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $black;
  }
  .e-portfolio-stat-card {
    height: 331px;
    .e-card-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: $black;
    }
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.6);
    }
    .e-amount {
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      letter-spacing: 0.08em;
      color: $primary_color;
    }
    .e-assets {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.08em;
      color: $black;
      .e-gain-percent img {
        vertical-align: unset;
        width: 15px;
      }
    }
    .e-add-more {
      padding: 12px 10px 9px 10px;
      font-weight: 500;
      font-size: 14px;
    }
    .e-edit-btn {
      padding: 12px 10px 9px 10px;
    }
    .e-empty-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #ff6f07;
      display: block;
    }
  }
  .e-no-data {
    width: 40px;
    background: black;
    height: 1.7px;
    opacity: 1;
  }
  .e-graph-card {
    height: 330px;
    .e-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: $black;
    }
    .apexcharts-canvas {
      left: 5px !important;
      margin: inherit;
      top: 70px;
    }
    .e-graph-indicator {
      width: 18px;
      height: 18px;
      border-radius: 100px;
      display: inline-block;
      vertical-align: middle;
    }
    .e-legend-value {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $black;
      text-transform: capitalize;
    }
    .e-graph-legends {
      margin-bottom: 15px;
    }
  }

  .e-asset-card-wrapper {
    width: 215px;
  }
  .e-asset-card {
    position: relative;
    cursor: pointer;
    transition: ease 0.5s;
    // width: 215px;
    &:hover {
      box-shadow: 0px 3.50769px 42.0923px rgba(0, 0, 0, 0.12);
      transform: scale(1.02);
    }
    .e-refresh-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: rgba(1, 1, 1, 0.6);
    }
    h6 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.08em;
      color: $input_text;
    }
    .e-asset-card-arrow {
      width: 14px;
      height: 11.67px;
    }
    .e-coming-soon {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #999999;
      position: absolute;
      top: 10px;
      right: 10px;
      text-transform: uppercase;
    }
  }
}

.e-portfolio-accordion {
  .accordion-item {
    background: #f5f5f5;
    border-radius: 6px;
    border: none;
    .e-accordion-btn {
      border: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #0e1012;
      background-color: transparent;
      width: 100%;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        border: solid #010101;
        right: 20px;
        top: 7px !important;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
      }
    }
    .e-accordion-btn[aria-expanded="false"] {
      &::after {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        top: 12px;
      }
    }

    .e-accordion-btn[aria-expanded="true"] {
      &::after {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        top: 14px;
      }
    }
  }
}

//Orders
.e-order-page {
  .e-filter-btn-mobile {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #5a69ee;
  }
}
.e-overlay-order {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 104%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.1);
    top: 0;
    left: -24px;
  }
}

// SIP
.e-sip-card {
  h6 {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #656565;
    margin-block-end: 2px;
  }
  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: rgba(1, 1, 1, 0.9);
  }
  .e-sip-submit {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding: 12px 16px;
  }
  .e-sip-cancel-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding: 13px;
    border-radius: 8px;
    transition: ease-in-out 0.5s;
    background: transparent;
    &:hover {
      background: rgba(19, 41, 230, 0.12);
    }
  }
  .e-value {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(1, 1, 1, 0.9);
  }
}
.e-sip-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: $black;
}

// Notification
.e-notification-card {
  .e-unread-dot {
    width: 4.86px;
    height: 4.75px;
    border-radius: 100px;
    background: #3cbc81;
    right: -8px;
  }
  h5 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $black;
    margin-block-end: 2px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
  }
  .e-notification-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $black;
    border: 1.8px solid #5a69ee;
    border-radius: 4px;
    padding: 7px 16px;
  }
  .e-primary {
    background: #5a69ee;
    border-radius: 4px;
    padding: 8px 16px;
    border: none;
  }
}

// Reports
.e-report-card {
  h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $black;
  }
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.5);
  }
  button {
    // background: #5a69ee;
    border-radius: 8px;
    padding: 12px 16px;
    border: none;
  }
}

// Profile
.e-profile-container {
  height: 100vh;
  overflow-y: hidden;
  .e-profile-tab {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: rgba(1, 1, 1, 0.6);
    padding: 20px 0;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
  }
  .e-active-profile-tab {
    @extend .e-profile-tab;
    color: $primary_color;
  }

  // Personal detail tab
  .e-profile-detail {
    padding-bottom: 50px;
    .e-image-preview {
      width: 60px;
      height: 60px;
      border-radius: 100px;
      border: 3px solid #5a69ee;
      position: relative;
      .e-profile-edit-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;
      }
      .e-placeholder {
        background: rgba(90, 105, 238, 0.2);
        padding-top: 13px;
        color: $white;
        font-weight: 600;
        font-size: 20px;
        width: 100%;
        height: 100%;
        border-radius: 100px;
      }
      .e-img {
        width: 100%;
        height: 100%;
        border-radius: 100px;
        object-fit: cover;
      }
    }
    .e-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: $black;
    }
    .e-data-container {
      padding: 18px 90px 18px 18px;
      border: 1.8px solid #e6e6e6;
      border-radius: 8px;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }
    label {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: $input_text;
      margin-bottom: 5px;
    }
    .e-credential-wrapper {
      @extend .e-data-container;
      padding: 18px;
      span {
        font-weight: 500;
      }
    }

    .e-letter-style {
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
    }

    .e-verified {
      @extend .e-credential-wrapper;
      border: 1.8px solid #3cbc81;
      background-image: url("../assets/images/new-design/check-circle.svg");
      background-repeat: no-repeat;
      background-position: 97% 50%;
    }
  }
  //Nominee detail
  .e-nominee-edit {
    border: 1.8px solid #5a69ee;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  .e-nominee-delete {
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
  .e-add-more {
    color: #4254eb;
  }
}

.e-rupee-symbol {
  font-family: "Roboto" !important;
}

.e-google-login-btn {
  position: absolute;
  top: 25px;
  left: 2px;
  // width: 258px;
  z-index: 2;
  right: 0;
  margin: auto;
  opacity: 0;
}
// background: white;
// padding: 8px;
// border: 1.8px solid blue;
// border-radius: 6px;
// }
// height: 62px;
// background: white;
// padding: 8px;
// border: 1.8px solid blue;
// border-radius: 6px;
.e-support-sec {
  .e-support-btn,
  .e-whatsapp,
  .e-calendar,
  .e-marker {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 100;
  }
  .e-whatsapp,
  .e-calendar,
  .e-marker {
    display: none;
  }

  // .e-whatsapp {
  //   position: fixed;
  //   bottom: 35px;
  //   right: 110px;
  //   cursor: pointer;
  //   transition: all 0.3s ease-in-out;
  // }
  // .e-calendar {
  //   position: fixed;
  //   bottom: 92px;
  //   right: 60px;
  //   cursor: pointer;
  //   transition: all 0.3s ease-in-out;
  // }

  .e-animate-calendar {
    @extend .e-calendar;
    display: block;
    animation: e-widget-upp 0.5s forwards;
  }
  @keyframes e-widget-upp {
    0% {
      bottom: 20px;
      right: 40px;
    }
    100% {
      bottom: 115px;
      right: 40px;
    }
  }

  .e-animate-marker {
    @extend .e-marker;
    display: block;
    animation: e-widget-up 0.5s forwards;
  }
  @keyframes e-widget-up {
    0% {
      bottom: 26px;
      right: 40px;
    }
    100% {
      bottom: 62px;
      right: 40px;
    }
  }

  .e-animate-whatsapp {
    @extend .e-calendar;
    display: block;
    animation: e-widget-down 0.5s forwards;
  }
  @keyframes e-widget-down {
    0% {
      bottom: 20px;
      right: 40px;
    }
    100% {
      bottom: 35px;
      right: 110px;
    }
  }

  @keyframes e-widget-down {
    0% {
      bottom: 20px;
      right: 40px;
    }
    100% {
      bottom: 168px;
      right: 40px;
    }
  }
  .e-remove-calendar {
    animation: close-calender 0.5s forwards;
  }

  @keyframes close-calender {
    0% {
      bottom: 115px;
      right: 40px;
    }
    100% {
      bottom: 20px;
      right: 40px;
    }
  }

  .e-remove-whatsapp {
    animation: close-whatsapp 0.5s forwards;
  }

  @keyframes close-whatsapp {
    0% {
      bottom: 168px;
      right: 40px;
    }
    100% {
      bottom: 20px;
      right: 40px;
    }
  }
  .e-remove-marker {
    animation: close-marker 0.5s forwards;
  }

  @keyframes close-marker {
    0% {
      bottom: 62px;
      right: 40px;
    }
    100% {
      bottom: 20px;
      right: 40px;
    }
  }

  @-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-20px);
    }
  }
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-20px);
    }
  }

  .bounce {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-name: bounce;
    animation-name: bounce;
  }
}

.e-asset-detail-wrapper {
  .e-asset-details {
    padding-top: 15px;
    a {
      text-decoration: none;
      color: $black;
      &:hover {
        text-decoration: underline;
      }
    }
    .e-back {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: $black;
      text-decoration: none !important;
      &:hover {
        img {
          transform: scale(1.02);
        }
      }
      img {
        transition: ease 0.4s;
      }
    }
    .e-fund-name {
      font-weight: 600 !important;
      font-size: 24px;
      line-height: 150%;
      color: $black;
      margin-block-end: auto;
    }
    .e-amc-name {
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .e-fund-wrapper {
    h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: $black;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #646464;
    }
    button {
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #5a69ee !important;
    }
  }
  .e-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(1, 1, 1, 0.5);
  }
  .e-value {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgba(1, 1, 1, 0.9);
  }

  .e-chart-wrapper {
    width: 100%;
    height: 64px;
    border-radius: 8px;
    overflow: hidden;
    white-space: pre;
  }
  .e-indicator {
    width: 19.5px;
    height: 18px;
    border-radius: 100px;
    margin-right: 8px;
  }
  .e-legend-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $black;
    width: 100%;
    .e-name {
      width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
    .e-percentage {
      vertical-align: super;
      display: inline;
    }
  }

  .e-asset-detail-select {
    .css-1s2u09g-control {
      width: 80px;
      height: 34px;
      padding: 0px 0px 0px 0px !important;
      border-radius: 4px;
      border: 1.60549px solid #7a7a7a !important;
      min-height: 30px !important;
      background-color: transparent;
    }
    .css-1pahdxg-control {
      width: 80px;
      height: 34px;
      padding: 0px 0px 0px 0px !important;
      border-radius: 4px !important;
      border: 1.60549px solid $primary_color !important;
      min-height: 30px !important;
      background-color: transparent;
    }
    .css-qc6sy-singleValue {
      font-size: 12px !important;
    }
    .css-26l3qy-menu {
      width: 80px;
      border: 1.60549px solid $primary_color !important;
    }
    .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer {
      padding: 4px !important;
    }
    .css-319lph-ValueContainer {
      padding-top: 0 !important;
    }
    .css-tj5bde-Svg,
    .css-8mmkcg {
      background-image: url("../assets/images/new-design/asset-select-arrow.svg");
      background-repeat: no-repeat;
      background-position-x: 0px;
      background-position-y: 5px;
    }
  }

  .e-asset-select {
    .css-1s2u09g-control {
      width: 80px;
      // height: 34px;
      padding: 0px 0px 0px 0px !important;
      // background: rgba(255, 255, 255, 0.25);
      border: 1.60549px solid #7a7a7a !important;
      border-radius: 4.93996px;
      min-height: 30px !important;
      background-color: transparent;
    }
    .css-1pahdxg-control {
      width: 80px;
      // height: 34px;
      padding: 0px 0px 0px 0px !important;
      // background: rgba(255, 255, 255, 0.25);
      border: 1.60549px solid #7a7a7a !important;
      border-radius: 4.93996px !important;
      min-height: 30px !important;
      background-color: transparent;
    }
    .css-qc6sy-singleValue {
      font-size: 12px !important;
    }
    .css-26l3qy-menu {
      width: 80px;
      border: 1.60549px solid $primary_color !important;
    }
    .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer {
      padding: 4px !important;
    }
    .css-319lph-ValueContainer {
      padding-top: 0 !important;
    }
    .css-tj5bde-Svg,
    .css-8mmkcg {
      background-image: url("../assets/images/new-design/asset-select-arrow.svg");
      background-repeat: no-repeat;
      background-position-x: 0px;
      background-position-y: 5px;
    }
  }
}
.e-active,
.e-inactive {
  cursor: pointer;
}

.e-delete-btn {
  background: #e74a3b;
  /* semantic colors/red */

  border: 1.8px solid #e74a3b;
  border-radius: 8px;
  &:hover {
    background: #e74a3b;
  }
}
.e-delete {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #e74a3b;
}
.e-news-card-wrapper {
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  height: 150px;
  position: relative;
  margin-bottom: 18px;
  padding: 18px !important;
  transition: ease 0.5s;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 3.50769px 42.0923px rgb(0 0 0 / 12%);
    transform: scale(1.02);
  }
  .e-date {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    img {
      margin-top: -2px;
    }
  }
  h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding-top: 8px;
    color: rgba(1, 1, 1, 0.6);
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .e-bottom {
    position: absolute;
    bottom: 20px;
  }
}
.e-inactive {
  &:hover {
    transform: scale(1.05);
  }
}
.e-portfolio-detail {
  .e-active-node {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.5);
  }
  .e-current-node {
    @extend .e-active-node;
    color: #4254eb;
  }
  .e-tab {
    background: #f2f2f2;
    border-radius: 8px;
    padding: 12px 18px;
    height: 41px;
    width: max-content;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $input_text;
    transition: ease-in-out 0.3s;
    cursor: pointer;
    &:hover {
      background: #e1e1e1;
    }
  }
  .e-active-tab {
    background: #5a69ee;
    color: $white;
    &:hover {
      background-color: #1d2dbc;
    }
  }
  .e-gainer {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #3cbc81;
  }
  .e-indicator {
    width: 14px;
    height: 11.67px;
    vertical-align: unset;
  }
  .e-detail-card-wrapper {
    transition: ease-in-out height 0.5s;
  }
  .e-allocation-detail-card {
    padding: 30px !important;
    background-color: $white;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    .e-table-wrapper {
      background: rgba(235, 235, 235, 0.5);
      backdrop-filter: blur(4px);
      padding: 16px 8px;
      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #010101;
      }

      border-radius: 8px;
    }
    .e-table-row {
      background: #fafafa;
      border-radius: 8px;
      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.6);
    }
    .e-current-value {
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      letter-spacing: 0.08em;
      color: #1329e6;
      position: relative;
      margin-block-end: auto;
      .e-rupee {
        font-family: "Roboto";
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        position: relative;
        top: -5px;
      }
    }
    .e-last-updated {
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #8c0a01;
      margin-block-end: 5px;
    }

    .e-card-value {
      font-weight: 500 !important;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.08em;
      color: $input_text;
      .e-value-rupee {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        font-family: "Roboto";
        position: relative;
        top: -2.5px;
      }
    }
  }

  .e-subtitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $black;
    margin-block-end: auto;
  }
  .e-allocation-card {
    background: #ffffff;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    .e-icon-wrap {
      background-color: #dee1fc;
      border: 1px solid #f0f0f0;
      width: 56px;
      height: 56px;
      border-radius: 100px;
      text-align: center;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
    }
    h6 {
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      color: $black;
    }
    .e-price {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: rgba(1, 1, 1, 0.6);
    }
    .e-price-amount {
      font-weight: 500;
    }
    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: rgba(1, 1, 1, 0.6);
    }
    .e-type-badge {
      background: rgba(19, 41, 230, 0.12);
      color: $primary_color;
      border-radius: 6px;
      padding: 3px 5px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }
    .e-deposit-edit {
      position: absolute;
      top: 0;
      right: 10px;
      cursor: pointer;
    }
    .e-more-menu {
      background: #f2f2f2;
      width: 70px;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      right: -58px;
      z-index: 10;
      .e-menu-item {
        padding: 4px 5px;
        text-align: center;
        transition: ease 0.3s;
        cursor: pointer;
        &:hover {
          background: #e1e1e1;
        }
      }
    }
  }
}
.react-loading-skeleton {
  border-radius: 34px;
}
.item-img .react-loading-skeleton {
  border-radius: 4px !important;
}
.e-dashboard-coming-soon {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  color: rgba(1, 1, 1, 0.6);
  line-height: 150%;
  background-color: #e0e3ff;
  padding: 14px;
  border-radius: 8px;
}
