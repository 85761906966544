/*
 *   File : pie.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Pie chart
 *   Integrations :
 *   Version : v1.0
*   Created : 08-12-2021
 */

@import "./../config.scss";

.apexcharts-canvas {
  left: -26px;
}
.apexcharts-legend-text {
  line-height: 24px;
  color: $black!important;
 
}
.apexcharts-legend-marker {
  width: 8px!important;
  height: 8px!important;
}