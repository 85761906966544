/*
 *   File : headerOuterHalf.module.scss.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Header
 *   Integrations : null
 *   Version : 1.0
 *   Created : 09-12-2021
 */

@import "./../config.scss";

.e_headerOuterHalf {
  padding: 32px 40px 32px 54px;
  background-color: $white;
  .e_logo {
    width: 80px;
    height: 32px;
    &:hover {
      cursor: pointer;
    }
  }
  .e_account {
    background-image: url(../../assets/images/menu/arrow-down.svg);
    background-repeat: no-repeat;
    height: 6px;
    width: 8px;
    padding: 0 20px;
    background-position: 100% center;
    font-weight: 500;
    @extend %font14;
    line-height: 17px;
    color: $input_text;
    cursor: pointer;
  }
  .dropdown {
    position: relative;
  }
  .e_logout_div {
    position: absolute;
    z-index: 3;
    right: 0;
    top: 4px;
    width: 205px;
    height: 56px;
    background: $white;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    padding: 15px;
    .e_menu_item {
      color: $logout;
      font-weight: 500;
      @extend %font14;
      cursor: pointer;
      padding: 15px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
