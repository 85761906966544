@import "./../config.scss";

.e_custome_date_picker {
    .e_date_input {
        box-sizing: border-box;
        border-radius: 8px;
        @extend %font18;
        border: 1.8px solid #E6E6E6;
        background-color: transparent;
        outline: none;
        width: 100%;
        font-weight: 600;
        color: $input_text;
        padding: 12px 37px 13px 20px;
    }
    .e_disable_picker {
        pointer-events: none !important;
        background-color: #e9e9ea;
    }
    .e_icon {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 10%;
    }
    label {
        position: absolute;
        left: 20px;
        top: 10px;
        color: #6d6d79;
        font-weight: 600;
        font-size: 12px;
    }
}
.e_date_panal_dropdown {
    position: absolute;
    width: 275px;
    overflow: hidden;
    border-radius: 8px;
    // box-shadow: 0 0 0px 0px rgb(0,0,0 / 10%), 0 1px 3px 0 rgb(0,0,0 / 10%), 0 4px 15px 0 rgb(0,0,0 / 15%);
    border: 1px solid #ccc;
    z-index: 9999;
    background-color: #fff;
    span {
        padding-top: 13px;
        // padding-left: 12px;
        font-weight: 400 !important;
        /* border: 1px solid #ccc; */
        display: inline-flex;
        // width: 38px;
        // height: 38px;
        justify-content: center;
        cursor: pointer;
        line-height: 12px;
        width: 35px;
        font-size: 14px;
        height: 38px;
        margin: 2px;
        // vertical-align: middle;
    }
    .e_disabled_date {
        pointer-events: none;
        background-color: #eeeeee;
        border-radius: 4px;
    }
    .e_selected_date {
        background-color: $primary_color;
        color: #fff;
        border-radius: 4px;
        text-align: center;
        vertical-align: middle;
    }
    .e_date_group {
        padding-left: 16px;
        padding-bottom: 20px;
    }
    .e_close_btn {
        position: absolute;
        top: -14px;
        right: 0;
        width: 15px;
        float: right;
        margin: 17px;
    }
    h6 {
        font-size: 14px;
        color: #000000;
        font-weight: 500;
        opacity: 0.6;
    }
    h5 {
        font-weight: 400;
        font-size: 14px !important;
    }
    .e_date_picker_header {
        position: relative;
    }
    button {
        background-color: $primary_color;
        color: #fff;
        border: none;
        outline: none;
        padding: 10px;
        height: 42px;
        width: 92%;
        margin-top: 20px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 700;
    }
}
