/*
 *   File : switch.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Switch style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 07-12-2021
 */

@import "./../config.scss";

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: -1px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $primary_color;
}

input:focus + .slider {
  // box-shadow: 0 0 1px  $primary_color;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.slider::after {
  position: relative;
  right: -45px;

  // content: "off"
}

.on {
  color: $primary_color;
}
