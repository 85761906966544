@import "./../config.scss";

.e_notification_menu {
    background-color: $white;
    border: none !important;
    border-radius: 8p !important;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
    height: 300px;
    width: 320px;
    position: absolute;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        // border-radius: 10px;
        height: 0px !important;
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background: $primary_color;
        // border-radius: 10px;
    }
    .e_row {
        cursor: pointer;
        //    background-color: $primary_color;
        border-bottom: 1px solid #ccc;
        transition: ease-in-out 0.5s;
        &:hover {
            background-color: #edf3ff;
        }
    }
    .e_title {
        font-size: 14px;
        font-weight: 800;
    }
    .e_date {
        font-size: 12px !important;
        font-weight: 400;
        margin: 5px 0 10px 0;
    }
    p {
        height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
