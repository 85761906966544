
@import '../config.scss';

.e_modal {
    .e_modal_dialog {
        max-width: 540px!important;
        .e_content {
            position: relative;
            border-radius: 12px!important;
            .e_close {
                position: absolute;
                width: 30px;
                height: 30px;
                top: 20px;
                right: 5%;
                cursor: pointer;
            }
            p{
                font-weight: 500;
                font-size: 14px;
                line-height: 150%;
                color: $black;
                    b {
                        font-weight: 600!important;
                    }
                    a {
                        color: $primary_color;
                        text-decoration: none;
                            &:hover{
                                 text-decoration: underline;
                            }
                    }
            }
        }
    }
}