@import "../config.scss";

.e_asset_modal {
    .e_content {
        padding: 0 !important;
        max-width: 540px;
        transition: ease-in-out max-width 0.4s;
        // min-height: 435px;
        .e_modal_header {
            background: #f6f6f6;
            position: relative;
            border-radius: 12px 12px 0px 0px;
            height: 80px;
            img {
                position: absolute;
                right: 15px;
                top: 20%;
                cursor: pointer;
            }
            h5 {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #010101;
                text-align: center;
                margin-block-end: auto;
            }
        }
        .e_tab {
            background: #f2f2f2;
            border-radius: 8px;
            padding: 12px 18px;
            margin-right: 15px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #010101;
            transition: ease-in-out 0.3s;
            cursor: pointer;
            &:hover {
                background: #e1e1e1;
            }
        }
        .e_active {
            background: #5a69ee;
            color: $white;
            transition: ease-in-out 0.3s;
            &:hover {
                background-color: #1d2dbc;
            }
        }
        .e_back_btn {
            // position: absolute;
            // top: 12px;
            font-weight: 500;
            font-size: 10px;
            line-height: 150%;
            color: $black;
            cursor: pointer;
            &:hover {
                opacity: 0.7;
            }
            img {
                width: 10px;
                height: 10px;
                margin-right: 5px;
                vertical-align: text-top;
                transition: ease-in-out 0.3s;
            }
        }
    }
    .e_link_btn {
        padding: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $primary_color;
        position: relative;
        transition: ease-in-out 0.3s;
        &:hover {
            opacity: 0.7;
        }
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 100%;
            height: 1.5px;
            background-color: #5a69ee;
        }
    }
    .e_coming_soon {
        font-size: 16px;
        font-weight: 600;
        color: black;
        background-color: #edf3ff;
        border-radius: 9px;
    }
}
