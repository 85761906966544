/*
 *   File : phoneInput.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Phone Input style
 *   Integrations :
 *   Version : v1.0
 */

 @import "./../config.scss";
 .e_input_box ~ label {
   position: absolute;
   left: 100px;
   top: 24px;
   font-weight: 600;
   @extend %font14;
   letter-spacing: 0.01em;
   color: $input_placeholder;
   -webkit-transition: all 0.3s ease;
   transition: all 0.3s ease;
 }
 .e_input_text {
   font-weight: 500;
   @extend %font12;
   line-height: 12px;
   color: $input_text;
 }
 .success {
   font-style: normal;
   font-weight: 500;
   font-size: 10px;
   line-height: 12px;
   color: #007900;
 }
 .fail {
   font-style: normal;
   font-weight: 500;
   font-size: 10px;
   line-height: 12px;
   color: #E74A3B;
 }
 
 
 .PhoneInput {
   border: 1.8px solid #E6E6E6;
  //  background-color: #f0f0f0;
   outline: none;
   width: 100%;
   font-weight: 400;
   color: $black;
   padding: 20px;
   border-radius: 8px;
   padding: 11px 50px 11px 20px;
 }
 .e-success {
   
 }
 .PhoneInput--focus {
   border: 1.8px solid #5A69EE;
   background: rgba(208, 212, 250, 0.24);
     input {
       background-color: transparent!important;
     }
 }
 
 .PhoneInputCountryIcon--border {
   box-shadow: none !important;
   background-color: transparent !important;
 }
 .PhoneInputCountryIconImg {
   border-radius: 4px;
 }
 .PhoneInput {
   input {
     border: none !important;
     outline: none !important;
     font-weight: 600;
    //  background-color: #f0f0f0;
     @extend %font18;
     color: $black;
     &::placeholder {
       font-weight: 600;
       line-height: 18px;
       color: $input_placeholder;
       @extend %font12;
       letter-spacing: 0.25px;
     }
     &:focus {
       border: none !important;
       outline: none !important;
       label {
         color: $input_placeholder;
         @extend %font12;
         -webkit-transform: translateY(-14px);
         transform: translateY(-14px);
       }
     }
   }
 }
 
 .e_input_error {
   border: 1px solid $error;
 }
 .e_error_msg {
   @extend %font12;
   color: $error !important;
 }