.SingleDatePickerInput{
	background: $white!important;
	border-radius: 8px!important;
  border: 1px solid $input_boder!important;
  padding: 8px 0px!important;
	.DateInput_input{
		background-color: $white!important;	
		@extend %font14;
		font-weight: 600;
		border-radius: 8px 0px 0px 8px;
	} 
	.DayPicker_weekHeader_li{
		padding-right: 0!important; 
	}

}
.SingleDatePickerInput__withBorder{
	border: 1px solid $input_boder!important;
	width: 100%!important;
}
.SingleDatePicker {
	width: 100%!important;
}
.SingleDatePickerInput .DateInput__small {
    width: 80%!important;
}
.SingleDatePickerInput_calendarIcon_svg {
	fill: $secondary_color!important;
}
.e-invested-date .SingleDatePickerInput .DateInput__small {
   width: 60%!important;
}
.SingleDatePickerInput_calendarIcon{
	background-image: url('../../assets/images/input/calendar.svg')!important;
	background-repeat: no-repeat!important;
	background-position-y: 50%!important;
	.SingleDatePickerInput_calendarIcon_svg{
		display: none;
	}
}