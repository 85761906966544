/*
 *   File : methodology.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Methodology modal style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 14-06-2022
 */

@import "./../config.scss";

.e_methodology_modal {
  .e_modal_dialog {
    max-width: 720px !important;
  }
  .e_modal_content {
    padding: 0px !important;
    border-radius: 12px !important;
    .e_modal_header {
      display: inline-flex;
      background: #fafafa;
      padding-top: 24px;
      padding-bottom: 24px;
      h6 {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #010101;
        text-align: center;
      }
      .e_close {
        cursor: pointer;
      }
    }
    .e_container {
      overflow-y: scroll !important;
      height: 620px;
      padding: 0 36px;
      &::-webkit-scrollbar{
        height: 0!important;
      }
      h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #010101;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #010101;
        a {
          color: #6f80fc !important;
          text-decoration: underline;
          font-weight: 500;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      ul {
        padding-left: 23px !important;
        li {
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;
          color: #010101;
        }
      }
      ol {
        padding-left: 23px !important;
        li {
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;
          color: #010101;
          span {
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .e_container {
    padding: 0 10px!important;
    h4{
      font-size: 16px!important;
    }
    p{
      font-size: 12px!important;
    }
  }
}
