/*
 *   File : currentvalue.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Value card style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 20-12-2021
 */

 @import "./../config.scss";

.e_current_value {
  background-color: $primary_color!important;
  padding: 32px;
  box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
  border-radius: 8px!important;
  height: 135px;
  p {
    color: $white;
    font-weight: 500;
    @extend %font14;
    line-height: 17px;
  }
  h3 {
    @extend %h3;
    color: $white;
  }
 
}
