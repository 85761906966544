/*
 *   File : portfolioSummary.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Portfolio summary card style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 04-01-2022
 */

@import "./../config.scss";

.e_filter {
  background-color: $white !important;
  padding: 32px;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  position: absolute;
  width: 341px;
  z-index: 10;
  right: 0;
  .e_input_check {
    width: 5% !important;
    &:focus {
      box-shadow: none !important;
      border: none !important;
    }
  }
  img {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: $input_text;
    cursor: pointer !important;
  }
  .e_active_clear{
    span{
      color : $primary_color;
      font-weight: 600;;
    }
    
  }
  .e_inactive_clear{
    span{
      color :  $input_text;
      font-weight: 600;
    }
  }
  h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: $black;
  }
  p {
    font-weight: 500;
    @extend %font14;
    line-height: 17px;
    color: $input_placeholder;
  }
  .e_order_text{
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $black;
  }
  h3 {
    font-weight: 600;
    @extend %font14;
    line-height: 24px;
    color: $input_text;
  }
  h5 {
    @extend %h5;
  }
  .e_current_value {
    color: $primary_color;
  }
  .e_btn {
    background-color: $tooltip_bg !important;
    padding: 20px 40px !important;
    &:hover {
      opacity: 0.8;
    }
    &:after {
      // color: #fff!important;
      // background-color: $tooltip_bg!important;
      content: none;
    }
  }
  .e_filter_submit{
    padding: 8px 16px!important;
    background: #5A69EE;
    span{
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $white;
    }
  }
}
