/*
 *   File : headerDropdown.module.scss.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Header
 *   Integrations : null
 *   Version : 1.0
 *   Created : 09-12-2021
 */

@import "./../config.scss";

.e_menu_dropdown {
  background-color: $white;
  z-index: 100000;
  .e_logo {
    width: 29px;
    height: 24px;
    &:hover {
      cursor: pointer;
    }
  }
  .dropdown {
    position: relative;
  }
  .e_logout_div {
    position: absolute;
    z-index: 3;
    right: 0;
    top: 8px;
    width: 295px;
    background: $white;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    padding: 24px;
    height: 614px;
    overflow: auto;
    &::-webkit-scrollbar {
      // border-radius: 10px;
      height: 0px !important;
      background: #F0F0F0;
      width: 4px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #C0C0C0;
      border-radius: 12px;
    }
    span {
      color: $input_text;
      font-weight: 500;
      @extend %font14;
      cursor: pointer;
      // padding: 34px;
      display: block;
      // padding: 1px 5px 0px 15px;
    }
    .e_profile_bg {
      background-color: $modal_bg;
      border-radius: 8px;
      padding: 0 14px;
      width: 100%;
      .e_profile_img {
        @extend %font14;
        background-color: $primary_color;
        color: #ffffff;
        font-weight: 700;
        line-height: 17px;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        padding: 8px 11px;
        margin: 10% 0;
      }
      .e_profile_im {
        @extend %font14;
        background-color: $primary_color;
        color: #ffffff;
        font-weight: 700;
        line-height: 17px;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        padding: 8px;
        margin: 10% 0;
      }
      h4 {
        @extend %font14;
        line-height: 20px;
        color: $input_text;
        font-weight: 700;
        margin-bottom: 5px;
        cursor: pointer;
      }
      p {
        font-weight: 500;
        @extend %font14;
        line-height: 12px;
        color: $input_placeholder;
        cursor: pointer;
        word-break: break-all;
        font-size: 12px;
      }
    }
    .e_redeem {
      border: 1px solid $primary_color;
      box-sizing: border-box;
      border-radius: 8px;
      min-height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 0;
      width: 100%;
      &:after {
        color: $secondary_color !important;
        background: #edf3ff;
        border-radius: 8px;
        height: 100%;
        position: absolute;
        content: "";
        top: 0px;
        left: 0;
        width: 0;
        transition: all 0.35s;
        z-index: 1;
        opacity: 0;
      }
      &:hover:after {
        opacity: 1;
        width: 100%;
        box-shadow: none !important;
      }
      span {
        color: $primary_color;
        padding: 0 !important;
        font-weight: 700;
        z-index: 999;
        position: relative;
      }
    }
    .e_menu {
      span {
        padding: 0;
      }
    }
    .e_refer {
      background-color: $modal_bg;
      border-radius: 8px;
      padding: 4px;
      border: 1px solid $primary_color;
      width: 100%;
      img {
        cursor: pointer;
      }
      h4 {
        @extend %font14;
        color: $primary_color;
        font-weight: 400 !important;
      }
      span {
        padding: 0;
      }
    }
    .e_your_orders {
      background-image: url("../../assets/images/menu/orders.svg");
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      background-position: left;
    }
  }
  a {
    text-decoration: none;
  }

  .e_inner_header_link {
    font-weight: 500;
    @extend %font14;
    line-height: 130%;
    color: $input_text;
    text-decoration: none !important;
    padding-top: 20px;
    position: relative;
    margin-right: 30px;
  }
  .e_inner_header_link_active {
    color: $input_text;
    text-decoration: none;
    @extend %font14;
    line-height: 130%;
    display: inline-block;
    font-weight: 600;
    margin: 0px 30px 0px 0px;
    &::after {
      content: "";
      display: block;
      width: 100%;
      position: relative;
      bottom: 0px;
      top: 9px;
      height: 2px;
      background: $primary_color;
      transition: width 0.3s;
      z-index: 1;
    }
  }
  .e_copied_toast {
    width: max-content;
    bottom: 120px;
    left: 0;
    background-color: $primary_color;
    color: $white;
    @extend %font16;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 4px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.18);
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease-in, transform 0s ease 0.3s;
    pointer-events: none;
    &.e_copied_active {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.3s ease-in;
    }
  }
  .e_dummy_header {
    .e_profile_bg {
      width: auto;
    }
    .e_refer{
      width: auto;
    }
  }
}
