/*
 *   File : input.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : InputBox style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 06-12-2021
 */

@import "./../config.scss";

.e_input_wrap {
  position: relative;
  display: block;
  //    margin-bottom: 15px;
  .e_input_text {
    font-weight: 500;
    @extend %font12;
    line-height: 12px;
    color: $input_text;
  }
  .e_input_box {
    box-sizing: border-box;
    border-radius: 8px;
    @extend %font18;
    border: 1.8px solid #e6e6e6;
    background-color: transparent;
    outline: none;
    width: 100%;
    font-weight: 500;
    color: $input_text;
    padding: 12px 37px 13px 22px;
    font-size: 16px;
    &::placeholder {
      color: #c1c1c1;
      font-weight: 400;
    }
  }
}

.e_input_box:focus {
  border: 1.8px solid #5a69ee;
  //  background: rgba(208, 212, 250, 0.24);
  @extend %font12;
}
.e_success {
  position: relative;
  background-color: rgba(60, 188, 129, 0.12) !important;
  border: 1.8px solid #3cbc81 !important;
  background-image: url("../../assets/images/new-design/check-circle.svg");
  background-repeat: no-repeat;
  background-position: 95% 50%;
}
.e_fail {
  position: relative;
  background-color: #fce9e7 !important;
  border: 1.8px solid #e74a3b !important;
  background-image: url("../../assets/images/new-design/fail.svg");
  background-repeat: no-repeat;
  background-position: 90% 50%;
}
.e_input_error {
  border: 1px solid $error;
}
.e_error_msg {
  @extend %font12;
  color: $error !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield !important ;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input !important;
}

label {
  &:hover {
    cursor: pointer;
  }
}
// input:-internal-autofill-selected {
//   background-color: $white!important;
// }
