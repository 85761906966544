/*
 *   File : iconStyleToolTip.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Tooltip style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 08-12-2021
 */

@import "./../config.scss";

.e_icon_tooltip {
  background: $white;
  background: rgba(255, 255, 255, 0.24);
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(6px);
  // padding: 25px 25px 0px 30px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  min-width: 200px;
  height: auto;
  right: 10%;
  left: 10%;
  span {
    font-weight: 500 !important;
    color: $input_text !important;
    font-size: 12px !important;
    line-height: 24px;
    letter-spacing: -0.02em;
    font-family: $font-stack;
    &:hover {
      // opacity: 0.8;
      cursor: pointer;
    }
  }
  a {
    display: block;
    color: $primary_color !important;
    font-size: 12px !important;
    text-decoration: underline !important;
    padding-top: 30px;
    line-height: 24px;
  }
}
