/*
 *   File : overview.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Overview card style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 20-12-2021
 */

 @import "./../config.scss";

.e_overview {
  background-color: $white!important;
  padding: 32px;
  box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
  border-radius: 8px!important;
  p {
    color: $input_placeholder;
    font-weight: 500;
    @extend %font14;
    line-height: 17px;
  }
  h3 {
    @extend %h3;
    color: $black;
    font-weight: 700!important;
  }
  .e_icon {
    background: $white;
    box-shadow: 0px 0px 16px rgba(19, 41, 230, 0.24);
    border-radius: 100px;
    padding: 20px;
      img{
        position: relative;
        top: -2px;
        margin-right: 0!important;
      }
    // position: relative;
    // z-index:999;
    // transition: 0.4s ease-in-out;    
    &:hover {
      // cursor: not-allowed;
      transform:translateX(10%);
      transition: 0.4s ease-in-out; 
      box-shadow: 0px 0px 16px rgba(19, 41, 230, 0.60);   
    }
  }
  .e_tooltip_hover {
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  } 
 
}
