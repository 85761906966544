
@import '../config.scss';

.e_player_wrapper {
    position: relative;
    z-index: 10;
    video{
        // video {
            filter: blur(0)!important;
        // }
    }
    // player
    .e_player{
        position: absolute;
        width: 100%;
        top: -50px;
        left: 0;
        object-fit: cover;
        filter: blur(3px);
        transition: ease-in-out 0.5s;
        &::-webkit-media-controls {
            display: none!important;
        }
    } 
    // mute btn
    .e_sound_btn {
        position: absolute;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        z-index: 10;
        top: 6%;
        right: 6%;
    }    
    //play btn
    .e_play_btn {
        position: absolute;
        top: 75%;
        left: 46%;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        text-align: center;
        background: radial-gradient(50% 50% at 50% 50%, #1329e6 0, #e7eafd 90.1%);
        border: 1px solid transparent;
        // padding: 0 20px;
        z-index: 5;
        transition: 0.5s ease-in-out;
        
        cursor: pointer!important;
        // box-shadow: 0 0 0 0 rgba(9, 61, 247, 0.403);
            &::after {
                content: '';
                position: absolute;
                top: -1px;
                left: -1px;
                width: 50px;
                height: 50px;
                border-radius: 100%;
                text-align: center;
                background: radial-gradient(100% 100% at 50% 50%, transparent 0 , transparent 90.1%);
                // background-image: radial-gradient(shape size at position, start-color, ..., last-color);
                transform: scale(1);
                animation: pulse-black 2s infinite;
                z-index: -1;
            }
            img{
                transition: width ease 0.5s;
                width: 18px;
            }
    }
    @keyframes pulse-black {
        0% {
        //   box-shadow: 0 0 0 0 rgba(9, 61, 247, 0.403);
        background: radial-gradient(100% 100% at 50% 50%, transparent 0, transparent 90.1%);
        }
        50% {
            transform: scale(1.3);
            // box-shadow: 0 0 0 10px rgba(9, 61, 247, 0.403);
            background: radial-gradient(100% 100% at 50% 50%, transparent 0 , #1328e65c 90.1%);
        }
        70% {
          transform: scale(1.5);
        //   box-shadow: 0 0 0 10px rgba(9, 61, 247, 0.403);
        background: radial-gradient(100% 100% at 50% 50%, transparent 0, #1328e643 90.1%);
        }
        
        100% {
          transform: scale(2);
          background-color: transparent;
        }
      }
    .e_pause_btn {
        position: absolute;
        top: 75%;
        left: 46%;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        text-align: center;
        background: radial-gradient(50% 50% at 50% 50%, #1329e6 0, #e7eafd 90.1%);
        border: 1px solid transparent;
        // padding: 0 20px;
        z-index: 5;
        transition: 0.5s ease-in-out;
        img{
            transition: width ease 0.5s;
            // width: 20px;
        }
    }
    // .e_disabled {
    //     pointer-events: none!important;
    //     &:hover ~ video{
    //         filter: blur(5px)!important;
    //         transform: scale(1) !important;
    //     }
    // }
    // seeker style
    .e_seek_bar {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: absolute;
        z-index: 10;
        bottom: 5%;
        width: 80%;
        margin: auto;
        left: 0;
        right: 0;   
        cursor: pointer;
            &::-webkit-slider-runnable-track {
                background-color: white;
                border: none;
                height: 4px;
                outline: none;
                border-radius: 99px!important;
            }
            
            &::-ms-track {
                background-color: white;
                border: none;
                height: 4px;
                outline: none;
                border-radius: 99px!important;
            }
            &::-webkit-slider-thumb {
                cursor: pointer;
                -webkit-appearance: none;
                background: $white!important;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
                width: 20px;
                height: 20px;
                border-radius: 100px;
                margin-top: -8px;
            }
    }
  
    input[type=range] {
        -moz-appearance: none!important;
        background: transparent;
        &::-moz-range-track {
            box-shadow: none!important;
            box-sizing: none!important;
            border: 0 !important;
            outline: none !important;
            border-color: red;
            -moz-appearance: none!important;
            background-color: #fff;
            height: 4px!important;
            border-radius: 99px!important;
        }
        &::-moz-range-thumb {
            cursor: pointer;
            -moz-appearance: none!important;
            background: $white!important;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            width: 20px;
            height: 20px;
            border-radius: 100px;
            margin-top: -8px;
        }
        &::-moz-focus-outer  {
            border: 1px solid red!important;
            box-shadow: none!important;
            box-sizing: none!important;
            outline: none !important;
            background-color: transparent!important;
        }
    }
    p {
        position: absolute;
        z-index: 10;
        bottom: 5%;
        left: 10%;
        margin: auto;
        left: 0;
        width: max-content;
        right: 0;  
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
    }
    // .e_app_logo {
    //     position: absolute;
    //     z-index: 10;
       
    // }
}

.e_video_overlay {
    position: relative;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(14, 16, 18, 0.36);
        opacity: 0.6;
        z-index: 4;
        bottom: 0;
        left: 0;
        backdrop-filter: blur(9.656px);
    }
    video{
        filter: blur(6px)!important;
    }
}
