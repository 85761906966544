/*
 *   File : toast.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Toast.module.scss
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_toast {
  position: fixed;
  bottom: 20px;
  right: 35%;
  width: 486px;
  padding: 24px 15px 24px 40px;
  background-color: $white;
  // border: 1px solid #f1f1f1;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
  font-size: 14px !important;
  background-size: 18px;
  background-position: 15px;
  background-repeat: no-repeat;
  z-index: 1099;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    height: 4px;
    background: $red;
    width: 85%;
    left: 0;
    bottom: 0;
    border-bottom-left-radius: 8px;
  }
}
.e_success {
  // @extend .e_toast;
  background-image: url("../../assets/images/note/check.png");
  // border-color: $toast_success_border;
  // background-color: $toast_success_bg;
  color: $toast_success_color;
  overflow: hidden;
  font-size: 14px !important;
  text-overflow: ellipsis;
  line-height: 15px !important;
}
.e_fail {
  // @extend .e_toast;
  background-image: url("../../assets/images/note/error.svg");
  // border-color: red;
  // background-color: $toast_fail_bg !important;
  overflow: hidden;
  text-overflow: ellipsis;
  background-repeat: no-repeat;
  font-size: 14px !important;
  line-height: 15px !important;
}
