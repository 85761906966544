/*
 *   File : portfolioSummary.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Portfolio summary card style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 20-12-2021
 */

@import "./../config.scss";

.e_portfolioSummary {
  background-color: $tooltip_bg !important;
  padding: 32px;
  // box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
  border-radius: 8px !important;
  border: 1px solid $input_boder !important;
  transition: box-shadow 0.3s;
  &:hover {
    // cursor: pointer;
    // box-shadow: 0 0 11px rgba(33,33,33,.2);
  }
  h6 {
    @extend %h6;
    color: $input_text;
    font-weight: 700 !important;
  }
  p {
    font-weight: 500;
    @extend %font14;
    line-height: 17px;
    color: $input_placeholder;
  }
  h3 {
    font-weight: 600;
    @extend %font14;
    line-height: 24px;
    color: $input_text;
  }
  h5 {
    @extend %h5;
  }
  .e_current_value {
    color: $primary_color;
  }
  .e_btn {
    background-color: $tooltip_bg !important;
    padding: 20px 0px !important;
    &:hover {
      opacity: 0.8;
    }
    &:after {
      // color: #fff!important;
      // background-color: $tooltip_bg!important;
      content: none;
    }
  }
  .e_sip_month {
    color: $primary_color;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background: #EDF3FF;
    border: 1px solid #1329E6;
    border-radius: 4px;
    padding: 2px 8px;
    box-shadow: inset 0 0 2px 0px #1329e6;
  }
}
