@charset "utf-8";

/* ==============================
   Small desktop
   ============================== */
@media only screen and (max-width: 1455px) {
  .e-quiz-heading {
    font-size: 20px !important;
  }

  /*assets*/
  .portfolioSummary_e_btn__3dCTy {
    padding: 20px 28px !important;
  }

  /*asset-detail*/
  .e-scrip-container .e-pie-container .e-piechart-label-type {
    right: 11%;
  }
  .secondary_e_s_btn__1lSre {
    padding: 18px 0px;
  }
  .e-sip-cancel-btn {
    padding: 18px 20px;
  }
  .portfolioSummary_e_portfolioSummary__NlL6n .portfolioSummary_e_btn__LG-64 {
    padding: 20px 0px !important;
  }
  .e-change-pwd {
    padding: 18px 0px !important;
  }

  .e-overview-container .e-invest-more,
  .e-full-width {
    padding: 20px 0px !important;
  }
}

@media only screen and (max-width: 1400px) {
  /*assets*/
  .e-assets-container .e-pie-container .e-piechart-label {
    right: 7%;
  }

  // New design
  .e-dashboard-summary-card {
    // .e-portfolio-value {
    //   font-size: 28px !important;
    // }
    .e-gain-percent {
      img {
        width: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .e-scrip-container .e-pie-container .e-piechart-label-type {
    right: 5%;
  }

  .e-scrip-container .e-pie-container .e-piechart-label-type p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 120px;
  }

  .secondary_e_s_btn__1lSre {
    padding: 18px 0px;
  }
  .e-sip-cancel-btn {
    padding: 18px 20px;
  }

  .portfolioSummary_e_portfolioSummary__NlL6n .portfolioSummary_e_btn__LG-64 {
    padding: 20px 0px !important;
  }

  .e-overview-container .e-invest-more {
    padding: 20px 0px !important;
  }
  .e-graph-card {
    .apexcharts-canvas {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
    }
  }
  .e-portfolio-card {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .e-logout-div {
    height: 500px !important;
    overflow-y: auto !important;
  }
  .e-portfolio-withdraw {
    padding: 20px 0px !important;
  }

  .e-accordion-column {
    width: 200px !important;
  }
  .e-portfolio-detail .e-allocation-card label {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) {
  /*assets*/
  // .e-assets-container .e-pie-container .e-piechart-label {
  //   display: none;
  // }
}

@media only screen and (max-width: 1199px) {
  /*portfolio*/
  .e-portfolio-container .e-piechart-label-type {
    right: 2%;
  }

  .e-portfolio-detail {
    .e-tab-wrapper {
      height: 53px;
      overflow-x: auto;
      white-space: nowrap;
      overflow-y: hidden;
    }
  }

  .e-radio-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
  .e-allocation-detail {
    .e-tab-wrapper {
      overflow: auto;
    }
  }
  .e-profile-container {
    overflow-y: auto;
  }
}

/* ==============================
   Tablet landskape
   ============================== */
@media only screen and (max-width: 1024px) {
  /*asset detail*/

  .e-scrip-container .e-pie-container .e-piechart-label-type {
    right: 35%;

    p {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 992px) {
  .e-info {
    width: 440px !important;
    left: 58%;
  }
  /*header*/
  .headerOuterFull_e_headerOuterBtn__1qB50,
  .headerOuterFull_e_headerOuterLogin__2-uLZ,
  .headerOuterFull_e_headerauthLogin__1K9xO {
    background-color: white;
  }
  .e-invest-rupee {
    left: 6% !important;
  }
  .e-auth-video-container .e-form-wrap {
    margin-top: 60px;
  }

  /* Auth */
  .e-video-player {
    display: none;
  }
  .e-auth-banner {
    display: none;
  }

  .e-content-wrap {
    overflow-x: hidden;
  }

  .e-quiz-heading {
    font-size: 1.5rem !important;
  }

  .e-detail-sec td {
    padding: 20px 1px 22px 6px;
  }

  /*Overview*/
  .e-small-device {
    padding-top: 20px;

    h3 {
      padding-top: 0px !important;
    }
  }

  .react-joyride__spotlight {
    // top: 940px !important;
  }
  /*assets*/
  .e-assets-container .e-pie-container {
    height: initial;
  }

  .e-assets-container .e-pie-container .e-piechart-label {
    display: inline;
  }

  .e-assets-container .e-pie-container .e-piechart-label {
    top: 100px;
    right: 6%;
  }

  /*portfolio*/
  .e-portfolio-container .e-piechart-label-type {
    right: 8%;
  }
  .e-portfolio-container .e-graph-card {
    height: 98%;
  }
  .e-portfolio-stat-card {
    height: 100% !important;
  }
  .e-portfolio-legend {
    max-height: 98px;
    overflow: auto;
  }

  .e-portfolio-container .e-asset-card-wrapper {
    width: 50%;
  }

  .e-assets-container .e-piechart-label h2 {
    width: 100%;
  }
  .e-player-modal {
    .modal-dialog {
      min-width: 600px !important;
    }
  }
  .e-pie-container {
    // position: initial!important;
  }
  .e-assets-container .e-pie-container .apexcharts-canvas {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  // New design
  .e-main-content {
    width: 100%;
    overflow-x: hidden;
  }
  .e-side-bar-lg {
    display: none !important;
  }
  .e-inner-small-screen-title {
    display: block !important;
  }
  .e-small-screen-menu {
    // display: block;
  }
  .e-sidebar-logo {
    display: none;
  }
  .e-side-abr-close-btn {
    display: block;
  }
  .e-side-bar {
    z-index: 10;
  }

  // Asset
  .e-asset-container {
    .e-asset-popup-open {
      right: 0 !important;
      width: 100%;
    }
  }

  // Onboard
  .e-onboard {
    .e-onboard-progress-section {
      display: none !important;
    }
    .e-step-progress {
      position: fixed;
      top: 0;
      z-index: 99;
    }
    .e-small-screen-progress-wrapper {
      width: 100%;
      height: 91px;
      margin-top: 22px;
      overflow-x: scroll;
      white-space: nowrap;
      // &::-webkit-scrollbar {
      //   // height: 0px !important;
      //   width: 4px!important;
      // }
    }
    .e-onboard-progress-section-small-screen {
      display: block !important;
      .e-onboard-progress-wrapper {
        position: relative;
        margin-top: 20px;
        width: 500px;
      }
      .e-progress-label-wrapper {
        position: relative;
        margin-top: 20px;
        width: 500px;

        h5 {
          font-weight: 600;
          font-size: 11.9431px;
          line-height: 15px;
          color: #010101;
        }
        p {
          font-weight: 600;
          font-size: 7.96209px;
          line-height: 10px;
          color: rgba(0, 0, 0, 0.75);
          margin-block-end: 0;
        }
        .e-progress-label {
          position: absolute;
          top: 10px;
        }
        .e-progress-label:nth-child(1) {
          left: 1%;
        }

        .e-progress-label:nth-child(2) {
          left: 30%;
        }

        .e-progress-label:nth-child(3) {
          left: 60%;
        }
        .e-progress-label:nth-child(4) {
          left: 100%;
        }
      }
    }
  }

  // Portfolio
  .e-onboard-step-section {
    height: max-content;
  }
  .e-portfolio-section .e-fixed-btn-wrapper {
    width: 98%;
  }
  .e-portfolio-section .e-fixed-btn-wrapper .e-fixed-btn {
    width: 92%;
    left: 2%;
  }
  .e-verification {
    .e-video-recorder-wrapper video {
      height: 75vh !important;
    }
  }
}

/* ==============================
   Tablet Portrait
   ============================== */
@media only screen and (max-width: 768px) {
  .e-tour-container {
    height: 100% !important;
  }
  .e-auth-container.e-confirm-otp .e-form-wrap {
    margin-top: 60px;
  }
  .invest_e_invest_modal__C8G6p .invest_e_amount_input__3PRVw {
    padding: 12px 20px 13px 35px;
  }
  .invest_e_invest_modal__C8G6p .invest_e_rupee__3cjGq {
    left: 7%;
  }
  .e-process-wrap,
  .e-faq-wraper {
    padding: 0 !important;
  }

  .e-toast {
    bottom: 22%;
    width: 250px !important;
    right: 20%;
    .e-fail,
    .e-success {
      bottom: 5%;
    }
  }

  .e-auth-container h3 {
    line-height: 30px !important;
  }

  .e-pending-amount p {
    line-height: 24px !important;
  }

  .e-mobile-view {
    display: block;
  }

  .e-radiometric-sec {
    display: none;
  }

  .e-mobile-back {
    padding-left: 12px !important;
    margin-left: 0px !important;
  }

  .e-mobile-continue-wrap {
    background-color: white;
  }

  .e-profile-side-tab {
    padding: 0px !important;
  }

  .e-mobile-confirm-wrap {
    padding-top: 0px !important;
    margin-top: 12px !important;
  }

  .e-last-name {
    // margin-top: 0px !important;
  }

  .e-header-inner {
    padding: 36px 32px;
  }

  .e-overview-container {
    padding: 0 32px 130px;
  }

  .e-detail-bg {
    padding: 50px 32px 30px 32px !important;
  }

  /*overview*/
  .e-tab-device {
    padding-top: 30px;
  }

  .e-profile-sec .iconStyleToolTip_e_icon_tooltip__1eSJm {
    top: 63rem;
  }

  .e-edit-img {
    right: 40%;
  }

  .e-order-accordion {
    .e-order-accordion-body-wrapper {
      min-width: 850px;
    }

    .e-order-accordion-body {
      overflow-x: scroll;
    }
  }

  .e-notification-menu {
    width: 200px;
  }

  .ant-picker-input:focus .ant-picker-clear {
    display: block;
    right: 10px;
  }

  .e-area-chart-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .e-player-modal {
    .modal-dialog {
      min-width: 500px !important;
    }
  }
  /*onboard*/
  .e-onboard-tooltip {
    right: 30%;
    top: 60px;
  }
  .e-portfolio-tracker {
    .e-graph-card {
      .apexcharts-canvas {
        position: absolute;
        left: 0 !important;
        right: 0;
        margin: auto;
      }
    }
    .e-allocation-detail {
      .e-tab-wrapper:first-child {
        margin-left: 1.5px;
      }
    }
  }
  .e-scrip-container .e-pie-container .apexcharts-canvas {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  .e-portfolio-tracker .e-graph-card .e-graph-legends {
    width: 100% !important;
  }
  .e-guest-prompt {
    width: 500px;
    height: max-content;
    top: 20%;
  }
  .e-guest-overlay {
    overflow: auto;
    height: 100%;
  }
  .e-guest-help {
    bottom: -20%;
  }

  // New design
  .e-dashboard .e-home-faq {
    display: block;
  }
  .e-home-faq-wrapper {
    width: 100% !important;
  }
  .e-asset-container {
    .e-vertical-rule {
      display: none !important;
    }
  }

  // Portfolio tracker
  .e-portfolio-container {
    .e-graph-card {
      height: max-content !important;
      .apexcharts-canvas {
        top: 60px !important;
      }
    }
  }

  .e-portfolio-legend {
    max-height: max-content;
  }

  // Onboard
  .e-onboard-progress-section {
    height: max-content !important;
    border: none !important;
  }

  // Profile
  .e-profile-container {
    height: max-content !important;
    overflow-y: auto !important;
    .e-profile-tab,
    .e-active-profile-tab {
      border: none !important;
    }

    .e-profile-tab-wrapper {
      border-bottom: 1px solid #ccc;
      display: flex;
      white-space: nowrap;
      overflow: auto;
      .e-profile-tab:nth-child(1) {
        margin-right: 45px;
      }
      .e-profile-tab:nth-child(2) {
        margin-right: 48px;
      }
      .e-active-profile-tab:nth-child(1) {
        margin-right: 45px;
      }
      .e-active-profile-tab:nth-child(2) {
        margin-right: 48px;
      }
    }
  }

  .e-onboard-step-section {
    .e-step-title {
      font-size: 18px;
    }
  }
  .e-rupee {
    left: 4%;
    top: 15px;
  }

  .e-portfolio-section .e-fixed-btn-wrapper {
    width: 100%;
  }
  .e-portfolio-section .e-fixed-btn-wrapper .e-fixed-btn {
    width: 92%;
  }

  .e-portfolio-indicator {
    position: absolute;
    top: 5%;
    right: 5%;
  }
  .e-asset-container {
    padding-bottom: 50px;
  }
  .e-arrow-img {
    display: none;
  }
}

/* ==============================
   Phone
   ============================== */
@media only screen and (max-width: 575px) {
  body {
    .e-tour-overview-container {
      overflow: unset;
      position: unset;
    }
  }
  .e-invest-rupee {
    left: 8% !important;
  }
  .e-portfolio-section .e-chart-card .e-chart-label .e-chart-label-text {
    font-size: 13px !important;
  }
  .e-ant-daterange-picker .ant-picker-suffix {
    top: 12px;
    right: 2px;
  }
  .e-small-device {
    padding-top: 0px;

    h3 {
      padding-top: 0px !important;
    }
  }
  .e-asset-detail-wrapper .e-asset-details {
    padding-top: 15px;
  }
  .e-btn-close {
    position: absolute;
    right: 15px;
    top: 24px;
  }
  .modal-header h6,
  .e-modal-title {
    font-size: 18px !important;
    margin-bottom: 24px;
  }
  .e-content-wrap .e-onboard-index {
    padding: 20px;
  }
  .e-small-screen-menu {
    width: 100% !important;
  }

  .e-support-btn {
    right: 10px !important;
  }
  /*inner header*/
  .headerInner_e_headerInner__b4vid .headerInner_e_logo__CYFVJ {
    margin-right: 0;
  }

  .e-laptop-view {
    display: none;
  }

  .e-sample-video-pose {
    width: 100%;
  }
  .e-tooltip-hover {
    margin-left: 4px !important;
  }

  .e-skip-analysis {
    text-align: left !important;
  }

  .align-center {
    text-align: center !important;
  }

  .star {
    padding: 0px !important;
  }

  .e-onboard-process .e-process-wrap {
    .e-tooltip-pan,
    .e-tooltip,
    .e-tooltip-account {
      right: 10%;
      left: 10%;
    }
  }

  .e_img_splash {
    position: absolute;
    top: 45vh;
    left: 20%;
    animation: img-zoom-out 2s 1s forwards;
  }

  @keyframes img-zoom-out {
    0% {
      position: absolute;
      top: 45vh;
      // left: 42%;
      height: 100px;
    }

    100% {
      position: absolute;
      height: 32px;
      left: 3.7%;
      top: 4.3%;
    }
  }

  .e-filter-return {
    padding-left: 0px !important;
  }

  .e-header-outer-half {
    padding: 32px 44px 32px 24px;
  }

  .e-radio-buttons {
    display: block;
  }

  .e-step-4 {
    margin-left: 0px !important;
  }

  .e-header-outer-full-confirm,
  .e-header-outer-full {
    width: 100%;
    padding: 32px 44px 32px 24px;
    background-color: white;
  }

  .e-header-outer-btn,
  .e-header-outer-login,
  .e-header-outer-full-login {
    padding: 32px 44px 15px 24px !important;
  }

  .e-scrip-container {
    .donut {
      padding-left: 24px;
    }
  }

  /*auth*/

  /*Overview*/
  .e-mobile-scroll {
    overflow-x: scroll;
    overflow-y: hidden;

    .e-span-wrap {
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }

  .e-mobile-scroll::-webkit-scrollbar {
    height: 8px !important;
  }

  /*assets*/
  .e-assets-container .e-pie-container .e-piechart-label {
    right: 3%;
  }

  .e-assets-container .e-piechart-label h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .text-end.e-text-start {
    text-align: start !important;
  }

  /*portfolio*/
  .e-portfolio-container .e-piechart-label-type {
    top: 12%;
    right: 0%;
    position: relative;

    p {
      width: 100%;
    }
  }
  .e-logout-div {
    height: 500px;
    overflow-y: scroll;
  }

  .e-portfolio-container .e-piechart-label-type p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
  }

  .e-portfolio-container {
    .e-asset-card-wrapper {
      width: 100%;
    }
  }

  /*sip*/
  .e-sip-table {
    overflow-x: scroll;

    table {
      min-width: 350px;
    }

    .table {
      min-width: 650px;
    }
  }

  .e-portfolio-section .e-portfolio-riskscore-preview .e-score {
    position: absolute;
    top: 13%;
    right: 5%;
  }
  .e-portfolio-section .e-portfolio-riskscore-preview .e-invest-btn {
    width: 100%;
  }

  /*nominee*/
  .e-report-accordion .e-accordion-sec {
    padding: 10px 4px !important;
  }

  .e-report-accordion .e-accordion-wrapper {
    padding: 4px !important;
  }

  .e-invest-btn {
    text-align: left !important;
    padding-top: 30px;
  }

  .e-personal-detail-icon {
    // display: block !important;
    justify-content: flex-start !important;
    position: relative;
    left: -7px;
  }
  .e-profile-sec .e-edit {
    margin-right: 20px;
  }

  .e-side-wrap {
    padding: 34px !important;
  }

  .e-invest-tooltip {
    max-height: 350px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 25px !important;
  }
  .e-child-faq-btn {
    padding-bottom: 55px !important;
  }
  .e-player-modal {
    .modal-dialog {
      min-width: 95% !important;
    }
  }
  /*onboard*/
  .e-onboard-tooltip {
    right: 12% !important;
    top: 60px;
  }
  .e-order-accordion-icon {
    top: 55% !important;
  }
  .e-take-tour-btn {
    margin-left: 0;
    margin-top: 10px;
    padding: 8px 42px;
    display: block !important;
    width: 100%;
  }
  .e-auth-btn-wrapper {
    top: 10%;
  }
  .e-logo-small-screen {
    top: 10%;
  }
  .e-back-mw {
    position: absolute;
    top: -41px;
    right: 0;
  }
  .e-btn-position {
    position: fixed;
    bottom: 16px;
    z-index: 99;
    width: max-content;
    margin: auto !important;
    right: 0% !important;
    left: 0 !important;
  }
  .e-next-span {
    // display: none;
  }
  .e-onboard-next {
    display: block;
  }
  .e-onboard-process .e-process-wrap .e-radio-wraper {
    min-height: 60px;
    label {
      line-height: 20px;
    }
    // padding: 21px 10px 20px 10px;
  }
  .e-graph-card {
    position: initial;
  }
  .e-security-modal-img {
    width: 20px;
  }
  .e-login-whatsapp {
    margin-right: 10px;
  }

  .e-child-faq-btn {
    &::after {
      right: 0 !important;
    }
  }
  .e-new-onboard .e-login-phone-input {
    width: 100%;
  }
  .e-take-tour {
    width: 100%;
  }
  .e-personal-detail-icon {
    display: inherit;
  }
  .e-finish-tour-btn {
    padding: 20px !important;
    width: 100%;
  }
  .react-joyride__spotlight {
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
  }
  .__floater__open {
    width: 95% !important;
  }
  .react-joyride__tooltip svg {
    top: 10px !important;
  }
  .e-add-asset-modal-btn {
    width: 100% !important;
  }
  .e-allocation-detail .e-allocation-card .e-icon-wrap {
    width: 40px;
    height: 40px;
    padding-top: 6px;
  }
  .e-guest-prompt {
    width: 90%;
    height: max-content;
    top: 30%;
  }
  .e-guest-logout {
    display: none;
  }
  .e-guest-header-placeholder {
    display: block;
  }
  .e-guest-dropdown {
    .e-logout-div {
      top: 70px !important;
      right: 20px;
      height: 325px !important;
    }
  }
  .e-guest-help {
    bottom: -25%;
  }
  .e-whatsapp-btn {
    background-color: rgba(172, 235, 196, 1);
  }
  .e-calendly-btn {
    background-color: rgba(118, 175, 255, 1);
  }
  .e-process-wrap,
  .e-faq-wraper {
    padding: 0 !important;
  }

  .e-invest-modal-accordion .accordion-item .e-accordion-btn {
    font-size: 12px;
    &::after {
      padding: 3px !important;
      right: 0;
      top: 5px !important;
    }
    img {
      width: 20px;
    }
  }
  .e-invest-modal-accordion .accordion-item .accordion-header {
    padding-bottom: 10px;
  }
  .e-order-filter-dstp {
    display: none;
  }
  .e-filter-btn-mobile {
    display: block;
  }
  .e-cancel-edit-score {
    width: 100%;
    margin-top: 20px;
  }
  .e-send-btn {
    padding: 0 !important;
  }

  // Onboard
  .e-onboard-step-section {
    .e-quiz .e-quiz-radio-wrapper {
      width: 100% !important;
    }
    .e-quiz label {
      font-size: 16px;
    }
    .e-score-wrapper {
      width: 500px;
      overflow: scroll;
    }
  }
  .e-verification {
    .e-video-recorder-wrapper video {
      height: 55vh !important;
    }
    .e-video-upload,
    .e-record-again {
      width: 100%;
      margin-top: 15px;
    }
    .e-stop-record {
      width: 100%;
    }
  }

  .e-dashboard .e-home-faq .accordion-item .accordion-button {
    padding: 20px 20px !important;
  }

  .e-calendly-btn {
    margin-top: 10px;
    // margin-left: 5px;
    width: 165px;
  }
  .e-credential-wrapper {
    span {
      width: 244px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .welcomeModal_e_welcome_modal__Cw9j6 .welcomeModal_e_content__XgeL3 .welcomeModal_e_product_tour__1Vmtl {
    padding: 9px 26px;
    margin-left: -12px;
  }
  .tab_e_tab__21CMi,
  .tab_e_active_tab__NZXGP {
    padding: 12px 14px;
  }

  .e-portfolio-container .e-asset-card-wrapper {
    width: 100%;
  }

  .e-asset-container .e-asset-table-title h6 {
    font-size: 14px;
  }
  .e-asset-container .e-table-body p,
  .e-asset-container .e-table-body span {
    font-size: 13px;
  }
  .e-order-filter {
    left: 0;
    right: 0;
    margin: auto;
  }
  .e-overlay-order::after {
    height: 100%;
    left: 0;
    position: fixed;
  }
  .e-notification-card {
    .e-notification-icon {
      width: 40px;
    }
  }
  .assetModalBody_e_rupee__34QQK {
    left: 4%;
  }

  .e-portfolio-detail .e-allocation-card .e-icon-wrap {
    width: 46px;
    height: 46px;
    padding-top: 10px;
  }
  .e-portfolio-detail .e-allocation-card h6 {
    font-size: 16px;
  }

  .welcomeModal_e_welcome_modal__Cw9j6 .welcomeModal_e_content__XgeL3 .welcomeModal_e_title__21cP_ img {
    float: right;
    cursor: pointer;
    position: absolute;
    top: 7%;
    right: 9px;
    width: 30px;
  }

  .welcomeModal_e_welcome_modal__Cw9j6 .welcomeModal_e_content__XgeL3 .welcomeModal_e_title__21cP_ {
    font-size: 22px;
  }
}

@media only screen and (max-width: 380px) {
  .e-score-selector {
    margin-right: 5px !important;
  }
  .e-ant-daterange-picker .ant-picker {
    padding: 14px 12px !important;
  }

  .css-14el2xx-placeholder {
    white-space: pre;
    font-size: 12px !important;
  }

  .e-allocation-detail .e-allocation-card .e-icon-wrap {
    width: 35px;
    height: 35px;
    padding-top: 4px;
  }

  .e-ant-daterange-picker .ant-picker-range-separator {
    padding: 0 4px 0px 0px !important;
  }

  .ant-picker-range-wrapper {
    width: 276px !important;
    max-width: 276px !important;
  }

  .ant-picker-panel-container {
    overflow-x: scroll !important;
  }

  .e-faq-tab-sec {
    min-width: 200px;
    overflow-x: scroll;
    padding: 0px;
    justify-content: inherit !important;
    &::-webkit-scrollbar {
      height: 2px !important;
    }
  }
  .e-calendly-btn {
    display: block;
    margin-top: 10px !important;
    margin: auto;
  }

  // .e-allocation-input {
  //   position: absolute;
  //   top: 20px;
  //   right: 50px;
  // }
  .e-area-chart-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .e-login-whatsapp {
    margin-right: 0 !important;
    margin-bottom: 15px;
  }

  .e-guest-help {
    bottom: -35%;
  }
  .e-verification {
    .e-video-recorder-wrapper video {
      height: 45vh !important;
    }
  }
  .e-asset-container .e-asset-popup-open {
    left: -17px !important;
  }
}

/* ==============================
   Small Phone
   ============================== */
// @media only screen and (max-width: 319px) {
// }
