@import "../config.scss";

.e_welcome_modal {
    .e_dialog {
        max-width: 602px !important;
    }
    .e_content {
        .e_title {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: $input_text;
            img {
                // width: 30px;
                // height: 30px;
                float: right;
                cursor: pointer;
            }
        }
        .e_subtitle {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: rgba(1, 1, 1, 0.6);
        }
        h6 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: $input_text;
        }
        .e_text {
            font-weight: 500;
            font-size: 13px;
            line-height: 120%;
            color: rgba(1, 1, 1, 0.75);
        }
        .e_separator {
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.6);
        }
        .e_team {
            width: 205px;
            object-fit: cover;
        }
        .e_product_tour {
            background: rgba(254, 200, 53, 0.3);
            border: 1px solid #fed973;
            border-radius: 6px;
            outline: none;
            padding: 9px 18px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #a77a00;
            transition: ease-in 0.4s;
            &:hover {
                background-color: rgb(225 177 46 / 59%);
            }
            img {
                vertical-align: sub;
            }
        }
    }
}
