/*
 *   File : radioButton.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Primary Button Style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

$md-radio-checked-color: #5A69EE;;
$md-radio-border-color: #6c6c6c;
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 15x;

.e_radiobutton,
.radioButton_e_radiobutton__MQ5Ie {
  margin: 16px 0;

  input[type="radio"] {
    display: none;
    &:disabled + label {
      cursor: no-drop;
      opacity: 0.3;
    }
    &:checked + .e_radio {
      border-color: #5A69EE;;
    }

    &:checked + .e_radio:after {
      transform: scale(1);
    }
  }

  label {
    display: flex;
    align-items: center;
    height: $md-radio-size;
    position: absolute;
    margin-bottom: 0;
    left: 30px;
    width: 200px;
    // top: 0;
    // bottom: 0;
    // margin: auto;
    cursor: pointer;
    vertical-align: bottom;
    font-weight: 400 !important;
    color: $input_text;
    

  }
  .e_radio {
    width: $md-radio-size;
    height: $md-radio-size;
    border: 1px solid $md-radio-border-color;
    border-radius: 50%;
    position: relative;
    margin-right: 10px;
      &::after  {
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        border-radius: 100px;
        transition: all 0.3s ease;
        transition-property: transform, border-color;
        width: $md-radio-checked-size;
        height: $md-radio-checked-size;
        transform: scale(0);
        background: #5A69EE;;
        position: absolute;
      }
  }
}
// .radioButton_e_radiobutton__gL4Sj label:before {
//   width: 22px;
//   height: 22px;
// }
// .radioButton_e_radiobutton__gL4Sj label:after {
//   display: inline-block;
//   width: 10px;
//   height: 10px;
// }

.e-input-radio {
  margin: 16px 0;

  input[type="radio"] {
    display: none;
    &:disabled + label {
      cursor: no-drop;
    }
    &:checked + .e_radio {
      border-color: #5A69EE;;
    }

    &:checked + .e_radio:after {
      transform: scale(1);
    }
  }
}
//   label {
//     display: flex;
//     align-items: center;
//     height: $md-radio-size;
//     position: relative;
//     // padding: 2px ($md-radio-size + 10px);
//     padding: 0px 0px 0px 30px;
//     margin-bottom: 0;
//     cursor: pointer;
//     vertical-align: bottom;
//     font-weight: 400 !important;
//     color: $input_text;
//     &:before,
//     &:after {
//       position: absolute;
//       content: "";
//       border-radius: 50%;
//       transition: all 0.3s ease;
//       transition-property: transform, border-color;
//     }

//     &:before {
//       position: absolute;
//       left: 0px;
//       width: $md-radio-size;
//       height: $md-radio-size;
//       border: 1.5px solid $red;
//     }

//     &:after {
//       position: absolute;
//       top: 6.2px !important;
//       left: 6.4px !important;
//       width: $md-radio-checked-size;
//       height: $md-radio-checked-size;
//       transform: scale(0);
//       background: $primary_color;
//     }
//   }
// }
