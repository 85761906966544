
@import "./../config.scss";

.e_allocation_modal {
    .e_dialog {
        max-width: 640px!important;
        .e_modal_content {
            height: auto !important;
            padding: 0!important;
            border-radius: 12px;
            
          }
          .e_modal_header {
            background: #F6F6F6;
            position: relative;
            border-radius: 12px 12px 0px 0px;
                h5 {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    color: $black;
                }
              .e_modal_close {  
                border: none;
                outline: none;
                background: transparent;
                position: absolute;
                right: 3%;
                top: 30%;
                width: 28px;
                height: 28 px;
                cursor: pointer;
              }
          }
      }
      .e_graph_legends {
        p{
            font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $black;
        }       
        
      }
      .e_chart_label {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
      }
      label {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.6);
      }
      .e_amount {
        span {
          font-size: 15px!important;
          vertical-align: bottom;
          margin-right: 5px;
        }
      }
      span {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }
      .e_submit {
        width: 100%;
        padding: 18px 24px;
            &:hover {
                img {
                    transform: translateX(5px);
                }
                
            }
            span {
                color: $white;
                display: inline;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
            }
            img {
                transition: ease-in-out 0.3s;
            }
      }
      .e_desc {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.6);
      }
}