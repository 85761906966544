/*
 *   File : footer.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : footer style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 07-12-2021
 */

 @import "./../config.scss";

.e_footer {
  padding: 44px 52px;
  background-color: $white;
  .e_social_wrap {
    .e_link a{
      font-weight: 400;
      @extend %font14;
      line-height: 160%;
      color: $black;
      text-decoration: none;
      margin-right: 24px;
      &:hover {
        color: $primary_color;
        text-decoration: underline;
        transition: .3s ease-in-out;
      }
    }
  }
  .e_footer_copyright_link {
    font-weight: 400;
    @extend %font14;
    line-height: 20px;
    color:$black;
    text-decoration: none;
  }
  .e_footer_powered {
    font-weight: 400;
    @extend %font14;
    line-height: 20px;
    color:$primary_color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      transition: .3s ease-in;
    }
  }
  .e_app_icon {
    transition: all .3s ease-out;
    &:hover {
      -webkit-transform: translate(0px,-8px);
      cursor: pointer;
    }
  }
}
