/*
 *   File : selectBox.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : SelectBox style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 29-10-2021
 */

@import "./../config.scss";

.e-select-wraper {
  .css-1s2u09g-control {
    border: 1.8px solid #e6e6e6 !important;
    border-radius: 8px;
    color: $input_text !important;
    padding: 8px 0px 9px 8px !important;
    &:hover {
      cursor: pointer;
    }
  }
  .css-14el2xx-placeholder {
    font-weight: 500 !important;
    @extend %font14;
    color: rgba(0, 0, 0, 0.24) !important;
  }

  .css-qc6sy-singleValue {
    font-weight: 600 !important;
    @extend %font14;
    color: $input_text;
  }
  .css-1pahdxg-control {
    // box-shadow: none !important;
    border: 1.5px solid $primary_color !important;
    box-shadow: 0px 0px 5px 2px rgba(19, 41, 230, 0.1);
    border-radius: 8px !important;
    padding: 8px 0px 9px 8px !important;
    &:hover {
      // box-shadow: none !important;
      border: 1.5px solid $primary_color !important;
      border-radius: 8px !important;
    }
  }
  .css-319lph-ValueContainer {
    // padding: 17px 16px !important;
  }
  .css-1okebmr-indicatorSeparator {
    display: none !important;
  }
  .css-tj5bde-Svg,
  .css-8mmkcg {
    background-image: url(../../assets/images/new-design/selectArrow.svg);
    // transform: rotate(180deg)!important;
    background-repeat: no-repeat;
    background-position: 0px;
  }
  .css-tlfecz-indicatorContainer {
    color: transparent !important;
    // transform: rotate(180deg)!important;
  }
  .css-1gtu0rj-indicatorContainer {
    color: transparent !important;
    &:hover {
      color: transparent !important;
    }
  }
  // .ccs-2613qy-menu {
  //   color: $black!important;
  //   background-color: none!important;
  // }
  .css-26l3qy-menu,
  .css-26l3qy-menu div,
  .css-26l3qy-menu span {
    font-size: 13px !important;
    // position: relative;
    z-index: 1;
  }
  .css-9gakcf-option {
    background-color: $white !important;
    font-weight: 600;
    @extend %font14;
    line-height: 24px;
    color: $input_text !important;
  }
  .css-4ljt47-MenuList {
    color: $input_text !important;
    font-weight: 600;
    @extend %font14;
    line-height: 24px;
    background-color: #f9f9f9;
    border: 1.8px solid #5a69ee;
    border-radius: 8px;
  }
  .css-1n7v3ny-option {
    background-color: #ebebeb;
  }
  .css-26l3qy-menu {
    border-radius: 8px;
  }
  /* Scroll Bar */
  ::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: $primary_color;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0b1fca;
  }
  .e-country-search {
    background-image: url("../../assets/images/onboard/search.svg");
    background-size: 14px;
    background-position: 2%;
    background-repeat: no-repeat;
  }
}
.e-select-null-wraper {
  .css-1s2u09g-control {
    border: 1.8px solid #e6e6e6 !important;
    border-radius: 8px;
    color: $input_text !important;
    padding: 8px 0px 9px 8px !important;
    &:hover {
      cursor: pointer;
    }
  }
  .css-14el2xx-placeholder {
    font-weight: 600 !important;
    @extend %font14;
    color: $input_placeholder !important;
  }

  .css-qc6sy-singleValue {
    font-weight: 500 !important;
    @extend %font14;
    color: rgba(0, 0, 0, 0.24);
  }
  .css-1pahdxg-control {
    // box-shadow: none !important;
    border: 1.5px solid $primary_color !important;
    box-shadow: 0px 0px 5px 2px rgba(19, 41, 230, 0.1);
    border-radius: 8px !important;
    padding: 8px 0px 9px 8px !important;
    &:hover {
      // box-shadow: none !important;
      border: 1.5px solid $primary_color !important;
      border-radius: 8px !important;
    }
  }
  .css-319lph-ValueContainer {
    // padding: 17px 16px !important;
  }
  .css-1okebmr-indicatorSeparator {
    display: none !important;
  }
  .css-tj5bde-Svg,
  .css-8mmkcg {
    background-image: url(../../assets/images/new-design/selectArrow.svg);
    // transform: rotate(180deg)!important;
    background-repeat: no-repeat;
    background-position: 0px;
  }
  .css-tlfecz-indicatorContainer {
    color: transparent !important;
    // transform: rotate(180deg)!important;
  }
  .css-1gtu0rj-indicatorContainer {
    color: transparent !important;
    &:hover {
      color: transparent !important;
    }
  }
  // .ccs-2613qy-menu {
  //   color: $black!important;
  //   background-color: none!important;
  // }
  .css-26l3qy-menu,
  .css-26l3qy-menu div,
  .css-26l3qy-menu span {
    font-size: 13px !important;
    // position: relative;
    z-index: 1;
  }
  .css-9gakcf-option {
    background-color: $white !important;
    font-weight: 600;
    @extend %font14;
    line-height: 24px;
    color: $input_text !important;
  }
  .css-4ljt47-MenuList {
    color: $input_text !important;
    font-weight: 600;
    @extend %font14;
    line-height: 24px;
  }
  /* Scroll Bar */
  ::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: $primary_color;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0b1fca;
  }
  .e-country-search {
    background-image: url("../../assets/images/onboard/search.svg");
    background-size: 14px;
    background-position: 2%;
    background-repeat: no-repeat;
  }
}
.e-select-error {
  .css-1s2u09g-control {
    border: 1.5px solid $red !important;
    border-radius: 8px !important;
    color: $input_text !important;
    padding: 8px 0px 9px 8px !important;
    &:hover {
      cursor: pointer;
    }
  }
  .css-14el2xx-placeholder {
    font-weight: 500 !important;
    @extend %font14;
    color: rgba(0, 0, 0, 0.24) !important;
  }

  .css-qc6sy-singleValue {
    font-weight: 600 !important;
    @extend %font14;
    color: $input_text;
  }
  .css-1pahdxg-control {
    // box-shadow: none !important;
    border: 1.5px solid $primary_color !important;
    box-shadow: 0px 0px 5px 2px rgba(19, 41, 230, 0.1);
    border-radius: 8px !important;
    padding: 8px 0px 9px 8px !important;
    &:hover {
      // box-shadow: none !important;
      border: 1.5px solid $primary_color !important;
      border-radius: 8px !important;
    }
  }
  .css-319lph-ValueContainer {
    // padding: 17px 16px !important;
  }
  .css-1okebmr-indicatorSeparator {
    display: none !important;
  }
  .css-tj5bde-Svg,
  .css-8mmkcg {
    background-image: url(../../assets/images/input/dropdown.svg);
    // transform: rotate(180deg)!important;
    background-repeat: no-repeat;
    background-position: 0px;
  }
  .css-tlfecz-indicatorContainer {
    color: transparent !important;
    // transform: rotate(180deg)!important;
  }
  .css-1gtu0rj-indicatorContainer {
    color: transparent !important;
    &:hover {
      color: transparent !important;
    }
  }
  // .ccs-2613qy-menu {
  //   color: $black!important;
  //   background-color: none!important;
  // }
  .css-26l3qy-menu,
  .css-26l3qy-menu div,
  .css-26l3qy-menu span {
    font-size: 13px !important;
    // position: relative;
    z-index: 1;
  }
  .css-9gakcf-option {
    background-color: $white !important;
    font-weight: 600;
    @extend %font14;
    line-height: 24px;
    color: $input_text !important;
  }
  .css-4ljt47-MenuList {
    color: $input_text !important;
    font-weight: 600;
    @extend %font14;
    line-height: 24px;
  }
  /* Scroll Bar */
  ::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: $primary_color;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0b1fca;
  }
  .e-country-search {
    background-image: url("../../assets/images/onboard/search.svg");
    background-size: 14px;
    background-position: 2%;
    background-repeat: no-repeat;
  }
}
