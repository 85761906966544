@import "./../config.scss";
.e_nodata_wrapper {
    width: 100%;
    img {
        vertical-align: middle;
        width: 300px;
    }
    h5 {
        color: #010101;
        text-align: center;
        letter-spacing: -0.02em;
        font-weight: bold;
        font-size: 18px;
    }
    p {
        color: #6d6d6d;
        text-align: center;
        letter-spacing: -0.02em;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
    }
}
.e_nodata_asset_wrapper {
    h5 {
        font-size: 24px;
        line-height: 29px;
        font-weight: 600;
        color: #000000;
        padding-top: 36px;
    }
    p {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        padding-top: 12px;
        color: #000000;
        span {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #4254eb;
        }
    }
    .e_add_assets {
        padding: 12px 16px;
        border-radius: 8px;
        width: 340px;
        margin-top: 24px;
    }
    .e_link {
        font-weight: 500;
        font-size: 16px;
        padding-top: 36px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.7);
        a {
            text-decoration: none;
            color: #4254eb;
            font-weight: 600;
        }
    }
}
