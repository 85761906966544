/*
 *   File : tab.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Tab style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_tab {
  @extend %font14;
  font-weight: 500;
  line-height: 120%;
  color: $input_text;
  transition: ease 0.7s;
  position: relative;
  background-color: $white;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid transparent;
  margin-right: 1.25rem;
  margin-bottom: 10px;
  width: 100%;
  background-image: url("../../assets/images/dataformatting/right.svg");
  background-repeat: no-repeat;
  background-position: 95% center;
  @extend %font14;
  &:hover {
    cursor: pointer;
    border: 1px solid #ccc;
    // background-color: $primary_color;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.08);
    opacity: 0.8;
    transition: ease 0.7s;
    padding: 24px;
    width: 100%;
    border-radius: 8px;
  }
}
.e_active_tab {
  @extend .e_tab;
  transition: ease 0.3s;
  opacity: 1;
  background-color: $primary_color;
  color: $white;
  border-radius: 8px;
  @extend %font14;
  margin-right: 1.25rem;
  padding: 24px;
  width: 100%;
  background-image: url("../../assets/images/dataformatting/right-active.svg");
}
