/*
 *   File : input.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : InputBox style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 06-12-2021
 */

 @import "./../config.scss";

 .e_input_wrap_old {
   position: relative;
   display: block;
   margin-bottom: 15px;
   .e_input_box {
     box-sizing: border-box;
     border-radius: 8px;
     @extend %font14;
     background: $white;
     border: 1.5px solid $input_boder;
     outline: none;
     width: 100%;
     font-weight: 600;
     color: $input_text;
     padding: 34px 50px 6px 20px;
   }
 }
 .e_input_box ~ label {
   position: absolute;
   left: 20px;
   top: 24px;
   font-weight: 600;
   @extend %font14;
   letter-spacing: 0.01em;
   color: $input_placeholder;
   -webkit-transition: all 0.3s ease;
   transition: all 0.3s ease;
 }
 .e_input_box:focus ~ label,
 .e_input_box:invalid ~ label {
   color: $input_placeholder;
   @extend %font12;
   -webkit-transform: translateY(-14px);
   transform: translateY(-14px);
 }
 .e_input_label ~ label {
   -webkit-transform: translateY(-14px);
   transform: translateY(-14px);
   font-size: 12px !important;
 }
 // input:invalid ~ label {
 //   -webkit-transform: translateY(-14px);
 //   transform: translateY(-14px);
 //   @extend %font12;
 
 // }
 .e_input_box:focus {
   border: 1.5px solid $primary_color;
   box-shadow: 0px 0px 5px 2px rgba(19, 41, 230, 0.1);
   @extend %font12;
 }
 .e_input_error {
   border: 1px solid $error;
 }
 .e_error_msg {
   @extend %font12;
   color: $error !important;
 }
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }
 
 input[type="number"] {
   -moz-appearance: textfield !important ;
 }
 input[type="number"]:hover,
 input[type="number"]:focus {
   -moz-appearance: number-input !important;
 }
 
 label {
   &:hover {
     cursor: pointer;
   }
 }
 // input:-internal-autofill-selected {
 //   background-color: $white!important;
 // }
 