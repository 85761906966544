@import "../config.scss";

.e_check_modal {
    .e_content {
        padding: 0 !important;
        max-width: 540px;
        transition: ease-in-out max-width 0.4s;
        // min-height: 435px;
        .e_modal_header {
            background: #f6f6f6;
            position: relative;
            border-radius: 12px 12px 0px 0px;
            height: 80px;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #010101;
            padding-top: 28px !important;
            img {
                position: absolute;
                right: 15px;
                top: 20%;
                cursor: pointer;
            }
            h5 {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #010101;
                text-align: center;
                margin-block-end: auto;
            }
        }
        .e_sub_heading {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: $input_text;
            margin-bottom: 4px;
        }
        .e_sub_heading_title {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $input_text;
        }
        .e_text {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: $input_text;
            margin-bottom: 12px;
        }
    }
    .e-check-handy {
        .checkBox_e_checkbox_container__2zc8dbel {
            color: $primary_color !important;
        }
    }
}
