@import "../config.scss";

.e_sidebar_wrapper {
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background: #ffffff;
    border-right: 1px solid rgba(64, 64, 64, 0.12);
    &::-webkit-scrollbar {
        // border-radius: 10px;
        background: #c0c0c0;
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #c3ccd7;
        border-radius: 12px;
    }

    .e_section {
        margin-top: 25px;
        .e_title {
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            font-variant: small-caps;
            color: rgba(0, 0, 0, 0.5);
        }
        .e_navbar {
            padding: 14px 0 12px 10px;
            width: 100%;
            border-radius: 6px;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: $input_text;
            margin-top: 10px;
            cursor: pointer;
            transition: ease-in 0.5s;
            &:hover {
                background: #f6f6f6;
            }
            img {
                vertical-align: text-top;
            }
        }
        .e_active_nav {
            background: rgba(19, 41, 230, 0.12);
            color: $primary_color;
        }
    }
    .e_count {
        background: #1329e6;
        border-radius: 3px;
        padding: 3px 5px;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #ffffff;
    }
    .e_badge {
        padding: 4px 8px;
        background: rgba(255, 111, 7, 0.16);
        border-radius: 999px;
        font-weight: 600;
        font-size: 8px;
        line-height: 10px;
        color: #ff6f07;
        position: absolute;
        white-space: pre;
    }
}
.e_placeholder {
    border: 1.2px solid #010101;
    border-radius: 50%;
    width: 32px !important;
    height: 32px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}
.e_name {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #010101;
    padding-top: 0px;
}
.e_email {
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: rgba(1, 1, 1, 0.75);
    word-break: break-all;
}
.e_info {
    background-color: $white;
    position: fixed;
    bottom: 0;
    padding: 20px 10px;
    width: 16%;
    cursor: pointer;
    transition: 0.35 all ease-in-out;

    &:hover {
        img {
            transform: translateX(5px);
        }
    }
}
