
/*
 *   File : newSlider.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Slider style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 06-01-2022
 */

 @import "./../config.scss";
.MuiSlider-thumb[data-index="0"]{
  display: none!important;
  pointer-events: none!important;
}
.MuiSlider-thumb[data-index="2"]{
  display: none!important;
  pointer-events: none!important;
  .Mui-active{
    pointer-events: none!important;
  }
}
.Mui-active{
  pointer-events: none!important;
}
.MuiSlider-rail {
  background-color: #E7E7E7!important;
  height: 8px!important;
}
.MuiSlider-track{
  background-color: $primary_color!important;
  height: 8px!important;
}
.MuiSlider-mark{
  background-color: transparent!important;

}
.MuiSlider-thumb{
  background-color: $white!important;
}
.MuiSlider-root{
  pointer-events: none;
}
.MuiSlider-thumb{
  pointer-events: all !important;
}
.MuiSlider-markLabel{
  font-weight: 300!important;
  font-size: 12px!important;
  line-height: 24px;
  color: #6D6D6D!important;
  opacity: 0.5!important;
  transform:translateY(-230%);    
}
.MuiSlider-valueLabelOpen, .MuiSlider-valueLabelOpen {
  background-color: $white!important;
  color: $primary_color!important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32)!important;
  width: 60px!important;
  height: 40px!important;
  border-radius: 8px!important;
  @extend %font14;
}
.css-eg0mwd-MuiSlider-thumb,.css-7drnjp {
  width: 24px;
  height: 24px;
  &:hover {
    box-shadow: none!important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
   
  }
}