/*
 *   File : success.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Success modal style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

 @import "./../config.scss";

.e_success_modal {
  .e_modal_dialog {
    max-width: 540px!important;
  }
  .e_content {
    position: relative;
    // height: 380px;
    padding: 0!important;
  }
  .e_modal_header {
    background: #F6F6F6;
    position: relative;
    border-radius: 12px 12px 0px 0px;
        h5 {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: $black;
        }
      .e_modal_close {  
        border: none;
        outline: none;
        background: transparent;
        position: absolute;
        right: 3%;
        top: 30%;
        width: 28px;
        height: 28 px;
        cursor: pointer;
      }
  }
  .e_success_icon {
    margin-top: 120px;
    width: 120px;
    height: 120px;
  }
  h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #010101;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.6);
  }
  .e_link {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
  }
  .e_btn {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding: 18px 24px;
  }
}

