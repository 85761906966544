/*
 *   File : edit.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Invest modal style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 04-01-2022
 */

@import "./../config.scss";

.e_edit_modal {
  .e_modal_content {
    padding: 0!important;
    max-width: 540px!important;

  }
  .e_modal_header {
    background: #F6F6F6;
    position: relative;
    border-radius: 12px 12px 0px 0px;
    height: 80px;
      img {
        position: absolute;
        right: 15px;
        top: 20%;
        cursor: pointer;
      }
      h5 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #010101;
        text-align: center; 
        margin-block-end: auto;
      }
  }
  h6 {
    @extend %h5;
    font-weight: 700 !important;
  }
  p {
    
  }
  .e_delete_text {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $input_text;
  }
  .textarea_e_textarea__3WFuM:focus {
    // border: 1px solid transparent;
  }
  .textarea_e_textarea__3WFuM {
    padding: 32px 16px !important;
  }
  .e_modal_submit {
    border: none;
    color: $primary_color;
    font-weight: 700;
    background: none !important;
    &:after {
      background: none;
    }
  }
  .e_input_wrapper {
    position: relative;
    .e_input {
      padding: 12px 23px 12px 30px !important;
      position: relative;
    }
    .e_rupee {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #010101;
      font-family: 'Roboto';
      position: absolute;
      top: 18px;
      left: 15px;
    }
  }

  .e_subtitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #010101;
  }
  .e_note {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.6);
  }
  .e_cancel {
    border: none;
    outline: none;
    background: $primary_color;
    padding: 18px 40px;
    color: $white;
    font-weight: 700;
    @extend %font14;
    line-height: 120%;
    position: relative;
    z-index: 0;
    border-radius: 8px;
    &:after {
      @include hover_animation();
      color: $white !important;
      background: #0b1fca;
      border-radius: 8px;
    }
    &:hover:after {
      opacity: 1;
      width: 100%;
      box-shadow: none !important;
    }
    span {
      z-index: 999;
      position: relative;
    }
  }
  .e_to_withdraw {
    @extend %font12;
    line-height: 16px;
    color: $input_placeholder;
  }
  .e_amount {
    font-weight: 700;
    @extend %font18;
    color: $input_text;
  }
}

.e-save-btn {
  position: relative;
  line-height: 1.6rem;
  color: $primary_color;
  text-decoration: none;
  text-align: center;
}

.e-save-btn:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  top: 2.8rem;
  left: 2rem;
  background-color: $primary_color;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.e-save-btn:hover:before {
  visibility: visible;
  width: 45%;
}

.e-send-btn {
  position: relative;
  line-height: 1.6rem;
  color: $primary_color;
  text-decoration: none;
  text-align: center;
}

.e-send-btn:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  top: 2.8rem;
  left: 2rem;
  background-color: $primary_color;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.e-send-btn:hover:before {
  visibility: visible;
  // width: 68%;
}

.e_disabled {
  opacity: 0.5;
  pointer-events: none;
}
.e_disabled_txt {
  opacity: 0.5;
  pointer-events: none;
  background: none !important;
  color: $primary_color;
  border: none !important;
}

.e_description {
  font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #010101;
}
.e_profile_edit_sub_ittle {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #010101 !important;
}
.e_otp_wrapper {
  transition: ease-in-out height 0.4s;
}

/*--Tour modal------*/
.e_tour_p {
  font-weight: 400!important;
  font-size: 14px!important;
  line-height: 24px!important;
  color: $input_placeholder!important;
}
.e_take_tour_btn {
  padding: 18px 34px!important;
}

