/*
 *   File : cardIconStyleToolTip.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Tooltip style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 08-12-2021
 */

 @import "./../config.scss";

.e_card_tooltip {
  background: var(--background);
  border: 1px solid $input_boder;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px 30px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  .e_icon {
    // width: 35px;
  }
  span {
    font-weight: 400!important;
    font-size: 12px;
    line-height: 18px;
    color: $black!important;
  }
}