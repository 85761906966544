/*
 *   File : checkBox.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : InputBox style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 07-12-2021
 */

@import "./../config.scss";

.e_checkbox_container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: $input_text;
  transition: ease 0.5s;
  padding-top: 3px;
  &:hover{
    // color: $primary_color;
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

  }
  /*  custom checkbox */
  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 19px;
    width: 19px;
    border: 1.6px solid #6d6d6d;
    box-sizing: border-box;
    transition: all 0.5s;
    border-radius: 4px;
    &:hover{
      border: 1px solid $primary_color;
    }
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  /* custome checkmark */
  input:checked ~ .checkmark:after{
    display: block;
  }
  input:checked ~ .checkmark{
    
    background-color: rgba(19, 41, 230, 1);
    border: 1px solid $white;
  }
}


