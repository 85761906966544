@import "./config.scss";

.e-new-onboard {
    .e-outer-logo {
        transition: ease-in-out 0.5s;
        cursor: pointer;
        &:hover {
            transform: scale(0.8);
        }
    }
    .e-video-player-container {
        position: relative;
        width: 100%;
        height: 540px;
        overflow: hidden;
        border-radius: 18px;
    }
    .e-site-link {
        font-weight: 500;
        font-size: 10px;
        line-height: 150%;
        color: $black;
        text-decoration: none;
        vertical-align: sub;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0.5px;
            background-color: $black;
            left: 19%;
            bottom: -2px;
            transition: ease width 0.5s;
        }
        &:hover::after {
            width: 81%;
        }
        img {
            margin-right: 10px;
        }
    }
    .e-form-title {
        font-weight: 600;
        @extend %font32;
        line-height: 39px;
        color: $input_text;
        margin-block-end: 5px;
    }
    .e-form-caption {
        font-weight: 500;
        @extend %font14;
        line-height: 150%;
        color: rgba(1, 1, 1, 0.6);
    }
    form {
        label {
            font-weight: 600;
            @extend %font12;
            line-height: 15px;
            color: $input_text;
        }
    }
    // .e-login-phone-input {
    //     width: 85%;
    // }
    .e-privacy {
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        color: $input_text;
        a {
            font-weight: 600;
            color: rgba(66, 84, 235, 1);
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .e-sign-up-btn {
        border: 1.8px solid #1329e6;
        box-shadow: 0px 2.75257px 33.0308px rgba(0, 0, 0, 0.06);
        border-radius: 4.81699px;
        margin-bottom: 12px;
    }
    .e-sign-up-option {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: rgba(0, 0, 0, 0.5);
    }
    .e-auth-submit {
        font-weight: 600;
        @extend %font18;
        // background-image: url('../assets/images/new-design/btn-arrow.svg');
        background-repeat: no-repeat;
        background-position: 85% 50%;
        padding: 18px 50px 18px 40px;
        transition: ease-in-out 0.3s;
        &:hover {
            padding: 18px 60px 18px 40px;
        }
    }
    .e-terms {
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        color: $input_text;
        a {
            font-weight: 600;
            color: $input_text;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .e-security-text {
        font-weight: 600;
        @extend %font10;
        line-height: 12px;
        color: #5a69ee;
        width: max-content;
        cursor: pointer;
        img {
            vertical-align: top;
        }
    }
    .e-timer-wrapper {
        // margin-top: -10px;
    }
}
.e-otp-timer {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $input_text;
}
.e-otp-resend {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #5a69ee;
}
.e-otp-wrapper {
    overflow: hidden;
    transition: ease height 0.5s;
}

.e-phone-success {
    position: relative;
    background-color: rgba(60, 188, 129, 0.12) !important;
    border: 1.8px solid #3cbc81 !important;
    background-image: url("../assets/images/new-design/check-circle.svg") !important;
    background-repeat: no-repeat !important;
    background-position: 95% 50% !important;
    input {
        background-color: transparent !important;
    }
}
.e-phone-fail {
    position: relative;
    background-color: #fce9e7 !important;
    border: 1.8px solid #e74a3b !important;
    background-image: url("../assets/images/new-design/fail.svg");
    background-repeat: no-repeat;
    background-position: 95% 50%;
    input {
        background-color: transparent !important;
    }
}
.e-help-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: $input_text;
}
.e-sign-up {
    .e-auth-submit {
        background-image: none !important;
    }
}
