
@import '../config.scss';

.e_player_modal {
    .e_modal_dialog{
        min-width: 800px!important;
        .e_modal_content{
            min-height: 500px!important;
            background-color: transparent;
            border: none;
            padding: 0!important;
            iframe {
                width: 100%;
                min-height: 500px;
            }
        }
    }
}