

@import './../config.scss';

.e_tab{
	padding: 12px 18px;
	background: #F2F2F2;
	border-radius: 8px;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #010101;
	min-width: 120px;
	cursor: pointer;
	transition: ease-in-out 0.5s;
		&:hover {
			background-color: #e7e5e5
		}
	
}
.e_active_tab{
	@extend .e_tab;
	transition: ease 0.3s;
	background: #5A69EE;
	color: $white;
		&:hover {
			background-color: #1d2dbc;
		}
}