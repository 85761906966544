@import "../config.scss";

.e_form_trash {
    // position: absolute;
    width: 20px;
    // right: 2.5%;
    // top: 15px;
    z-index: 2;
    cursor: pointer;
    transition: ease 0.3s;
    &:hover {
        transform: scale(0.8);
    }
}
.e_asset_number {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $input_text;
    // margin-block-end: 3px;
}
//file uploader
.e_file_uploader {
    background: $white;
    border: 1.8px dashed #e6e6e6;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 30px;
    cursor: pointer;
    button {
        background: #edf3ff;
        border: 1px solid #5a69ee;
        border-radius: 4px;
        padding: 8px 16px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #5a69ee;
    }
    p {
        font-family: "Outfit";
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $input_text;
        margin-block-end: 3px;
    }
    span {
        display: block;
        font-family: "Outfit";
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        color: #7f7f7f;
    }
}

.e_more_btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5a69ee;
}
.e_stocks {
    .e_card_title {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $input_text;
    }
    .e_other_card {
        background: $white;
        overflow: hidden;
        transition: ease height 0.5s;
        h6 {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $input_text;
        }
    }
}
.e_form_name {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: rgba(1, 1, 1, 0.6);
}
.e_divider {
    width: 2px;
    height: 100px;
    background: #ededed;
    position: absolute;
    right: 0;
    top: 0;
    // bottom: 0;
    margin: auto;
}
.e_link_btn {
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $primary_color;
    position: relative;
    transition: ease-in-out 0.3s;
    &:hover {
        opacity: 0.7;
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 1.5px;
        background-color: #5a69ee;
    }
}
.e_price_input {
    padding: 12px 37px 13px 26px !important;
}
.e_rupee {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #010101;
    top: 16px;
    position: absolute;
    left: 6%;
}
.e_page_title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $input_text;
}
.e_spinner_input {
    text-align: center;
    padding: 12px 31px 13px 35px !important;
}
.e_spinner_minus {
    position: absolute;
    top: 30%;
    left: 13%;
    cursor: pointer;
}
.e_spinner_plus {
    position: absolute;
    top: 30%;
    right: 13%;
    cursor: pointer;
}

.e_search {
    background-color: #f9f9f9 !important;
    position: relative;
    background-image: url("../../assets/images/new-design/search.svg");
    background-repeat: no-repeat;
    background-position: 95% 50%;
    padding: 10px 30px 10px 15px !important;
}
.e_search:focus {
    background-color: rgba(208, 212, 250, 0.24) !important;
}
.e_file_name {
    font-size: 12px;
    text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
    text-align: left;
    /* height: 33px; */
    white-space: pre;
}
.e_close {
    width: 20px;
}
.e_upcoming_broker {
    color: $logout;
    font-size: 14px;
    font-weight: 400;
    display: inline;
}

.e_instruction_ul {
    padding-left: 14px !important;
    list-style: decimal;
    font-weight: 400;
    font-size: 12px;
    color: #010101;
}
.e_or {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 18px;
    /* bottom: 0; */
    width: max-content;
    z-index: 20;
    padding: 0 17px;
    background-color: #edf3ff;
}
.e_login_btn {
    transition: ease-in-out 0.5s;
    &:hover {
        transform: scale(0.8);
    }
}
