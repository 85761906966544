@import "./../config.scss";

.e_rebalance_modal {
    .e_modal_dialog {
        max-width: 540px !important;
    }
    .e_modal_content {
        padding: 0 !important;
    }
    .e_modal_header {
        background: #f6f6f6;
        position: relative;
        border-radius: 12px 12px 0px 0px;
        height: 80px;
        img {
            position: absolute;
            right: 15px;
            top: 20%;
            cursor: pointer;
        }
        h5 {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: $input_text;
            text-align: center;
            margin-block-end: auto;
        }
    }
    .e_modal_title {
        background: #f6f6f6;
        position: relative;
        border-radius: 12px 12px 0px 0px;
        height: 80px;
        img {
            position: absolute;
            right: 15px;
            top: 20%;
            cursor: pointer;
        }
        h5 {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: $input_text;
            text-align: center;
            margin-block-end: auto;
        }
    }
    .e_sub_title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $input_text;
        margin-bottom: 12px;
    }
    .e_sub_content {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: $input_text;
        margin-bottom: 0;
    }
    .e_info {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #010101;
    }
    .e_card_wrapper {
        background-color: #f5f5f5;
        border-radius: 8px;
        height: 350px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            // border-radius: 10px;
            height: 0px !important;
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background: #c0c0c0;
            // border-radius: 10px;
        }
    }
    .e_title {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #010101;
    }
    .e_content_card {
        background: #ffffff;
        box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        .e_equity_name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #010101;
        }
        .e_fund_name {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: $primary_color;
            opacity: 0.77;
        }
        .e_weight {
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #010101;
            opacity: 0.77;
        }
        .e_arrow {
            height: 20px;
        }
        .e_arrow_small {
            height: 10px;
        }
    }
    .e_success_icon {
        width: 103px;
        height: 103px;
    }
    .e_success_text {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: $input_text;
        padding-top: 10px;
    }
}

.e_rebalance_card {
    background: #f5f5f5;
    border-radius: 8px;
    height: 350px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        // border-radius: 10px;
        height: 0px !important;
        width: 4px !important;
    }
    &::-webkit-scrollbar-thumb {
        background: #c0c0c0 !important;
        // border-radius: 10px;
    }
    .e_title {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: $black;
    }
    .e_fund_name {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
    }
    .e_weight {
        @extend .e_fund_name;
    }
}
