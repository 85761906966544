/*
 *   File : radiometrics.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Primary Link Text Button Style
 *   Integrations : null
 *   Version : v1.0
 */
@import "./../config.scss"; 
 
.e_radiometrics {
  background: #FFFFFF;
  border: 1.2px solid rgba(1, 1, 1, 0.12);
  border-radius: 6px;
  width: 36px;
  height: 36px;
  padding: 4px;
  text-align: center;
  margin-right: 12px;
  &:hover {
    cursor: pointer;
  }
  .e_item {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #010101;
  }
}
.e_radiometrics_active {
  background: #5A69EE;
  border: 1.2px solid rgba(255, 255, 255, 0.12);
  border-radius: 6px;
  width: 36px;
  height: 36px;
  padding: 4px;
  text-align: center;
  margin-right: 12px;
  span {
    color: $white!important;
  }
}
.e_item_content {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: $input_text;
  text-transform: uppercase;
}
.e_item_content_risky {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: $input_text;
  text-transform: uppercase
  // padding-left: 50px;
}