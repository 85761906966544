/*
 *   File : headerInner.module.scss.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Header
 *   Integrations : null
 *   Version : 1.0
 *   Created : 09-12-2021
 */

@import "./../config.scss";

.e_headerInner {
  padding: 36px 52px;
  background-color: $white;
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 1;
  .e_logo {
    width: 29px;
    height: 24px;
    margin-right: 48px;
    &:hover {
      cursor: pointer;
    }
  }
  .e_notification_wrap {
    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }
  a{
    text-decoration: none;
  }
  .e_name_wrap {
    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }
  .e_name_wrap {
    background: $primary_color;
    color: $white;
    font-weight: 700;
    @extend %font14;
    line-height: 17px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    cursor: pointer;
    padding: 8px 12px;
  }
  .e_name_wraper {
    background: $primary_color;
    color: $white;
    font-weight: 700;
    @extend %font14;
    line-height: 17px;
    // width: 32px;
    // height: 32px;
    border-radius: 100%;
    cursor: pointer;
    padding: 9px 14px;
  }

  .e_profile_name {
    font-weight: 500;
    @extend %font14;
    line-height: 17px;
    color: $input_text;
    background-image: url(../../assets/images/menu/arrow-down.svg);
    background-repeat: no-repeat !important;
    height: 6px;
    width: 8px;
    padding: 0 20px 0 8px;
    position: relative;
    background-position: 100% center;
    cursor: pointer;
  }
  .e_inner_header_link {
    font-weight: 600;
    @extend %font14;
    line-height: 130%;
    color: $input_placeholder;
    text-decoration: none !important;
    padding-top: 20px;
    position: relative;
    margin-right: 30px;
    &:hover {
      opacity: 0.8;
    }
    &:before {
      content: "";
      position: absolute;
      width: 0px;
      height: 3px;
      bottom: -13px;
      left: 0;
      visibility: visible;
      // color: $primary_color;
      background-color: $primary_color;
      transition: ease-in-out 0.5s;
    }
    &:hover {
      &:before {
        width: 100%;
        height: 3px;
      }
    }
  }
  .e_inner_header_link_active {
    color: $input_text;
    text-decoration: none;
    @extend %font14;
    line-height: 130%;
    display: inline-block;
    font-weight: 600;
    margin: 0px 30px 0px 0px;
    &::after {
      content: "";
      display: block;
      width: 100%;
      position: relative;
      bottom: 0px;
      top: 9px;
      height: 2px;
      background: $primary_color;
      transition: width 0.3s;
      z-index: 1;
    }
  }
}
.e_guest_logout {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #010101;
  cursor: pointer;
  transition: ease-in-out 0.5s;
    &:hover {
      opacity: 0.6;
    }
}