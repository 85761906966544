@import '../config.scss';

@mixin animation_mixin($name, $halfScale, $fullScale) {
	@keyframes #{$name} {
		0% {
			opacity: 1;
			transform: scale(1);
		}

		50% {
			transform: scale($halfScale);
		}

		100% {
			transform: scale($fullScale);
		}
	}
}

$classes: 'dots' #fff pulse 1 60,
	'first_outer' #C6CCFA pulse1 3 65,
	'second_outer' #A1AAF7 pulse2 5 70,
	'third_outer' #7B88F4 pulse3 10 75,
	'fourth_outer' #5566F1 pulse4 20 80,
	'fifth_outer' #3044EE pulse5 30 85,
	'sixth_outer' #1329E6 pulse6 40 100;

.e_animate_wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 9999;
}

.e_content_wrapper {
	background-color: #fff;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 99999 !important;
}

.e_splash_image {
	position: absolute;
	margin: auto;
	width: 200px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	@include animation_mixin(logoFadeOut, 0.5, 0);
	animation: logoFadeOut 0 0.5s forwards;
}

@each $class,
$color,
$name,
$halfScale,
$fullScale in $classes {
	.e_#{$class} {
		height: 40px;
		width: 40px;
		background-color: $color;
		border-radius: 50%;
		margin: auto;
		position: absolute;
		top: -31px;
		left: -59px;
		right: 0;
		bottom: 0;
		opacity: 0;
		@include animation_mixin($name, $halfScale, $fullScale);
		animation: $name 1s 1s ease-in forwards;

	}
}

@media only screen and (max-width: 575px) {
	@each $class,
	$color,
	$name,
	$halfScale,
	$fullScale in $classes {
		.e_#{$class} {
			left: -10px;
		}
	}
	.e_splash_image  {
		width: 120px;
	}
}