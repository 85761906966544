
@import '../config.scss';

.e_loader_modal {
    .e_dialog {
        max-width: 500px!important;
    }
    h5 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #010101;
        margin-block-end: auto;
    }
    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.6);
    }
    .e_loader_content {
        span {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
        }
    }
}
