/*
 *   File : watsapp.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Watsapp Button Style
 *   Integrations : null
 *   Version : v1.0
 */

@import "./../config.scss";

.e_watsapp {
  color: #075E54 !important;
  outline: none !important;
  border: 1px solid rgba(7, 94, 84, 0.25);
  transition: all 0.35s;
  position: relative;
  background-color: rgba(172, 235, 196, 0.32);
  z-index: 99;
  border-radius: 6px;
  padding: 5px 18px;
  width: 162px;
  // display: inline-flex;
  &:hover {
    cursor: pointer;
    transition: 0.3s ease-in-out;
    background-color: rgba(87, 232, 142, 0.459);
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .e_icon {
    position: relative;
    // width: 13.46px;
    // height: 13.46;
    top: -1px;
    margin-right: 4px;
  }
}

.e_calendly {
  color:  #006BFF !important;
  outline: none !important;
  border: 1px solid rgba(10, 103, 214, 0.25);
  transition: all 0.35s;
  position: relative;
  background-color: rgba(0, 107, 255, 0.1);;
  z-index: 99;
  border-radius: 6px;
  padding: 5px 18px;
  // display: inline-flex;
  &:hover {
    cursor: pointer;
    transition: 0.3s ease-in-out;
    background-color: #3982e76b;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .e_icon {
    position: relative;
    margin-right: 4px;
    // width: 11.97px;
    height: 16.46px;
    top: -2px;
    margin-right: 4px;
  }
}
