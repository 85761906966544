@import "../config.scss";

.e_wrapper {
    width: 350px;
    height: 8px;
    background: #e9e9e9;
    border-radius: 8px;
    overflow: hidden;
    .e_progress_bar {
        width: 0;
        height: 8px;
        background: $primary_color;
        border-radius: 100px;
        transition: 0.35s linear;
    }
}
.e-progress-loader {
    opacity: 1;
    transition: 0.35s ease;
    margin: 320px 0;
    &.e-hide-scan-loader {
        opacity: 0;
    }
}
