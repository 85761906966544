/*
 *   File : allocationInput.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : InputBox style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 07-12-2021
 */

 @import "./../config.scss";

.e_allocation_input{
  box-sizing: border-box;
  border-radius: 0px;
  @extend %font14;
  background: $white;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $primary_color;
  outline: none;
  width: 100%;
  font-weight: 500;
  color: $input_text;
  width: 60px;
  background-image: url('../../assets/images/input/icon.svg')!important;
  background-repeat: no-repeat;
  background-position: right 7px;
  padding: 4px 25px 3px 0px;
}
.e_error_input{
  @extend .e_allocation_input;
  border-bottom: 1px solid red!important;
}