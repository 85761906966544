/*
 *   File : rangeSlider.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Range slider style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 29-10-2021
 */

 @import "./../config.scss";

.rangeslider-horizontal .rangeslider__fill {
  background-color: $primary_color;
}
.rangeslider .rangeslider__fill {
  box-shadow: transparent!important;
}
.rangeslider-horizontal{
  height: 8px;
}
.rangeslider-horizontal .rangeslider__handle:after{
  width: 0;
}
.rangeslider-horizontal .rangeslider__handle{
  border: none!important;
}
.rangeslider__handle-tooltip{
  background-color: $white!important;
  color: $input_text!important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
}
.rangeslider .rangeslider__handle{
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
}
.rangeslider__labels {
  top: -58px;
}
