/*
 *   File : primary.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Primary Button Style
 *   Integrations :
 *   Version : v1.0
 */

 @import "./../config.scss";

 .e_p_btn{
   border: none;
   outline: none;
   background: $primary_color;
   padding: 18px 40px;
   color: $white;
   font-weight: 600;
   @extend %font14;
   line-height: 120%;
   position: relative;
   z-index: 0;
   border-radius: 8px;
   border: 1px solid $primary_color;
   transition: ease 0.3s;
      &:hover {
        background: #0B1FCA;
      }
  //  &:after{
  //   // @include hover_animation();
  //   color: $white!important;
  //   background: #0B1FCA;
  //   border-radius: 8px;
  //  }
  //  &:hover:after{
  //    opacity: 1;
  //    width: 100%;
  //    box-shadow: none!important;
  //  }
   span {
     z-index: 999;
     position: relative;
   }
 }
 .e_disabled{
   opacity: 0.5;
   pointer-events: none;
 }
 
 