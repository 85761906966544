/*
 *   File : reportAccordion.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Accordion
 *   Integrations : null
 *   Version : 1.0.0
 *   Created :11-01-2022
 */
@import "./../config.scss";
.e_report_accordion {
  .e_accordion_sec {
    background-color: $white !important;
    padding: 22px;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
    border-radius: 8px !important;
    border: 1px solid $input_boder;
    margin-bottom: 20px;
    // h6 {
    //   color: $primary_color  !important;
    //   @extend %font14;
    //   line-height: 24px;
    //   font-weight: 700!important;
    //   padding: 12px 10px 0px;
    //   width: 100%;
    // }
  }

  .e_accordion_button {
    border: none !important;
    background-color: $white;
    text-align: left;
    width: 100%;
    position: relative;
    border-radius: 0;
    outline: none;
    // &[aria-expanded="false"] {
    .e_accordion_icon {
      position: absolute;
      top: 42%;
      right: 0px;
      background-image: url(../../assets/images//dataformatting/add.svg);
      background-repeat: no-repeat;
      padding: 13px;
      transition: 0.5s ease-in-out;
    }
    // }
    // &[aria-expanded="true"] {
    .e_accordion_minus_icon {
      position: absolute;
      top: 42%;
      right: 0px;
      background-image: url(../../assets/images/dataformatting/minus.svg);
      background-repeat: no-repeat;
      padding: 13px;
      transition: 0.5s ease-in-out;
    }
    // }
  }
  .e_accordion_item {
    border: none !important;
    .e_accordion_header {
      background: #ffffff;
    }
  }
  .e_accordion_title {
    font-weight: 600;
    @extend %font18;
    line-height: 20px;
    color: $input_text;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .e_accordion_sub_title {
    font-weight: 700;
    @extend %font14;
    line-height: 20px;
    padding: 0px 16px;
    color: $input_text;
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
  .e_accordion_wrapper {
    background: $modal_bg;
    border-radius: 8px;
    padding: 14px;
    margin: 2px;
    h6 {
      @extend %font12;
      font-weight: 700 !important;
    }
    p {
      @extend %font12;
      line-height: 24px;
      color: $input_placeholder;
      font-weight: 600;
    }
    .e_accordion_content_img {
      width: 4%;
    }
    .e_accordion_content {
      font-weight: 400;
      @extend %font14;
      line-height: 150%;
      color: $input_text;
    }
    .e_download {
      outline: none;
      background: $primary_color;
      padding: 23px 40px;
      color: #ffffff;
      font-weight: 700;
      line-height: 120%;
      position: relative;
      z-index: 0;
      border-radius: 8px;
      border: 1px solid $primary_color;
    }
  }
  .e_delete {
    height: 40px;
    text-align: center;
    border: 1px solid #de2020;
    padding: 8px 12px;
    border-radius: 8px;
    font-weight: 700;
    color: #de2020;
    font-weight: 700;
    &:hover {
      opacity: 0.7;
    }
  }
  .e_edit {
    height: 40px;
    text-align: center;
    border: 1px solid $primary_color;
    padding: 8px 12px;
    border-radius: 8px;
    font-weight: 700;

    &:hover {
      opacity: 0.7;
    }
  }
}
