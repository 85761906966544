@import "../config.scss";
.e-ant-daterange-picker {
    /*
 *   File : dateRangepicker.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : dateRangepicker.scss
 *   Integrations : 
 *   Version : v1.0
 */

    .ant-picker-range {
        width: 100%;
        padding: 19px 17px !important;
        position: relative;
        @extend %font14;
        background: $white;
        font-weight: 600;
        color: $input_text;
        border: 1.5px solid $input_boder;
        border-radius: 8px !important;
        position: relative;
    }
    .ant-picker {
        width: 100%;
        padding: 19px 17px !important;
        position: relative;
        @extend %font14;
        background: $white;
        font-weight: 600;
        color: $input_text;
        border: 1.5px solid $input_boder;
        border-radius: 8px !important;
    }
    .ant-picker-focused {
        border: 1.5px solid $primary_color !important;
        box-shadow: 0px 0px 5px 2px rgba(19, 41, 230, 0.1);
        @extend %font12;
    }
    .ant-picker-input > input::placeholder {
        font-weight: 600;
        @extend %font14;
        color: $input_placeholder;
    }
    .ant-picker-input > input {
        font-weight: 600;
        @extend %font14;
        color: $input_placeholder;
    }
    .ant-picker-suffix {
        position: absolute;
        right: 9px;
        top: 20px;
    }
    .ant-picker-active-bar {
        display: none;
    }
    .ant-picker-range-separator {
        padding: 0 18px;
    }
    .ant-picker-clear {
        right: 9.5px;
    }
}
