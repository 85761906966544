 /*
 *   File : Textarea.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Textarea style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";
.e_textarea_wrap {
  position: relative;
  display: block;
  margin-bottom: 15px;
  .e_textarea{
    box-sizing: border-box;
    border-radius: 8px;
    @extend %font14;
    background: $white;
    border: 1.8px solid #E6E6E6;
    outline: none;
    width: 100%;
    font-weight: 600;
    color: $input_text;
    padding: 20px 16px 18px 16px;
    height: 145px;
  }
  
}
.e_textarea ~ label, textarea:invalid ~ label {
  position: absolute;
  left: 15px;
  top: 18px;
  font-weight: 600;
  @extend %font14;
  letter-spacing: 0.01em;
  color: $input_placeholder;
  background: $white;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e_textarea {
  &::placeholder {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.24);
  }
}

.e_textarea:focus ~ label  {
  top: 6px;
  color: $input_placeholder;
  @extend %font12;
}

textarea:valid ~ label {
  top: 6px;
  color: $input_placeholder;
  @extend %font12;
}
.e_textarea:focus {
  border: 1.8px solid #5A69EE;
   background: rgba(208, 212, 250, 0.24);
}
.e_input_error{
  border: 1px solid $error;

}
.e_error_msg{
  @extend %font12;
  color: $error!important;
}