@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    Mintd
Version:    1.0.0
Created:    26-10-2021
Use:
-------------------------------------------------------------------*/

/*--- 1. Import Fonts ----*/

$font-stack: "Montserrat";

@font-face {
  font-family: $font-stack;
  src: url(../assets/fonts/Montserrat-woff/Montserrat-ExtraBold.woff) format("woff");
  font-weight: 800;
}
@font-face {
  font-family: $font-stack;
  src: url(../assets/fonts/Montserrat-woff/Montserrat-Bold.woff) format("woff");
  font-weight: 700;
}
@font-face {
  font-family: $font-stack;
  src: url(../assets/fonts/Montserrat-woff/Montserrat-SemiBold.woff) format("woff");
  font-weight: 600;
}
@font-face {
  font-family: $font-stack;
  src: url(../assets/fonts/Montserrat-woff/Montserrat-Medium.woff) format("woff");
  font-weight: 500;
}
@font-face {
  font-family: $font-stack;
  src: url(../assets/fonts/Montserrat-woff/Montserrat-Regular.woff) format("woff");
  font-weight: 400;
}
@font-face {
  font-family: $font-stack;
  src: url(../assets/fonts/Montserrat-woff/Montserrat-Light.woff) format("woff");
  font-weight: 300;
}

// Outfit font
@font-face {
  font-family: "Outfit";
  src: url(../assets/fonts/static/Outfit-Regular.ttf) format("woff");
  font-weight: 400;
}

/* --- 2. Colors --- */
$primary_color: #1329e6;
$secondary_color: #0b1fca;
$white: #ffffff;
$black: #000000;
$error: #ff523b;
$input_boder: #f0f0f0;
$input_text: #010101;
$input_placeholder: #6d6d6d;
$tooltip_bg: #edf3ff;
$modal_bg: #edf3ff;
$logout: #ff5050;
$red: #de2020;
$brick_red: #8c0a01;
$toast_success_border: #00d1b1;
$toast_success_bg: #d4ece8f5;
$toast_success_color: #089487;
$toast_fail_bg: #f9e5e5;
$background_bg: #f6f9ff;
$green: #0fd99c;

/* --- 3. Font --- */
%font10 {
  font-size: 0.625rem;
}
%font11 {
  font-size: 0.688rem;
}
%font12 {
  font-size: 0.75rem;
}
%font14 {
  font-size: 0.875rem;
}
%font16 {
  font-size: 1rem;
}
%font18 {
  font-size: 1.125rem;
}
%font20 {
  font-size: 1.25rem;
}
%font24 {
  font-size: 1.5rem;
}
%font32 {
  font-size: 2rem;
}
%font36 {
  font-size: 2.25rem;
}
%font48 {
  font-size: 3rem;
}

/* --- 2. Global --- */
%h3 {
  color: $input_text;
  @extend %font24;
  line-height: 17px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
}
%h5 {
  color: $input_text;
  @extend %font18;
  line-height: 17px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
}
%h6 {
  color: $input_text;
  @extend %font14;
  line-height: 17px;
  -webkit-font-smoothing: antialiased;
}

.modal {
  ::-webkit-scrollbar {
    height: 160px;
    width: 13px;
  }
  ::-webkit-scrollbar-thumb {
    background: #8d8b8b !important;
    // border-radius: 31px;
  }
  ::-webkit-scrollbar-track {
    // border-radius: 31px;
    background: #fff;
  }
}

.e-calendar-modal {
  margin-left: 9%;
  .modal-dialog {
    max-width: 344px !important;
  }
  .modal-header {
    display: block !important;
  }
}
.modal-dialog {
  max-width: 636px !important;
  border: none !important;
}
.modal-content {
  padding: 15px !important;
  border-radius: 8px !important;
}
.e-score-modal .modal-content {
  padding: 25px !important;
}
.modal-header {
  border-bottom: none !important;
}
.btn-close {
  opacity: 10 !important;
}
.star {
  color: #ae0e0e;
  line-height: normal !important;
  padding-top: 2px;
}
.btn-close:focus {
  outline: 0;
  box-shadow: none !important;
  opacity: 1;
}
.e-full-width {
  width: 100%;
}
label {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: $input_text;
}

/* ---3. Mixin styles ---*/

// primary button
@mixin hover_animation() {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: all 0.35s;
  z-index: 1;
  opacity: 0;
}

.btn-close {
  opacity: 10 !important;
}
button:focus:not(:focus-visible) {
  outline: none !important;
}
.btn-close:focus {
  box-shadow: none !important;
}
.btn-close:hover {
  transform: scale(1.1);
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.12) !important;
  backdrop-filter: blur(6px) !important;
}
.modal-backdrop.show {
  opacity: 2 !important;
}
.modal-content {
  border: none !important;
}
