/*
 *   File : radiometrics.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Primary Link Text Button Style
 *   Integrations : null
 *   Version : v1.0
 */
 @import "./../config.scss"; 

 .e_calendar_modal{
   h6{
     @extend %font14;
     color: $input_text;
     font-weight: 700;
     line-height: 24px;
   }
   p{
     color: $input_placeholder;
     font-weight: 400;
   }
  
 }
 
 .e_calendar_wrap {
  background: #FFFFFF;
  // width: 278px;
  // height: 278px;
  // border: 1px solid red;
  word-break: break-word;
  padding: 20px;
  .e_calendar {
    padding: 9px 10px;
    box-sizing: border-box;
    margin-right: 12px;
    &:hover {
      cursor: pointer;
    }
    .e_item {
      font-weight: 400;
      @extend %font14;
      line-height: 150%;
      color: #010101;
    }
  }
  .e_calendar_active {
    background-color: $primary_color;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 8px;
    width: 43px;
    padding: 9px 10px;
    text-align: center;
    margin-right: 12px;
    @extend %font14;
    span {
      color: $white!important;
    }
  }
  .e_item_content {
    font-weight: 500!important;
    font-size: 12px;
    line-height: 15px;
    color: $input_placeholder;
  }
  .e_item_content_risky {
    font-weight: 500!important;
    font-size: 12px;
    line-height: 15px;
    color: $input_placeholder;
    padding-left: 65px;
  }

 }
 