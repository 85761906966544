/*
 *   File : secondary.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Secondary Button Style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_s_btn {
	background: $white;
	border: 1.8px solid #1329e6;
	box-sizing: border-box;
	border-radius: 8px;
	outline: none;
	padding: 18px 40px;
	color: $primary_color;
	font-weight: 700;
	@extend %font14;
	line-height: 120%;
	position: relative;
	box-shadow: 0px 0px 0px 0.2px blue inset;
	z-index: 0;
	transition: all 0.35s;
	// &:after {
	// 	color: $secondary_color !important;
	// 	// background: #edf3ff;
	// 	border-radius: 8px;
	// 	height: 100%;
	// 	position: absolute;
	// 	content: "";
	// 	top: 0px;
	// 	left: 0;
	// 	width: 0;
	// 	transition: all 0.35s;
	// 	z-index: 1;
	// 	opacity: 0;
	// }
	&:hover {
		opacity: 0.5;
		// width: 100%;
		box-shadow: none !important;
		transition: all 0.35s;
	}
	span {
		z-index: 2;
		position: relative;
	}
	img {
		z-index: 2;
		position: relative;
	}
}
.e_disabled {
	opacity: 0.5;
	pointer-events: none;
}
