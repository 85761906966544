/*
 *   File : scoreExample.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Score Example modal style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

@import "./../config.scss";

.e_score_example_modal {
  h3 {
    @extend %h3;
  }
  h6 {
    @extend %h5;
    font-weight: 700 !important;
  }
  .e_note {
    font-weight: 300;
    @extend %font14;
    line-height: 24px;
    color: $input_placeholder;
  }
  .e_content {
    font-weight: 400;
    @extend %font14;
    line-height: 24px;
    color: $input_text;
  }
  .e_scroll {
    height: 53vh;
    overflow-y: auto !important;
    &::-webkit-scrollbar {
      // display: none;
    }
  }
  .e_modal_submit {
    width: 100%;
  }
  .e_border {
    border: 1px solid $input_boder;
    box-sizing: border-box;
    border-radius: 8px;
  }
}
.e_disabled {
  opacity: 0.5;
  pointer-events: none;
}
.e_edit_bank_modal {
  p {
    font-size: 14px;
    line-height: 24px;
  }
  h3 {
    @extend %h3;
  }
  h6 {
    @extend %h5;
    font-weight: 700 !important;
  }
}
