/*
 *   File : headerOuterFull.module.scss.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Header
 *   Integrations : null
 *   Version : 1.0
 *   Created : 09-12-2021
 */

@import "./../config.scss";

.e_headerOuterFullConfirm {
  padding: 32px 54px;
  background-color: transarent;
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  // backdrop-filter: blur(29px);
  z-index: 99;
  .e_logo {
    width: 80px;
    height: 32px;
    &:hover {
      cursor: pointer;
    }
  }
  .e_create_acct_btn {
    border: 1px solid $input_boder;
    box-sizing: border-box;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
    color: $primary_color;
    padding: 16px !important;
  }
  .e_login_btn {
    font-weight: 600;
    @extend %font14;
    line-height: 17px;
    color: $input_text !important;
  }
}

.e_headerOuterFull {
  padding: 32px 44px;
  background-color: $white;
  position: fixed;
  height: auto;
  top: 0;
  left: 0;
  width: 100%;
  // backdrop-filter: blur(29px);
  z-index: 15;
  .e_logo {
    width: 80px;
    height: 32px;
    &:hover {
      cursor: pointer;
    }
  }
  .e_create_acct_btn {
    border: 1px solid $input_boder;
    box-sizing: border-box;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
    color: $primary_color;
    padding: 16px !important;
  }
  .e_login_btn {
    font-weight: 600;
    @extend %font14;
    line-height: 17px;
    color: $input_text !important;
  }
}
.e_headerOuterBtn {
  padding: 32px 54px;
  background-color: transparent;
  position: fixed;
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  // backdrop-filter: blur(29px);
  z-index: 99;
  width: 100%;

  // backdrop-filter: blur(29px);
  .e_logo {
    width: 80px;
    height: 32px;
    &:hover {
      cursor: pointer;
    }
  }
  .e_create_acct_btn {
    border: 1px solid $input_boder;
    box-sizing: border-box;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
    color: $primary_color;
    padding: 16px !important;
  }
}
.e_headerOuterLogin {
  padding: 32px 54px;
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  // backdrop-filter: blur(29px);
  z-index: 99;
  .e_logo {
    width: 80px;
    height: 32px;
    &:hover {
      cursor: pointer;
    }
  }
  .e_login_btn {
    font-weight: 600;
    @extend %font14;
    line-height: 17px;
    color: $input_text !important;
  }
  .e_video_btn {
    font-weight: 700!important;
    &:hover ~ .e_video_play {
      border: 2px solid $primary_color;
      transform: scale(0.8);
      background: radial-gradient(50% 50% at 50% 50%, #1329e6 60%, #e7eafd 90.1%);
    }
  }
  .e_video_play {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    text-align: center;
    background: radial-gradient(50% 50% at 50% 50%, #1329e6 0%, #e7eafd 90.1%);
    border: 1px solid $primary_color;
    // padding: 0px 20px;
    z-index: 5;
    transition: ease-in-out 0.5s;
   
    &:hover {
      // padding: 0px 18px;
      border: 2px solid $primary_color;
      transform: scale(0.8);
      background: radial-gradient(50% 50% at 50% 50%, #1329e6 60%, #e7eafd 90.1%);
    }
    img {
      width: 12px;
      height: 15px;
      margin-left: 4px;
    }
  }
}
.e_headerOuterFullLogin {
  padding: 32px 54px;
  background-color: $white;

  // backdrop-filter: blur(29px);
  z-index: 1;
  .e_logo {
    width: 80px;
    height: 32px;
    &:hover {
      cursor: pointer;
    }
  }
  .e_video_btn {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #1329e6;
  }
}
.e_headerauthLogin {
  padding: 32px 54px;
  background-color: transparent;
  position: fixed;
  width: auto;
  height: auto;
  top: 0;
  left: 0;

  z-index: 99;
  width: 100%;
  .e_logo {
    width: 80px;
    height: 32px;
    &:hover {
      cursor: pointer;
    }
  }
  .e_login_btn {
    font-weight: 600;
    @extend %font14;
    line-height: 17px;
    color: $input_text !important;
  }
}

@media only screen and (max-width: 480px){
  .e_video_btn {
    font-size: 12px!important;
  }
  .e_video_play {
    width: 30px!important;
    height: 30px!important;
  }
}
